/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DocumentSteps,
  DocumentStepsFromJSON,
  DocumentStepsFromJSONTyped,
  DocumentStepsToJSON,
} from './';

/**
 * Will steps object.
 * @export
 * @interface WillStepsRequestObject
 */
export interface WillStepsRequestObject {
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsRequestObject
   */
  about?: DocumentSteps;
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsRequestObject
   */
  assets?: DocumentSteps;
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsRequestObject
   */
  estate?: DocumentSteps;
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsRequestObject
   */
  executors?: DocumentSteps;
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsRequestObject
   */
  guardians?: DocumentSteps;
}

export function WillStepsRequestObjectFromJSON(
  json: any,
): WillStepsRequestObject {
  return WillStepsRequestObjectFromJSONTyped(json, false);
}

export function WillStepsRequestObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WillStepsRequestObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    about: !exists(json, 'about')
      ? undefined
      : DocumentStepsFromJSON(json['about']),
    assets: !exists(json, 'assets')
      ? undefined
      : DocumentStepsFromJSON(json['assets']),
    estate: !exists(json, 'estate')
      ? undefined
      : DocumentStepsFromJSON(json['estate']),
    executors: !exists(json, 'executors')
      ? undefined
      : DocumentStepsFromJSON(json['executors']),
    guardians: !exists(json, 'guardians')
      ? undefined
      : DocumentStepsFromJSON(json['guardians']),
  };
}

export function WillStepsRequestObjectToJSON(
  value?: WillStepsRequestObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    about: DocumentStepsToJSON(value.about),
    assets: DocumentStepsToJSON(value.assets),
    estate: DocumentStepsToJSON(value.estate),
    executors: DocumentStepsToJSON(value.executors),
    guardians: DocumentStepsToJSON(value.guardians),
  };
}
