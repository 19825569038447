import { Button } from '@material-ui/core';
import { useState } from 'react';

import useStyles from '@components/common/scroll-to-top-button/styles';
import { useGlobalScroll } from '@hooks/event-hooks';

const ScrollToTopButton: React.FC = () => {
  const [showButton, setShowButton] = useState(false);

  const classes = useStyles();

  const handleScroll = () => {
    setShowButton(window.scrollY > window.innerHeight);
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useGlobalScroll(handleScroll);

  return (
    <>
      {showButton && (
        <Button
          className={classes.wrapper}
          color="primary"
          variant="contained"
          type="button"
          onClick={moveToTop}
        >
          <i className="icon-arrow-up-bold" />
        </Button>
      )}
    </>
  );
};

export default ScrollToTopButton;
