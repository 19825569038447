import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    '&>span': {
      flexDirection: 'inherit',
    },

    '& i': {
      margin: '0 0 0 8px',
      fontSize: 20,
    },

    '&$iconReverse': {
      '& i': {
        margin: '0 8px 0 0',
        order: -1,
      },
    },
  },
  containedBlue: {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0px 10px 20px rgba(31, 144, 210, 0.25)',
    color: theme.palette.background.default,

    '&:hover': {
      backgroundColor: '#309BD9',
    },

    '& .MuiTouchRipple-root span': {
      backgroundColor: '#277FB3',
    },
  },
  containedPink: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.default,

    '&:hover': {
      backgroundColor: '#DC7A7A',
    },

    '& .MuiTouchRipple-root span': {
      backgroundColor: '#C06363',
    },
  },
  containedWhite: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&:active': {
      backgroundColor: theme.palette.background.paper,
    },

    '& .MuiTouchRipple-root span': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  containedRed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.error.dark,
    },

    '& .MuiTouchRipple-root span': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  outlineTransparent: {
    borderColor: theme.palette.info.dark,
    color: theme.palette.info.dark,

    '&:hover': {
      backgroundColor: theme.palette.info.light,
    },
    '&:active': {
      backgroundColor: theme.palette.text.disabled,
    },

    '& .MuiTouchRipple-root span': {
      backgroundColor: theme.palette.text.disabled,
      borderColor: 'currentColor',
    },
  },
  outlinePink: {
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&:active': {
      backgroundColor: theme.palette.secondary.main,
    },

    '& .MuiTouchRipple-root span': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  outlineRed: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&:active': {
      backgroundColor: theme.palette.secondary.main,
    },

    '& .MuiTouchRipple-root span': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  outlineBlue: {
    borderColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    height: 40,
    padding: '8px 24px',
    borderRadius: 8,

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
    },

    '& .MuiTouchRipple-root span': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  directionReverse: {
    flexDirection: 'row-reverse',

    '& i': {
      margin: '0 8px 0 0',
    },
  },
  rippleClasses: { color: 'red' },
  notInteractive: {
    pointerEvents: 'none',
  },
  downloadIcon: {
    marginRight: 8,
  },
  iconReverse: {
    /* nesting */
  },
  [theme.breakpoints.down('xs')]: {
    fullWidthOnMobile: { width: '100%' },
  },
}));

export default useStyles;
