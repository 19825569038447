/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Purchase object.
 * @export
 * @interface PurchaseObject
 */
export interface PurchaseObject {
  /**
   *
   * @type {number}
   * @memberof PurchaseObject
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof PurchaseObject
   */
  documentId: number;
  /**
   *
   * @type {string}
   * @memberof PurchaseObject
   */
  intentId: string;
  /**
   *
   * @type {number}
   * @memberof PurchaseObject
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof PurchaseObject
   */
  priceId: number;
}

export function PurchaseObjectFromJSON(json: any): PurchaseObject {
  return PurchaseObjectFromJSONTyped(json, false);
}

export function PurchaseObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PurchaseObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    documentId: json['document_id'],
    intentId: json['intent_id'],
    amount: json['amount'],
    priceId: json['price_id'],
  };
}

export function PurchaseObjectToJSON(value?: PurchaseObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    document_id: value.documentId,
    intent_id: value.intentId,
    amount: value.amount,
    price_id: value.priceId,
  };
}
