import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  disableScroll: {
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 'var(--header-height)',
    padding: '24px 0',
    backgroundColor: theme.palette.background.default,
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: { width: '141px', height: '48px' },
  mobileNavigation: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    zIndex: 1000,
    overscrollBehavior: 'none',
  },
  linksWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  linksReverse: {
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'space-between',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
    '& i': {
      color: theme.palette.background.default,
      fontSize: 24,
    },
  },
  blueSection: {
    background: 'linear-gradient(80.66deg, #38B6FF 17.5%, #81D9FF 129.92%)',
    padding: '32px 15px',
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    '& h6': {
      color: theme.palette.background.default,
    },
  },
  mobileLinks: {
    display: 'flex',
    flexDirection: 'column',
    '& i': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: 8,
    },
  },
  authButtonMobile: {
    color: theme.palette.secondary.dark,
    textAlign: 'left',
    '& p': {
      color: 'inherit',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  mobileLink: {
    display: 'inline-block',
    textDecoration: 'none',
    padding: '0 15px',
  },
  burgerIconWrapper: {
    alignItems: 'center',
    height: 48,
    width: 48,
    minWidth: 'initial',
    padding: 0,
    fontSize: 24,
    color: theme.palette.secondary.dark,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    userSelect: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  desktopNavigation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 80,
  },
  dropdownWrapper: {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative',
  },
  willDropdown: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    '& i': {},
  },
  userIcon: {
    fontSize: 16,
    marginRight: 8,
  },
  dropDownArrow: {
    fontSize: 10,
    marginLeft: 8,
    transition: 'transform 0.2s',
  },
  invert: {
    transform: 'rotate(180deg)',
  },
  open: {
    /* nesting */
  },
  dropdown: {
    position: 'absolute',
    top: 40,
    right: 0,
    padding: '14px 0 ',
    borderRadius: 16,
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 10px 40px rgb(0 0 0 / 10%)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    width: 264,
    zIndex: 1000,
    opacity: 0,
    visibility: 'hidden',
    transition: 'all 0.3s linear 0.2s',

    '&$open': {
      opacity: 1,
      visibility: 'visible',
      transition: 'all 0.3s ease-out',
    },

    '& a': {
      margin: '4px 16px',
      padding: '4px 8px',

      '&:hover': {
        textDecoration: 'none',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
        padding: '4px 8px',
        color: theme.palette.primary.dark,
      },

      '& p': {
        display: 'inline-block',
        color: 'inherit',
      },
    },
  },
  logOutButton: {
    textAlign: 'left',
    margin: '6px 16px',
    padding: '4px 8px',

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 8,
      padding: '4px 8px',
      color: theme.palette.primary.dark,
    },

    '& p': {
      color: 'inherit',
    },
  },
  desktopLink: {
    marginLeft: 48,

    '&:nth-child(2)': {
      marginLeft: 32,
    },
  },
  goBackLink: {
    display: 'flex',
    marginLeft: 'auto',
    color: theme.palette.info.main,

    '& p': {
      color: 'currentColor',
    },
  },
  inviteLink: {
    color: theme.palette.secondary.dark,

    '& p': {
      color: 'currentColor',
    },
  },
  [theme.breakpoints.down('sm')]: {
    navigation: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    desktopNavigation: {
      display: 'none',
    },

    mobileLink: {
      marginTop: 32,
    },
  },
  [theme.breakpoints.up('sm')]: {
    header: {
      padding: '11px 0',
      backgroundColor: 'transparent',
    },
    burgerIconWrapper: {
      height: 74,
      width: 74,
      backgroundColor: theme.palette.background.default,
      borderRadius: '100%',
      boxShadow: '0px 10px 20px rgb(14 87 129 / 25%)',

      '&:hover': {
        boxShadow: '0px 10px 20px rgb(14 87 129 / 25%)',
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    header: {
      padding: '36px 0',
    },
    mobileNavigation: {
      display: 'none',
    },
    userLogged: {
      backgroundColor: theme.palette.background.default,
      height: 'var(--header-height)',
    },
    burgerIconWrapper: {
      display: 'none',
    },
  },
}));

export default useStyles;
