import { DependencyList, useCallback, useEffect } from 'react';

export const useWindowEvent = (
  event: keyof WindowEventMap,
  callback: EventListener,
  dependencies?: DependencyList,
): void => {
  const deps = dependencies || [];
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const globalCallback = useCallback(callback, deps);

  useEffect(() => {
    window.addEventListener(event, globalCallback);

    return () => {
      window.removeEventListener(event, globalCallback);
    };
  }, [event, globalCallback]);
};

export const useGlobalScroll = (
  callback: EventListener,
  dependencies?: DependencyList,
): void => useWindowEvent('scroll', callback, dependencies);

export const useGlobalResize = (
  callback: EventListener,
  dependencies?: DependencyList,
): void => useWindowEvent('resize', callback, dependencies);
