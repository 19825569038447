/**
 * Returns a string of hex color with opacity.
 * @param color The hex color.
 * @param opacity The opacity from 0 to 1.
 */
const transparentize = (color: string, opacity: number): string => {
  return `${color}${`0${Math.round(opacity * 255).toString(16)}`.slice(-2)}`;
};

export default transparentize;
