/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Alert object.
 * @export
 * @interface AlertObject
 */
export interface AlertObject {
  /**
   *
   * @type {number}
   * @memberof AlertObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AlertObject
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof AlertObject
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AlertObject
   */
  buttonText: string;
  /**
   *
   * @type {string}
   * @memberof AlertObject
   */
  buttonLink: string;
}

export function AlertObjectFromJSON(json: any): AlertObject {
  return AlertObjectFromJSONTyped(json, false);
}

export function AlertObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AlertObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    description: json['description'],
    buttonText: json['button_text'],
    buttonLink: json['button_link'],
  };
}

export function AlertObjectToJSON(value?: AlertObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    description: value.description,
    button_text: value.buttonText,
    button_link: value.buttonLink,
  };
}
