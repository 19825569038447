/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Metatag object.
 * @export
 * @interface MetatagObject
 */
export interface MetatagObject {
  /**
   *
   * @type {number}
   * @memberof MetatagObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MetatagObject
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof MetatagObject
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof MetatagObject
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof MetatagObject
   */
  keywords: string;
}

export function MetatagObjectFromJSON(json: any): MetatagObject {
  return MetatagObjectFromJSONTyped(json, false);
}

export function MetatagObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MetatagObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    slug: json['slug'],
    title: json['title'],
    description: json['description'],
    keywords: json['keywords'],
  };
}

export function MetatagObjectToJSON(value?: MetatagObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    slug: value.slug,
    title: value.title,
    description: value.description,
    keywords: value.keywords,
  };
}
