/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gift types object.
 * @export
 * @enum {string}
 */
export enum GiftTypes {
  OneOffItem = 'One-off item',
  CollectionOfItems = 'Collection of items',
  Vehicle = 'Vehicle',
  SomeMoney = 'Some money',
}

export function GiftTypesFromJSON(json: any): GiftTypes {
  return GiftTypesFromJSONTyped(json, false);
}

export function GiftTypesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GiftTypes {
  return json as GiftTypes;
}

export function GiftTypesToJSON(value?: GiftTypes | null): any {
  return value as any;
}
