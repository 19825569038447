/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DocumentTypes,
  DocumentTypesFromJSON,
  DocumentTypesFromJSONTyped,
  DocumentTypesToJSON,
} from './';

/**
 * History object.
 * @export
 * @interface HistoryObject
 */
export interface HistoryObject {
  /**
   *
   * @type {number}
   * @memberof HistoryObject
   */
  id: number;
  /**
   *
   * @type {DocumentTypes}
   * @memberof HistoryObject
   */
  type: DocumentTypes;
  /**
   *
   * @type {string}
   * @memberof HistoryObject
   */
  file: string | null;
  /**
   *
   * @type {string}
   * @memberof HistoryObject
   */
  downloadedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof HistoryObject
   */
  signedAt?: string | null;
}

export function HistoryObjectFromJSON(json: any): HistoryObject {
  return HistoryObjectFromJSONTyped(json, false);
}

export function HistoryObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HistoryObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: DocumentTypesFromJSON(json['type']),
    file: json['file'],
    downloadedAt: !exists(json, 'downloaded_at')
      ? undefined
      : json['downloaded_at'],
    signedAt: !exists(json, 'signed_at') ? undefined : json['signed_at'],
  };
}

export function HistoryObjectToJSON(value?: HistoryObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: DocumentTypesToJSON(value.type),
    file: value.file,
    downloaded_at: value.downloadedAt,
    signed_at: value.signedAt,
  };
}
