/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ActorObject,
  ActorObjectFromJSON,
  ActorObjectFromJSONTyped,
  ActorObjectToJSON,
  AssetObject,
  AssetObjectFromJSON,
  AssetObjectFromJSONTyped,
  AssetObjectToJSON,
  BeneficiariesObject,
  BeneficiariesObjectFromJSON,
  BeneficiariesObjectFromJSONTyped,
  BeneficiariesObjectToJSON,
  CharityObject,
  CharityObjectFromJSON,
  CharityObjectFromJSONTyped,
  CharityObjectToJSON,
  DocumentProgress,
  DocumentProgressFromJSON,
  DocumentProgressFromJSONTyped,
  DocumentProgressToJSON,
  DocumentTypes,
  DocumentTypesFromJSON,
  DocumentTypesFromJSONTyped,
  DocumentTypesToJSON,
  EstateObject,
  EstateObjectFromJSON,
  EstateObjectFromJSONTyped,
  EstateObjectToJSON,
  GiftObject,
  GiftObjectFromJSON,
  GiftObjectFromJSONTyped,
  GiftObjectToJSON,
  Offers,
  OffersFromJSON,
  OffersFromJSONTyped,
  OffersToJSON,
  PetObject,
  PetObjectFromJSON,
  PetObjectFromJSONTyped,
  PetObjectToJSON,
  WillStepsObject,
  WillStepsObjectFromJSON,
  WillStepsObjectFromJSONTyped,
  WillStepsObjectToJSON,
} from './';

/**
 * Will object.
 * @export
 * @interface WillObject
 */
export interface WillObject {
  /**
   *
   * @type {number}
   * @memberof WillObject
   */
  id: number;
  /**
   *
   * @type {DocumentTypes}
   * @memberof WillObject
   */
  type: DocumentTypes;
  /**
   *
   * @type {DocumentProgress}
   * @memberof WillObject
   */
  progress: DocumentProgress;
  /**
   *
   * @type {ActorObject}
   * @memberof WillObject
   */
  owner: ActorObject | null;
  /**
   *
   * @type {ActorObject}
   * @memberof WillObject
   */
  partner: ActorObject | null;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof WillObject
   */
  children: Array<ActorObject> | null;
  /**
   *
   * @type {Array<PetObject>}
   * @memberof WillObject
   */
  pets: Array<PetObject> | null;
  /**
   *
   * @type {Array<AssetObject>}
   * @memberof WillObject
   */
  assets: Array<AssetObject> | null;
  /**
   *
   * @type {boolean}
   * @memberof WillObject
   */
  noAssets: boolean | null;
  /**
   *
   * @type {Array<CharityObject>}
   * @memberof WillObject
   */
  charities: Array<CharityObject> | null;
  /**
   *
   * @type {BeneficiariesObject}
   * @memberof WillObject
   */
  beneficiaries: BeneficiariesObject;
  /**
   *
   * @type {Array<EstateObject>}
   * @memberof WillObject
   */
  estate: Array<EstateObject> | null;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof WillObject
   */
  executors: Array<ActorObject> | null;
  /**
   *
   * @type {boolean}
   * @memberof WillObject
   */
  simpwillExecutor: boolean;
  /**
   *
   * @type {Array<GiftObject>}
   * @memberof WillObject
   */
  gifts: Array<GiftObject> | null;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof WillObject
   */
  adultActors: Array<ActorObject> | null;
  /**
   *
   * @type {WillStepsObject}
   * @memberof WillObject
   */
  steps: WillStepsObject;
  /**
   *
   * @type {boolean}
   * @memberof WillObject
   */
  unlimitedUpdates: boolean;
  /**
   *
   * @type {string}
   * @memberof WillObject
   */
  downloadedAt: string;
  /**
   *
   * @type {Array<Offers>}
   * @memberof WillObject
   */
  exclusiveOffers: Array<Offers> | null;
  /**
   *
   * @type {string}
   * @memberof WillObject
   */
  signedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof WillObject
   */
  printed: boolean;
  /**
   *
   * @type {string}
   * @memberof WillObject
   */
  witness1: string | null;
  /**
   *
   * @type {string}
   * @memberof WillObject
   */
  witness2: string | null;
  /**
   *
   * @type {string}
   * @memberof WillObject
   */
  storage: string | null;
  /**
   *
   * @type {string}
   * @memberof WillObject
   */
  purchasedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof WillObject
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof WillObject
   */
  canEdit: boolean;
}

export function WillObjectFromJSON(json: any): WillObject {
  return WillObjectFromJSONTyped(json, false);
}

export function WillObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WillObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: DocumentTypesFromJSON(json['type']),
    progress: DocumentProgressFromJSON(json['progress']),
    owner: ActorObjectFromJSON(json['owner']),
    partner: ActorObjectFromJSON(json['partner']),
    children:
      json['children'] === null
        ? null
        : (json['children'] as Array<any>).map(ActorObjectFromJSON),
    pets:
      json['pets'] === null
        ? null
        : (json['pets'] as Array<any>).map(PetObjectFromJSON),
    assets:
      json['assets'] === null
        ? null
        : (json['assets'] as Array<any>).map(AssetObjectFromJSON),
    noAssets: json['no_assets'],
    charities:
      json['charities'] === null
        ? null
        : (json['charities'] as Array<any>).map(CharityObjectFromJSON),
    beneficiaries: BeneficiariesObjectFromJSON(json['beneficiaries']),
    estate:
      json['estate'] === null
        ? null
        : (json['estate'] as Array<any>).map(EstateObjectFromJSON),
    executors:
      json['executors'] === null
        ? null
        : (json['executors'] as Array<any>).map(ActorObjectFromJSON),
    simpwillExecutor: json['simpwill_executor'],
    gifts:
      json['gifts'] === null
        ? null
        : (json['gifts'] as Array<any>).map(GiftObjectFromJSON),
    adultActors:
      json['adult_actors'] === null
        ? null
        : (json['adult_actors'] as Array<any>).map(ActorObjectFromJSON),
    steps: WillStepsObjectFromJSON(json['steps']),
    unlimitedUpdates: json['unlimited_updates'],
    downloadedAt: json['downloaded_at'],
    exclusiveOffers:
      json['exclusive_offers'] === null
        ? null
        : (json['exclusive_offers'] as Array<any>).map(OffersFromJSON),
    signedAt: json['signed_at'],
    printed: json['printed'],
    witness1: json['witness1'],
    witness2: json['witness2'],
    storage: json['storage'],
    purchasedAt: json['purchased_at'],
    updatedAt: json['updated_at'],
    canEdit: json['can_edit'],
  };
}

export function WillObjectToJSON(value?: WillObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: DocumentTypesToJSON(value.type),
    progress: DocumentProgressToJSON(value.progress),
    owner: ActorObjectToJSON(value.owner),
    partner: ActorObjectToJSON(value.partner),
    children:
      value.children === null
        ? null
        : (value.children as Array<any>).map(ActorObjectToJSON),
    pets:
      value.pets === null
        ? null
        : (value.pets as Array<any>).map(PetObjectToJSON),
    assets:
      value.assets === null
        ? null
        : (value.assets as Array<any>).map(AssetObjectToJSON),
    no_assets: value.noAssets,
    charities:
      value.charities === null
        ? null
        : (value.charities as Array<any>).map(CharityObjectToJSON),
    beneficiaries: BeneficiariesObjectToJSON(value.beneficiaries),
    estate:
      value.estate === null
        ? null
        : (value.estate as Array<any>).map(EstateObjectToJSON),
    executors:
      value.executors === null
        ? null
        : (value.executors as Array<any>).map(ActorObjectToJSON),
    simpwill_executor: value.simpwillExecutor,
    gifts:
      value.gifts === null
        ? null
        : (value.gifts as Array<any>).map(GiftObjectToJSON),
    adult_actors:
      value.adultActors === null
        ? null
        : (value.adultActors as Array<any>).map(ActorObjectToJSON),
    steps: WillStepsObjectToJSON(value.steps),
    unlimited_updates: value.unlimitedUpdates,
    downloaded_at: value.downloadedAt,
    exclusive_offers:
      value.exclusiveOffers === null
        ? null
        : (value.exclusiveOffers as Array<any>).map(OffersToJSON),
    signed_at: value.signedAt,
    printed: value.printed,
    witness1: value.witness1,
    witness2: value.witness2,
    storage: value.storage,
    purchased_at: value.purchasedAt,
    updated_at: value.updatedAt,
    can_edit: value.canEdit,
  };
}
