/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Payment Intent object.
 * @export
 * @interface PaymentIntentObject
 */
export interface PaymentIntentObject {
  /**
   *
   * @type {string}
   * @memberof PaymentIntentObject
   */
  intentId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentIntentObject
   */
  clientSecret: string;
}

export function PaymentIntentObjectFromJSON(json: any): PaymentIntentObject {
  return PaymentIntentObjectFromJSONTyped(json, false);
}

export function PaymentIntentObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    intentId: json['intent_id'],
    clientSecret: json['client_secret'],
  };
}

export function PaymentIntentObjectToJSON(
  value?: PaymentIntentObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    intent_id: value.intentId,
    client_secret: value.clientSecret,
  };
}
