/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CouponRequest,
  CouponRequestFromJSON,
  CouponRequestToJSON,
  CouponResponse,
  CouponResponseFromJSON,
  CouponResponseToJSON,
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
} from '../models';

export interface CheckCouponRequest {
  couponRequest: CouponRequest;
}

/**
 *
 */
export class StripeApi extends runtime.BaseAPI {
  /**
   * Check coupon
   */
  async checkCouponRaw(
    requestParameters: CheckCouponRequest,
  ): Promise<runtime.ApiResponse<CouponResponse>> {
    if (
      requestParameters.couponRequest === null ||
      requestParameters.couponRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'couponRequest',
        'Required parameter requestParameters.couponRequest was null or undefined when calling checkCoupon.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/coupon/check`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CouponRequestToJSON(requestParameters.couponRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CouponResponseFromJSON(jsonValue),
    );
  }

  /**
   * Check coupon
   */
  async checkCoupon(
    requestParameters: CheckCouponRequest,
  ): Promise<CouponResponse> {
    const response = await this.checkCouponRaw(requestParameters);
    return await response.value();
  }
}
