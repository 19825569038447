import {
  Configuration,
  ActorsApi,
  AssetsApi,
  AuthApi,
  CharitiesApi,
  DocumentsApi,
  GiftsApi,
  LpasApi,
  PetsApi,
  PreferencesApi,
  QuestionsApi,
  UsersApi,
  WillsApi,
  StripeApi,
  GuidesApi,
  BlogApi,
  MetatagsApi,
  PricesApi,
  KlarnaApi,
} from '@lib/api';
import { getState } from '@store/wrapper';

const config = new Configuration({
  basePath: process.env.NEXT_PUBLIC_API_URL,
  accessToken: () => getState().auth.token || '',
  fetchApi: typeof window === 'undefined' ? fetch : undefined,
});

export const ActorsAPI = new ActorsApi(config);
export const AssetsAPI = new AssetsApi(config);
export const AuthAPI = new AuthApi(config);
export const BlogAPI = new BlogApi(config);
export const CharitiesAPI = new CharitiesApi(config);
export const DocumentsAPI = new DocumentsApi(config);
export const GiftsAPI = new GiftsApi(config);
export const GuidesAPI = new GuidesApi(config);
export const LpasAPI = new LpasApi(config);
export const MetatagsAPI = new MetatagsApi(config);
export const PetsAPI = new PetsApi(config);
export const PreferencesAPI = new PreferencesApi(config);
export const QuestionsAPI = new QuestionsApi(config);
export const UsersAPI = new UsersApi(config);
export const WillsAPI = new WillsApi(config);
export const StripeAPI = new StripeApi(config);
export const PricesAPI = new PricesApi(config);
export const KlarnaAPI = new KlarnaApi(config);
