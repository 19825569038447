import { IconButton, Snackbar } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import useStyles from './styles';

const AUTO_HIDE_DURATION = 5_000;
const ANIMATION_DELAY = 1_500;

type Variant = 'error' | 'warning' | 'info' | 'success';

const icons: Record<Variant, JSX.Element> = {
  error: <ErrorOutlineOutlinedIcon />,
  info: <ErrorOutlineOutlinedIcon />,
  warning: <ReportProblemOutlinedIcon />,
  success: <CheckCircleOutlineIcon />,
};

interface AlertProps {
  message: string;
  variant: Variant;
}

const Alert: React.FC<AlertProps> = (props) => {
  const { message, variant } = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Snackbar
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={(_e, reason) => reason === 'timeout' && setOpen(false)}
      message={
        <>
          {icons[variant]}
          {message || 'Something went wrong'}
        </>
      }
      ContentProps={{
        className: classes.innerContent,
      }}
      className={classNames(classes.alert, classes[variant])}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <i className={classNames('icon-close', classes.closeIcon)} />
        </IconButton>
      }
    />
  );
};

class Toast {
  private static getContainer(): HTMLElement | null {
    return typeof document !== 'undefined'
      ? document.getElementById('toast-container')
      : null;
  }

  private static renderToast(message: string, variant: Variant): void {
    const container = Toast.getContainer();

    if (container) {
      const div = document.createElement('div');

      ReactDOM.render(<Alert message={message} variant={variant} />, div);
      container.appendChild(div);
      setTimeout(
        () => container.removeChild(div),
        AUTO_HIDE_DURATION + ANIMATION_DELAY,
      );
    }
  }

  static info(message: string): void {
    Toast.renderToast(message, 'info');
  }

  static warning(message: string): void {
    Toast.renderToast(message, 'warning');
  }

  static error(message: string): void {
    Toast.renderToast(message, 'error');
  }

  static success(message: string): void {
    Toast.renderToast(message, 'success');
  }
}

export default Toast;
