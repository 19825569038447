/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Asset types object.
 * @export
 * @enum {string}
 */
export enum AssetTypes {
  BankAccount = 'Bank account',
  Property = 'Property',
  Pension = 'Pension',
  FuneralCare = 'Funeral care',
  LifeInsurance = 'Life insurance',
  StocksAndShares = 'Stocks and shares',
  Other = 'Other',
}

export function AssetTypesFromJSON(json: any): AssetTypes {
  return AssetTypesFromJSONTyped(json, false);
}

export function AssetTypesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AssetTypes {
  return json as AssetTypes;
}

export function AssetTypesToJSON(value?: AssetTypes | null): any {
  return value as any;
}
