/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
  LpaComponentsRequest,
  LpaComponentsRequestFromJSON,
  LpaComponentsRequestToJSON,
  LpaResponse,
  LpaResponseFromJSON,
  LpaResponseToJSON,
  PurchaseRequest,
  PurchaseRequestFromJSON,
  PurchaseRequestToJSON,
  PurchaseResponse,
  PurchaseResponseFromJSON,
  PurchaseResponseToJSON,
} from '../models';

export interface GetLpaRequest {
  lpa: number;
}

export interface PurchaseLpasRequest {
  document: number;
  purchaseRequest: PurchaseRequest;
}

export interface UpdateLpaRequest {
  lpa: number;
  lpaComponentsRequest: LpaComponentsRequest;
}

/**
 *
 */
export class LpasApi extends runtime.BaseAPI {
  /**
   * Get information on lpa.
   */
  async getLpaRaw(
    requestParameters: GetLpaRequest,
  ): Promise<runtime.ApiResponse<LpaResponse>> {
    if (requestParameters.lpa === null || requestParameters.lpa === undefined) {
      throw new runtime.RequiredError(
        'lpa',
        'Required parameter requestParameters.lpa was null or undefined when calling getLpa.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/lpas/{lpa}`.replace(
        `{${'lpa'}}`,
        encodeURIComponent(String(requestParameters.lpa)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LpaResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get information on lpa.
   */
  async getLpa(requestParameters: GetLpaRequest): Promise<LpaResponse> {
    const response = await this.getLpaRaw(requestParameters);
    return await response.value();
  }

  /**
   * Purchase lpas
   */
  async purchaseLpasRaw(
    requestParameters: PurchaseLpasRequest,
  ): Promise<runtime.ApiResponse<PurchaseResponse>> {
    if (
      requestParameters.document === null ||
      requestParameters.document === undefined
    ) {
      throw new runtime.RequiredError(
        'document',
        'Required parameter requestParameters.document was null or undefined when calling purchaseLpas.',
      );
    }

    if (
      requestParameters.purchaseRequest === null ||
      requestParameters.purchaseRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'purchaseRequest',
        'Required parameter requestParameters.purchaseRequest was null or undefined when calling purchaseLpas.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/lpas/{document}/purchase`.replace(
        `{${'document'}}`,
        encodeURIComponent(String(requestParameters.document)),
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PurchaseRequestToJSON(requestParameters.purchaseRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PurchaseResponseFromJSON(jsonValue),
    );
  }

  /**
   * Purchase lpas
   */
  async purchaseLpas(
    requestParameters: PurchaseLpasRequest,
  ): Promise<PurchaseResponse> {
    const response = await this.purchaseLpasRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get information on lpa.
   */
  async updateLpaRaw(
    requestParameters: UpdateLpaRequest,
  ): Promise<runtime.ApiResponse<LpaResponse>> {
    if (requestParameters.lpa === null || requestParameters.lpa === undefined) {
      throw new runtime.RequiredError(
        'lpa',
        'Required parameter requestParameters.lpa was null or undefined when calling updateLpa.',
      );
    }

    if (
      requestParameters.lpaComponentsRequest === null ||
      requestParameters.lpaComponentsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'lpaComponentsRequest',
        'Required parameter requestParameters.lpaComponentsRequest was null or undefined when calling updateLpa.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/lpas/{lpa}`.replace(
        `{${'lpa'}}`,
        encodeURIComponent(String(requestParameters.lpa)),
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: LpaComponentsRequestToJSON(requestParameters.lpaComponentsRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LpaResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get information on lpa.
   */
  async updateLpa(requestParameters: UpdateLpaRequest): Promise<LpaResponse> {
    const response = await this.updateLpaRaw(requestParameters);
    return await response.value();
  }
}
