/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PricesObject,
  PricesObjectFromJSON,
  PricesObjectFromJSONTyped,
  PricesObjectToJSON,
} from './';

/**
 * Create payment intent request object.
 * @export
 * @interface CreateIntentRequest
 */
export interface CreateIntentRequest {
  /**
   *
   * @type {PricesObject}
   * @memberof CreateIntentRequest
   */
  priceStatus: PricesObject;
  /**
   *
   * @type {string}
   * @memberof CreateIntentRequest
   */
  coupon?: string;
}

export function CreateIntentRequestFromJSON(json: any): CreateIntentRequest {
  return CreateIntentRequestFromJSONTyped(json, false);
}

export function CreateIntentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateIntentRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    priceStatus: PricesObjectFromJSON(json['price_status']),
    coupon: !exists(json, 'coupon') ? undefined : json['coupon'],
  };
}

export function CreateIntentRequestToJSON(
  value?: CreateIntentRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    price_status: PricesObjectToJSON(value.priceStatus),
    coupon: value.coupon,
  };
}
