import { AnyAction, Dispatch, Middleware } from 'redux';

import { logOut } from '@store/auth';
import { getUser } from '@store/auth/thunks';
import { getDocuments } from '@store/documents/thunks';
import { RootState } from '@store/index';

const middleware: Middleware =
  ({ getState, dispatch }: { getState: () => RootState; dispatch: Dispatch }) =>
  (next) =>
  (action: AnyAction) => {
    const { token } = getState().auth;

    if (action.type === getUser.rejected.type && action.error?.code === '403') {
      dispatch(logOut());

      return;
    }

    if (!token && action.type === getDocuments.fulfilled.type) {
      return;
    }

    next(action);
  };

export default middleware;
