import { withStyles } from '@material-ui/core';

import Footer from '@components/common/footer';
import Header from '@components/common/header';
import ScrollToTopButton from '@components/common/scroll-to-top-button';
import cssVariables from '@utils/material-ui-theme/css-variables';

interface Props {
  withoutHeader?: true;
  headerGoBackLink?: string;
  withoutFooter?: true;
  withoutScrollToTop?: true;
  withoutFooterBigCircle?: true;
}

const Layout: React.FC<Props> = (props) => {
  const {
    children,
    withoutHeader,
    headerGoBackLink,
    withoutFooter,
    withoutScrollToTop,
    withoutFooterBigCircle,
  } = props;

  return (
    <>
      {!withoutScrollToTop && <ScrollToTopButton />}
      {!withoutHeader && <Header goBackLink={headerGoBackLink} />}
      {children}
      {!withoutFooter && <Footer withoutBigCircle={withoutFooterBigCircle} />}

      <div id="toast-container" />
    </>
  );
};

export default withStyles(cssVariables)(Layout);
