/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ActorCreateRequest,
  ActorCreateRequestFromJSON,
  ActorCreateRequestToJSON,
  ActorDocumentResponse,
  ActorDocumentResponseFromJSON,
  ActorDocumentResponseToJSON,
  ActorUpdateRequest,
  ActorUpdateRequestFromJSON,
  ActorUpdateRequestToJSON,
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
} from '../models';

export interface CreateActorRequest {
  actorCreateRequest: ActorCreateRequest;
}

export interface DeleteActorRequest {
  actor: number;
}

export interface UpdateActorRequest {
  actor: number;
  actorUpdateRequest: ActorUpdateRequest;
}

/**
 *
 */
export class ActorsApi extends runtime.BaseAPI {
  /**
   * Create actor.
   */
  async createActorRaw(
    requestParameters: CreateActorRequest,
  ): Promise<runtime.ApiResponse<ActorDocumentResponse>> {
    if (
      requestParameters.actorCreateRequest === null ||
      requestParameters.actorCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'actorCreateRequest',
        'Required parameter requestParameters.actorCreateRequest was null or undefined when calling createActor.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/actors`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ActorCreateRequestToJSON(requestParameters.actorCreateRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActorDocumentResponseFromJSON(jsonValue),
    );
  }

  /**
   * Create actor.
   */
  async createActor(
    requestParameters: CreateActorRequest,
  ): Promise<ActorDocumentResponse> {
    const response = await this.createActorRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete actor.
   */
  async deleteActorRaw(
    requestParameters: DeleteActorRequest,
  ): Promise<runtime.ApiResponse<ActorDocumentResponse>> {
    if (
      requestParameters.actor === null ||
      requestParameters.actor === undefined
    ) {
      throw new runtime.RequiredError(
        'actor',
        'Required parameter requestParameters.actor was null or undefined when calling deleteActor.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/actors/{actor}`.replace(
        `{${'actor'}}`,
        encodeURIComponent(String(requestParameters.actor)),
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActorDocumentResponseFromJSON(jsonValue),
    );
  }

  /**
   * Delete actor.
   */
  async deleteActor(
    requestParameters: DeleteActorRequest,
  ): Promise<ActorDocumentResponse> {
    const response = await this.deleteActorRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update actor.
   */
  async updateActorRaw(
    requestParameters: UpdateActorRequest,
  ): Promise<runtime.ApiResponse<ActorDocumentResponse>> {
    if (
      requestParameters.actor === null ||
      requestParameters.actor === undefined
    ) {
      throw new runtime.RequiredError(
        'actor',
        'Required parameter requestParameters.actor was null or undefined when calling updateActor.',
      );
    }

    if (
      requestParameters.actorUpdateRequest === null ||
      requestParameters.actorUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'actorUpdateRequest',
        'Required parameter requestParameters.actorUpdateRequest was null or undefined when calling updateActor.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/actors/{actor}`.replace(
        `{${'actor'}}`,
        encodeURIComponent(String(requestParameters.actor)),
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: ActorUpdateRequestToJSON(requestParameters.actorUpdateRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActorDocumentResponseFromJSON(jsonValue),
    );
  }

  /**
   * Update actor.
   */
  async updateActor(
    requestParameters: UpdateActorRequest,
  ): Promise<ActorDocumentResponse> {
    const response = await this.updateActorRaw(requestParameters);
    return await response.value();
  }
}
