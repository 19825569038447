/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ActorObject,
  ActorObjectFromJSON,
  ActorObjectFromJSONTyped,
  ActorObjectToJSON,
} from './';

/**
 * Pet object.
 * @export
 * @interface PetObject
 */
export interface PetObject {
  /**
   *
   * @type {number}
   * @memberof PetObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PetObject
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PetObject
   */
  type: string;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof PetObject
   */
  guardians: Array<ActorObject> | null;
}

export function PetObjectFromJSON(json: any): PetObject {
  return PetObjectFromJSONTyped(json, false);
}

export function PetObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PetObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    type: json['type'],
    guardians:
      json['guardians'] === null
        ? null
        : (json['guardians'] as Array<any>).map(ActorObjectFromJSON),
  };
}

export function PetObjectToJSON(value?: PetObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    type: value.type,
    guardians:
      value.guardians === null
        ? null
        : (value.guardians as Array<any>).map(ActorObjectToJSON),
  };
}
