import { makeStyles } from '@material-ui/core/styles';

import transparentize from '@utils/transparentize';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  textCenter: {
    textAlign: 'center',
  },
  popupWrapper: {
    zIndex: 1000,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.default,
    overflowY: 'auto',
  },
  imageSection: {
    position: 'relative',
    width: '100%',
  },
  stickToBottom: {
    /* nesting */
  },
  popupImage: {
    position: 'absolute',
    bottom: 28,
    left: '50%',
    transform: 'translateX(-50%)',
    height: 186,

    '&$stickToBottom': {
      bottom: 0,
    },
  },
  greySection: {
    height: 147,
    backgroundColor: '#EBF1F5',
    borderRadius: 24,
    marginTop: 100,
  },
  popup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 15px',
    position: 'relative',
  },
  title: {
    margin: '40px 0 8px',
  },
  description: {
    marginBottom: 40,
  },
  linkButton: { width: '100%' },
  closeIcon: {
    position: 'absolute',
    top: 40,
    right: 15,
    backgroundColor: theme.palette.background.default,
    padding: 5,
    borderRadius: 2,
    zIndex: 1000,
    '& i': {
      fontSize: 24,
      color: theme.palette.info.main,
    },
  },
  crossIcon: {
    fontSize: 24,
    color: theme.palette.info.main,
  },
  closeButtonMobile: {
    marginLeft: 'auto',
  },
  closeButton: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    popupWrapper: {
      backgroundColor: transparentize(theme.palette.secondary.main, 0.8),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    popup: {
      backgroundColor: theme.palette.background.default,
      boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.1)',
      borderRadius: 24,
      width: 690,
      maxHeight: '90vh',
      padding: 40,
    },
    editCase: {
      padding: '40px 40px 10px',

      '&>div': {
        width: 'calc(100% + 40px)',
        overflow: 'auto',
        padding: '0 20px 30px 20px',
        margin: '0 -20px',
        scrollbarColor: `${theme.palette.primary.dark} #E3E3E3`,
        scrollbarWidth: 'thin',

        '&::-webkit-scrollbar': {
          width: 8,
          paddingTop: 10,
          marginTop: 15,
          marginRight: -10,
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#E3E3E3',
          borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.primary.dark,
          borderRadius: 4,
        },
      },
    },
    imageSection: {
      '& img': {
        objectFit: 'cover',
        bottom: 16,
      },
    },
    greySection: {
      marginTop: 44,
    },
    linkButton: {
      width: 202,
    },
    closeIcon: {
      right: 40,
    },
    closeButtonMobile: {
      display: 'none',
    },
    closeButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 24,

      '& i': {
        marginRight: 8,
      },
      '& p': {
        color: theme.palette.info.main,
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    popup: {
      width: 770,
    },
  },
  [theme.breakpoints.up('lg')]: {
    popup: {
      width: 734,
    },
  },
}));

export default useStyles;
