/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  LpaObject,
  LpaObjectFromJSON,
  LpaObjectFromJSONTyped,
  LpaObjectToJSON,
  WillObject,
  WillObjectFromJSON,
  WillObjectFromJSONTyped,
  WillObjectToJSON,
} from './';

/**
 * Actor document object.
 * @export
 * @interface ActorDocumentObject
 */
export interface ActorDocumentObject {
  /**
   *
   * @type {WillObject}
   * @memberof ActorDocumentObject
   */
  will?: WillObject;
  /**
   *
   * @type {LpaObject}
   * @memberof ActorDocumentObject
   */
  lpa?: LpaObject;
}

export function ActorDocumentObjectFromJSON(json: any): ActorDocumentObject {
  return ActorDocumentObjectFromJSONTyped(json, false);
}

export function ActorDocumentObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActorDocumentObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    will: !exists(json, 'will') ? undefined : WillObjectFromJSON(json['will']),
    lpa: !exists(json, 'lpa') ? undefined : LpaObjectFromJSON(json['lpa']),
  };
}

export function ActorDocumentObjectToJSON(
  value?: ActorDocumentObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    will: WillObjectToJSON(value.will),
    lpa: LpaObjectToJSON(value.lpa),
  };
}
