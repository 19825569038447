/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ClientSecretObject,
  ClientSecretObjectFromJSON,
  ClientSecretObjectFromJSONTyped,
  ClientSecretObjectToJSON,
} from './';

/**
 * Purchase response object.
 * @export
 * @interface PurchaseResponse
 */
export interface PurchaseResponse {
  /**
   *
   * @type {ClientSecretObject}
   * @memberof PurchaseResponse
   */
  data: ClientSecretObject;
}

export function PurchaseResponseFromJSON(json: any): PurchaseResponse {
  return PurchaseResponseFromJSONTyped(json, false);
}

export function PurchaseResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PurchaseResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: ClientSecretObjectFromJSON(json['data']),
  };
}

export function PurchaseResponseToJSON(value?: PurchaseResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ClientSecretObjectToJSON(value.data),
  };
}
