/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  GuideResponse,
  GuideResponseFromJSON,
  GuideResponseToJSON,
  GuidesCollectionResponse,
  GuidesCollectionResponseFromJSON,
  GuidesCollectionResponseToJSON,
} from '../models';

export interface GetOneGuideRequest {
  guide: number;
}

/**
 *
 */
export class GuidesApi extends runtime.BaseAPI {
  /**
   * Get guides.
   */
  async getGuidesRaw(): Promise<runtime.ApiResponse<GuidesCollectionResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/guides`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GuidesCollectionResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get guides.
   */
  async getGuides(): Promise<GuidesCollectionResponse> {
    const response = await this.getGuidesRaw();
    return await response.value();
  }

  /**
   * Get one guide.
   */
  async getOneGuideRaw(
    requestParameters: GetOneGuideRequest,
  ): Promise<runtime.ApiResponse<GuideResponse>> {
    if (
      requestParameters.guide === null ||
      requestParameters.guide === undefined
    ) {
      throw new runtime.RequiredError(
        'guide',
        'Required parameter requestParameters.guide was null or undefined when calling getOneGuide.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/guides/{guide}`.replace(
        `{${'guide'}}`,
        encodeURIComponent(String(requestParameters.guide)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GuideResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get one guide.
   */
  async getOneGuide(
    requestParameters: GetOneGuideRequest,
  ): Promise<GuideResponse> {
    const response = await this.getOneGuideRaw(requestParameters);
    return await response.value();
  }
}
