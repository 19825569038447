/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AttorneysCanAct,
  AttorneysCanActFromJSON,
  AttorneysCanActFromJSONTyped,
  AttorneysCanActToJSON,
  AttorneysDecisions,
  AttorneysDecisionsFromJSON,
  AttorneysDecisionsFromJSONTyped,
  AttorneysDecisionsToJSON,
  DocumentProgress,
  DocumentProgressFromJSON,
  DocumentProgressFromJSONTyped,
  DocumentProgressToJSON,
  DonorAndAppliers,
  DonorAndAppliersFromJSON,
  DonorAndAppliersFromJSONTyped,
  DonorAndAppliersToJSON,
  ReceiverContactTypesObject,
  ReceiverContactTypesObjectFromJSON,
  ReceiverContactTypesObjectFromJSONTyped,
  ReceiverContactTypesObjectToJSON,
} from './';

/**
 * Lpa components.
 * @export
 * @interface LpaComponentsRequest
 */
export interface LpaComponentsRequest {
  /**
   *
   * @type {DocumentProgress}
   * @memberof LpaComponentsRequest
   */
  progress?: DocumentProgress;
  /**
   *
   * @type {boolean}
   * @memberof LpaComponentsRequest
   */
  mentalCapacity?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LpaComponentsRequest
   */
  reducedFee?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LpaComponentsRequest
   */
  lifeSustainingTreatment?: boolean;
  /**
   *
   * @type {AttorneysDecisions}
   * @memberof LpaComponentsRequest
   */
  attorneysDecisions?: AttorneysDecisions;
  /**
   *
   * @type {AttorneysCanAct}
   * @memberof LpaComponentsRequest
   */
  attorneysCanAct?: AttorneysCanAct;
  /**
   *
   * @type {Array<number>}
   * @memberof LpaComponentsRequest
   */
  attorneys?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof LpaComponentsRequest
   */
  replacementAttorneys?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof LpaComponentsRequest
   */
  notify?: Array<number>;
  /**
   *
   * @type {ReceiverContactTypesObject}
   * @memberof LpaComponentsRequest
   */
  contactTypes?: ReceiverContactTypesObject | null;
  /**
   *
   * @type {boolean}
   * @memberof LpaComponentsRequest
   */
  noReplacementAttorneys?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof LpaComponentsRequest
   */
  noNotifiers?: boolean | null;
  /**
   *
   * @type {DonorAndAppliers}
   * @memberof LpaComponentsRequest
   */
  applier?: DonorAndAppliers;
}

export function LpaComponentsRequestFromJSON(json: any): LpaComponentsRequest {
  return LpaComponentsRequestFromJSONTyped(json, false);
}

export function LpaComponentsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LpaComponentsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    progress: !exists(json, 'progress')
      ? undefined
      : DocumentProgressFromJSON(json['progress']),
    mentalCapacity: !exists(json, 'mental_capacity')
      ? undefined
      : json['mental_capacity'],
    reducedFee: !exists(json, 'reduced_fee') ? undefined : json['reduced_fee'],
    lifeSustainingTreatment: !exists(json, 'life_sustaining_treatment')
      ? undefined
      : json['life_sustaining_treatment'],
    attorneysDecisions: !exists(json, 'attorneys_decisions')
      ? undefined
      : AttorneysDecisionsFromJSON(json['attorneys_decisions']),
    attorneysCanAct: !exists(json, 'attorneys_can_act')
      ? undefined
      : AttorneysCanActFromJSON(json['attorneys_can_act']),
    attorneys: !exists(json, 'attorneys') ? undefined : json['attorneys'],
    replacementAttorneys: !exists(json, 'replacement_attorneys')
      ? undefined
      : json['replacement_attorneys'],
    notify: !exists(json, 'notify') ? undefined : json['notify'],
    contactTypes: !exists(json, 'contact_types')
      ? undefined
      : ReceiverContactTypesObjectFromJSON(json['contact_types']),
    noReplacementAttorneys: !exists(json, 'no_replacement_attorneys')
      ? undefined
      : json['no_replacement_attorneys'],
    noNotifiers: !exists(json, 'no_notifiers')
      ? undefined
      : json['no_notifiers'],
    applier: !exists(json, 'applier')
      ? undefined
      : DonorAndAppliersFromJSON(json['applier']),
  };
}

export function LpaComponentsRequestToJSON(
  value?: LpaComponentsRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    progress: DocumentProgressToJSON(value.progress),
    mental_capacity: value.mentalCapacity,
    reduced_fee: value.reducedFee,
    life_sustaining_treatment: value.lifeSustainingTreatment,
    attorneys_decisions: AttorneysDecisionsToJSON(value.attorneysDecisions),
    attorneys_can_act: AttorneysCanActToJSON(value.attorneysCanAct),
    attorneys: value.attorneys,
    replacement_attorneys: value.replacementAttorneys,
    notify: value.notify,
    contact_types: ReceiverContactTypesObjectToJSON(value.contactTypes),
    no_replacement_attorneys: value.noReplacementAttorneys,
    no_notifiers: value.noNotifiers,
    applier: DonorAndAppliersToJSON(value.applier),
  };
}
