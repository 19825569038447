import { Container, Typography, Link as MaterialLink } from '@material-ui/core';
// import classnames from 'classnames';
import { format } from 'date-fns';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import Link from 'next/link';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@components/common/loader';
import { BlogsProps } from '@pages/blog';
import { RootState } from '@store/index';
import { getArticles } from '@store/landings/thunks';
import idNormalize from '@utils/id-normalize';
import useStyles from '@views/blog/styles';

// interface TagProps {
//   title?: string;
//   color?: string;
//   tagClass?: string;
// }
// const Tag = (props: TagProps) => {
//   const { title, tagClass } = props;
//   const classes = useStyles({
//     tag: props,
//   });
//   return (
//     <div className={classnames(classes.tag, tagClass)}>
//       <Typography variant="button">{title}</Typography>
//     </div>
//   );
// };

const Blog: React.FC<BlogsProps> = (props) => {
  const { articles: staticArticles } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { articles = staticArticles, isLoading } = useSelector(
    (state: RootState) => state.landings,
  );

  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);

  return (
    <>
      <section className={classes.topSection}>
        <Container maxWidth={false}>
          <Typography component="h1" variant="h3" className={classes.title}>
            Blog
          </Typography>
          <Typography className={classes.subtitle} variant="body2">
            Check out our collection of blogs which focus on all things wills,
            will writing, probate and online will services! A will can be really
            daunting to think of, so we want to take away some of that pressure
            by ensuring we make the process as easy as possible, no confusing
            jargon and no tough conversations, just informative blogs written by
            experts! If you are thinking of utilising our revolutionary online
            will writing services, read on for simplified blogs explaining, some
            of the more complicated elements.
          </Typography>
        </Container>
      </section>

      {isLoading && !articles?.records && (
        <div className={classes.loaderWrapper}>
          <Loader size="sm" marginAuto />
        </div>
      )}

      {articles && (
        <section className={classes.articlesWrapper}>
          <Container maxWidth={false}>
            {articles.records.map((article) => {
              const { id, image, title, preview, author, createdAt } = article;

              return (
                <article key={title} className={classes.card}>
                  <div className={classes.articleImage}>
                    <img src={image} alt={title} />
                  </div>
                  {/* <Tag title={tag} /> */}
                  <div className={classes.articleText}>
                    <Link href={`/blog/${id}-${_.kebabCase(title)}`} passHref>
                      <MaterialLink id={`${idNormalize(title)}`}>
                        <Typography
                          className={classes.articleTitle}
                          variant="body1"
                        >
                          {title}
                        </Typography>
                      </MaterialLink>
                    </Link>

                    <Typography variant="body2">{preview}</Typography>
                  </div>
                  <div className={classes.authorBlock}>
                    <img
                      alt={`${author.firstName} ${author.lastName} avatar`}
                      src={author.avatar}
                    />
                    <div className={classes.authorText}>
                      <Typography variant="body2">
                        {`${author.firstName} ${author.lastName}`}
                      </Typography>
                      <Typography
                        className={classes.articleDate}
                        variant="body2"
                      >
                        {format(new Date(createdAt), 'dd MMMM yyyy')}
                      </Typography>
                    </div>
                  </div>
                </article>
              );
            })}
          </Container>
        </section>
      )}
    </>
  );
};

export default Blog;
