/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Pet request object for update.
 * @export
 * @interface PetUpdateRequest
 */
export interface PetUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof PetUpdateRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PetUpdateRequest
   */
  type?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof PetUpdateRequest
   */
  guardians?: Array<number>;
}

export function PetUpdateRequestFromJSON(json: any): PetUpdateRequest {
  return PetUpdateRequestFromJSONTyped(json, false);
}

export function PetUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PetUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    type: !exists(json, 'type') ? undefined : json['type'],
    guardians: !exists(json, 'guardians') ? undefined : json['guardians'],
  };
}

export function PetUpdateRequestToJSON(value?: PetUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: value.type,
    guardians: value.guardians,
  };
}
