/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UserContactTypes,
  UserContactTypesFromJSON,
  UserContactTypesFromJSONTyped,
  UserContactTypesToJSON,
} from './';

/**
 * Profile request.
 * @export
 * @interface ProfileRequest
 */
export interface ProfileRequest {
  /**
   *
   * @type {string}
   * @memberof ProfileRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileRequest
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileRequest
   */
  currentPassword?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileRequest
   */
  newPassword?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileRequest
   */
  newPasswordConfirmation?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileRequest
   */
  mailing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileRequest
   */
  unlimitedUpdates?: boolean;
  /**
   *
   * @type {Array<UserContactTypes>}
   * @memberof ProfileRequest
   */
  contactTypes?: Array<UserContactTypes>;
}

export function ProfileRequestFromJSON(json: any): ProfileRequest {
  return ProfileRequestFromJSONTyped(json, false);
}

export function ProfileRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProfileRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    currentPassword: !exists(json, 'current_password')
      ? undefined
      : json['current_password'],
    newPassword: !exists(json, 'new_password')
      ? undefined
      : json['new_password'],
    newPasswordConfirmation: !exists(json, 'new_password_confirmation')
      ? undefined
      : json['new_password_confirmation'],
    mailing: !exists(json, 'mailing') ? undefined : json['mailing'],
    unlimitedUpdates: !exists(json, 'unlimited_updates')
      ? undefined
      : json['unlimited_updates'],
    contactTypes: !exists(json, 'contact_types')
      ? undefined
      : (json['contact_types'] as Array<any>).map(UserContactTypesFromJSON),
  };
}

export function ProfileRequestToJSON(value?: ProfileRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    first_name: value.firstName,
    last_name: value.lastName,
    email: value.email,
    phone: value.phone,
    current_password: value.currentPassword,
    new_password: value.newPassword,
    new_password_confirmation: value.newPasswordConfirmation,
    mailing: value.mailing,
    unlimited_updates: value.unlimitedUpdates,
    contact_types:
      value.contactTypes === undefined
        ? undefined
        : (value.contactTypes as Array<any>).map(UserContactTypesToJSON),
  };
}
