/* eslint-disable no-param-reassign */
import {
  createAction,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { HistoryObject, UserToken } from '@lib/api';
import Toast from '@services/toast';
import {
  deleteUser,
  facebookAuth,
  getUser,
  getUserHistory,
  googleAuth,
  resetPassword,
  resettingPassword,
  signIn,
  signUp,
  updatePaymentDetails,
  updateUser,
} from '@store/auth/thunks';
import { FetchStatus } from '@store/documents';
import { updateActor } from '@store/documents/thunks';

export interface AuthState extends Partial<UserToken> {
  status: FetchStatus;
  error: string | undefined;
  isSignInWithRedirect: 'google' | 'facebook' | undefined;
  isUserEmail: boolean | undefined;
  resettingEmailSent: string | undefined;
  passwordResetSuccess: string | undefined;
  emailToReset: string;
  justSignedIn: boolean;
  history: HistoryObject[] | undefined;
  singleWillPrice: null | number;
  jointWillPrice: null | number;
  oneLpaPrice: null | number;
  bothLpasPrice: null | number;
}

const initialState: AuthState = {
  status: 'draft',
  token: undefined,
  user: undefined,
  error: undefined,
  isSignInWithRedirect: undefined,
  isUserEmail: undefined,
  resettingEmailSent: undefined,
  passwordResetSuccess: undefined,
  history: undefined,
  emailToReset: '',
  justSignedIn: false,
  singleWillPrice: null,
  jointWillPrice: null,
  oneLpaPrice: null,
  bothLpasPrice: null,
};

// for use in different reducers
export const logOut = createAction('auth/logout');

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    confirmIsUserEmail: (state) => {
      state.isUserEmail = true;
    },
    clearEmailSent: (state) => {
      state.resettingEmailSent = undefined;
    },
    setSignInWithRedirect: (
      state,
      { payload }: PayloadAction<AuthState['isSignInWithRedirect']>,
    ) => {
      state.isSignInWithRedirect = payload;
    },
    setJustSignedIn: (
      state,
      { payload }: PayloadAction<AuthState['justSignedIn']>,
    ) => {
      state.justSignedIn = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(logOut, () => {
      return initialState;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload.data;
    });
    builder.addCase(updateUser.fulfilled, (state, { meta, payload }) => {
      state.user = payload.data;

      meta.arg.fulfilledCallback?.();
    });
    builder.addCase(updateActor.fulfilled, (state, { meta, payload }) => {
      if (meta.arg.actor === state.user?.id && payload.data.will?.owner)
        state.user = {
          ...state.user,
          ...payload.data.will.owner,
          email: payload.data.will.owner.email || state.user.email,
        };
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.resettingEmailSent = undefined;
      state.emailToReset = action.meta.arg.email;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resettingEmailSent = action.payload.data.message;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.resettingEmailSent = undefined;
    });

    builder.addCase(resettingPassword.pending, (state) => {
      state.passwordResetSuccess = undefined;
    });
    builder.addCase(resettingPassword.fulfilled, (state, action) => {
      state.passwordResetSuccess = action.payload.data.message;
    });
    builder.addCase(getUserHistory.fulfilled, (state, action) => {
      state.history = action.payload.data;
    });
    builder.addCase(resettingPassword.rejected, (state) => {
      state.passwordResetSuccess = undefined;
    });
    builder.addCase(deleteUser.fulfilled, () => initialState);

    builder.addCase(
      updatePaymentDetails.fulfilled,
      (state, { meta, payload }) => {
        if (state.user) {
          state.user.payment = payload.data;
        }

        state.error = undefined;

        meta.arg.fulfilledCallback?.(payload.data);
      },
    );

    builder.addMatcher(
      isPending(
        signUp,
        updateUser,
        signIn,
        deleteUser,
        googleAuth,
        facebookAuth,
        getUser,
        resetPassword,
        updatePaymentDetails,
        resettingPassword,
        getUserHistory,
      ),
      (state) => {
        state.status = 'pending';
        state.error = undefined;
      },
    );

    builder.addMatcher(
      isFulfilled(signUp, signIn, googleAuth, facebookAuth),
      (state, action) => {
        state.token = action.payload.data.token;
        state.user = action.payload.data.user;
        state.isSignInWithRedirect = undefined;
        state.isUserEmail = !!action.payload.data.user.email;
      },
    );

    builder.addMatcher(
      isFulfilled(signIn, googleAuth, facebookAuth),
      (state) => {
        state.justSignedIn = true;
      },
    );

    builder.addMatcher(
      isFulfilled(
        signUp,
        updateUser,
        signIn,
        deleteUser,
        googleAuth,
        facebookAuth,
        getUser,
        resetPassword,
        updatePaymentDetails,
        resettingPassword,
        getUserHistory,
      ),
      (state) => {
        state.status = 'fulfilled';
      },
    );

    builder.addMatcher(
      isRejected(
        signUp,
        signIn,
        googleAuth,
        facebookAuth,
        getUser,
        resetPassword,
        resettingPassword,
        deleteUser,
        updateUser,
        updatePaymentDetails,
        getUserHistory,
      ),
      (state, action) => {
        const { message } = action.error;

        state.error = message;
        state.status = 'rejected';
        state.isSignInWithRedirect = undefined;

        if (message) Toast.error(message);
      },
    );
  },
});

export const {
  confirmIsUserEmail,
  clearEmailSent,
  setSignInWithRedirect,
  setJustSignedIn,
} = authSlice.actions;

const authReducer = authSlice.reducer;

const persistConfig: PersistConfig<AuthState> = {
  key: 'auth',
  storage,
  blacklist: [
    'error',
    'status',
    'resettingEmailSent',
    'passwordResetSuccess',
    'justSignedIn',
  ],
};

export default persistReducer(persistConfig, authReducer);
