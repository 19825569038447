import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  figures: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
    top: 0,
    left: 0,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  image: {
    position: 'absolute',
    objectFit: 'contain',
    '-webkit-user-drag': 'none',
  },
  circle: {
    position: 'absolute',
    borderRadius: '50%',
    '-webkit-user-drag': 'none',
  },
  blue: { backgroundColor: theme.palette.primary.dark },
  blueLight: { backgroundColor: theme.palette.primary.light },
  blueMain: { backgroundColor: theme.palette.primary.main },
  blueGradient: {
    background: 'linear-gradient(85.13deg, #38B6FF 10.5%, #81D9FF 73.94%)',
  },
  pink: { backgroundColor: theme.palette.secondary.light },
  pinkDark: { backgroundColor: theme.palette.secondary.dark },
  pinkMain: { backgroundColor: theme.palette.secondary.main },
  pinkGradient: {
    background: 'linear-gradient(59.68deg, #FB9C9C 8.16%, #FBCBCB 92.97%)',
  },
  yellow: { color: '#FFCF52' },
  yellowGradient: {
    background: 'linear-gradient(90.75deg, #EEC24F 10.34%, #FFDA79 90.48%)',
  },
  purple: { color: '#B362FF' },
  purpleGradient: {
    background: 'linear-gradient(59.68deg, #9B60E7 8.16%, #C98FFF 92.97%)',
  },
}));

export default useStyles;
