/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Subscription object.
 * @export
 * @interface SubscriptionProfileObject
 */
export interface SubscriptionProfileObject {
  /**
   *
   * @type {number}
   * @memberof SubscriptionProfileObject
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof SubscriptionProfileObject
   */
  nextPayment?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionProfileObject
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionProfileObject
   */
  activeTo?: string;
}

export function SubscriptionProfileObjectFromJSON(
  json: any,
): SubscriptionProfileObject {
  return SubscriptionProfileObjectFromJSONTyped(json, false);
}

export function SubscriptionProfileObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubscriptionProfileObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    price: json['price'],
    nextPayment: !exists(json, 'next_payment')
      ? undefined
      : json['next_payment'],
    status: json['status'],
    activeTo: !exists(json, 'active_to') ? undefined : json['active_to'],
  };
}

export function SubscriptionProfileObjectToJSON(
  value?: SubscriptionProfileObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    price: value.price,
    next_payment: value.nextPayment,
    status: value.status,
    active_to: value.activeTo,
  };
}
