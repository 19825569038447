/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  EstateRequestObject,
  EstateRequestObjectFromJSON,
  EstateRequestObjectFromJSONTyped,
  EstateRequestObjectToJSON,
} from './';

/**
 * Reserve beneficiary object.
 * @export
 * @interface ReserveBeneficiaryObject
 */
export interface ReserveBeneficiaryObject {
  /**
   *
   * @type {number}
   * @memberof ReserveBeneficiaryObject
   */
  owner: number;
  /**
   *
   * @type {boolean}
   * @memberof ReserveBeneficiaryObject
   */
  children?: boolean;
  /**
   *
   * @type {Array<EstateRequestObject>}
   * @memberof ReserveBeneficiaryObject
   */
  estate?: Array<EstateRequestObject>;
}

export function ReserveBeneficiaryObjectFromJSON(
  json: any,
): ReserveBeneficiaryObject {
  return ReserveBeneficiaryObjectFromJSONTyped(json, false);
}

export function ReserveBeneficiaryObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReserveBeneficiaryObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    owner: json['owner'],
    children: !exists(json, 'children') ? undefined : json['children'],
    estate: !exists(json, 'estate')
      ? undefined
      : (json['estate'] as Array<any>).map(EstateRequestObjectFromJSON),
  };
}

export function ReserveBeneficiaryObjectToJSON(
  value?: ReserveBeneficiaryObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    owner: value.owner,
    children: value.children,
    estate:
      value.estate === undefined
        ? undefined
        : (value.estate as Array<any>).map(EstateRequestObjectToJSON),
  };
}
