// eslint-disable-next-line import/no-extraneous-dependencies
import { FirebaseError } from '@firebase/util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { Axios } from '@config/axios/axiosConfiguration';
import {
  MessageResponse,
  ProfileResponse,
  SetNewPasswordData,
  UpdateUserRequest,
  UserDataForLogin,
  UserDataForSignUp,
  UserResponse,
  PaymentResponse,
  UserSendResetPasswordLinkData,
  HistoryResponse,
  PaymentObject,
  APIError,
  SendDeleteEmailRequest,
} from '@lib/api';
import { AuthAPI, UsersAPI } from '@services/api';

import { WithCallback } from '..';

interface ValidationErrors {
  errorMessage: string;
}

export const signUp = createAsyncThunk(
  'auth/signUpUser',
  async (data: UserDataForSignUp): Promise<UserResponse> => {
    return AuthAPI.userSignUp({
      userDataForSignUp: {
        email: data.email,
        name: data.name,
        password: data.password,
        mailing: data.mailing,
      },
    });
  },
);

export const signIn = createAsyncThunk(
  'auth/signInUser',
  async (data: UserDataForLogin): Promise<UserResponse> => {
    return AuthAPI.userLogin({
      userDataForLogin: {
        email: data.email,
        password: data.password,
      },
    });
  },
);

export const googleAuth = createAsyncThunk<
  UserResponse,
  { token: string; displayName: string },
  { rejectValue: ValidationErrors }
>('auth/signInGoogle', async ({ token, displayName }) => {
  try {
    return AuthAPI.googleLogin({
      userAuthenticateWithGoogle: {
        googleServerAuthCode: token,
        fullName: displayName,
      },
    });
  } catch (err) {
    if (err instanceof FirebaseError) {
      throw new Error('Login failed! Please contact the support.');
    }

    throw new Error(
      (err instanceof Error && err.message) || 'Something went wrong!',
    );
  }
});

export const facebookAuth = createAsyncThunk<
  UserResponse,
  { token: string },
  { rejectValue: ValidationErrors }
>('auth/signInFacebook', async ({ token }) => {
  try {
    return AuthAPI.facebookLogin({
      userAuthenticateWithFacebook: {
        facebookServerAuthCode: token,
      },
    });
  } catch (err) {
    if (err instanceof FirebaseError) {
      throw new Error('Login failed! Please contact the support.');
    }

    throw new Error(
      (err instanceof Error && err.message) || 'Something went wrong!',
    );
  }
});

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (): Promise<ProfileResponse> => {
    return UsersAPI.getUser();
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data: UserSendResetPasswordLinkData): Promise<MessageResponse> => {
    return AuthAPI.requestResetPasswordLink({
      userSendResetPasswordLinkData: {
        email: data.email,
      },
    });
  },
);

export const resettingPassword = createAsyncThunk(
  'auth/resettingPassword',
  async (data: SetNewPasswordData): Promise<MessageResponse> => {
    return AuthAPI.resetPassword({
      setNewPasswordData: {
        password: data.password,
        token: data.token,
      },
    });
  },
);

export const deleteUser = createAsyncThunk(
  'profile/deleteUser',
  async (data: SendDeleteEmailRequest): Promise<void> => {
    return UsersAPI.sendDeleteEmail(data).then(() => {
      AuthAPI.userLogout();
    });
  },
);

export const updateUser = createAsyncThunk(
  'profile/updateUser',
  async (data: WithCallback<UpdateUserRequest>): Promise<ProfileResponse> => {
    return UsersAPI.updateUser(data);
  },
);

interface UpdatePaymentRequest {
  data: string;
  name: string;
}

interface StripeResponse {
  id: string;
}

export const updatePaymentDetails = createAsyncThunk(
  'profile/updatePaymentDetails',
  async (
    info: WithCallback<UpdatePaymentRequest, PaymentObject>,
  ): Promise<PaymentResponse> => {
    let response: AxiosResponse<StripeResponse>;

    try {
      response = await Axios.post(
        'https://api.stripe.com/v1/payment_methods',
        info.data,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`,
            accept: '*/*',
          },
        },
      );
    } catch (err) {
      const error = err as AxiosError<{ error: { message: string } }>;

      throw new APIError(
        error.response?.data.error.message || 'Something went wrong.',
        error.response?.status.toString() || '',
      );
    }

    return UsersAPI.updateUserPayment({
      paymentRequest: {
        name: info.name,
        paymentMethodId: response.data.id,
      },
    });
  },
);

export const getUserHistory = createAsyncThunk(
  'user/getHistory',
  async (): Promise<HistoryResponse> => {
    return UsersAPI.getUserHistory();
  },
);
