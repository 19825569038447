import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  PersistConfig,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import middleware from './middlewares';
import rootReducer from './rootReducer';
import { setStore } from './wrapper';

const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: 'root',
  storage,
  blacklist: ['auth', 'documents', 'landings', 'error'], // include reducers which have own persistConfig
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleware),
});

const persistor = persistStore(store, {
  manualPersist: true,
});

export type Store = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type WithCallback<T, P = void, E = void> = T & {
  fulfilledCallback?: (payload: P) => void;
  rejectedCallback?: (error: E) => void;
};

setStore(store);

export { store, persistor };
