/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  GiftTypes,
  GiftTypesFromJSON,
  GiftTypesFromJSONTyped,
  GiftTypesToJSON,
} from './';

/**
 * Gift object.
 * @export
 * @interface GiftObject
 */
export interface GiftObject {
  /**
   *
   * @type {number}
   * @memberof GiftObject
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof GiftObject
   */
  actorId?: number;
  /**
   *
   * @type {number}
   * @memberof GiftObject
   */
  charityId?: number;
  /**
   *
   * @type {string}
   * @memberof GiftObject
   */
  successorName: string;
  /**
   *
   * @type {GiftTypes}
   * @memberof GiftObject
   */
  type: GiftTypes;
  /**
   *
   * @type {string}
   * @memberof GiftObject
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof GiftObject
   */
  message: string | null;
}

export function GiftObjectFromJSON(json: any): GiftObject {
  return GiftObjectFromJSONTyped(json, false);
}

export function GiftObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GiftObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    actorId: !exists(json, 'actor_id') ? undefined : json['actor_id'],
    charityId: !exists(json, 'charity_id') ? undefined : json['charity_id'],
    successorName: json['successor_name'],
    type: GiftTypesFromJSON(json['type']),
    description: json['description'],
    message: json['message'],
  };
}

export function GiftObjectToJSON(value?: GiftObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    actor_id: value.actorId,
    charity_id: value.charityId,
    successor_name: value.successorName,
    type: GiftTypesToJSON(value.type),
    description: value.description,
    message: value.message,
  };
}
