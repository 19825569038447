/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  PricesResponse,
  PricesResponseFromJSON,
  PricesResponseToJSON,
} from '../models';

/**
 *
 */
export class PricesApi extends runtime.BaseAPI {
  /**
   * Get prices.
   */
  async getPricesRaw(): Promise<runtime.ApiResponse<PricesResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/prices`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PricesResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get prices.
   */
  async getPrices(): Promise<PricesResponse> {
    const response = await this.getPricesRaw();
    return await response.value();
  }
}
