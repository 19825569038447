/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SuccessMessage,
  SuccessMessageFromJSON,
  SuccessMessageFromJSONTyped,
  SuccessMessageToJSON,
} from './';

/**
 * Response with message.
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
  /**
   *
   * @type {SuccessMessage}
   * @memberof MessageResponse
   */
  data: SuccessMessage;
}

export function MessageResponseFromJSON(json: any): MessageResponse {
  return MessageResponseFromJSONTyped(json, false);
}

export function MessageResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MessageResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: SuccessMessageFromJSON(json['data']),
  };
}

export function MessageResponseToJSON(value?: MessageResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: SuccessMessageToJSON(value.data),
  };
}
