import classNames from 'classnames';

import useStyles from '@components/common/loader/styles';

interface Props {
  size: 'md' | 'sm';
  marginAuto?: true;
  positionFixedCenter?: true;
}

const Loader: React.FC<Props> = ({ size, marginAuto, positionFixedCenter }) => {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 51"
      className={classNames(classes.loader, classes[size], {
        [classes.marginAuto]: marginAuto,
        [classes.positionFixedCenter]: positionFixedCenter,
      })}
    >
      <circle
        cx="24"
        cy="7"
        r="7"
        className={classNames(classes.circle, classes.big)}
      />
      <circle
        cx="24"
        cy="44"
        r="4"
        className={classNames(classes.circle, classes.small)}
      />
      <circle
        cx="7"
        cy="33"
        r="7"
        className={classNames(classes.circle, classes.big)}
      />
      <circle
        cx="41"
        cy="33"
        r="7"
        className={classNames(classes.circle, classes.big)}
      />
      <circle
        cx="7"
        cy="16"
        r="4"
        className={classNames(classes.circle, classes.small)}
      />
      <circle
        cx="41"
        cy="16"
        r="4"
        className={classNames(classes.circle, classes.small)}
      />
    </svg>
  );
};

export default Loader;
