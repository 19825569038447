/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Marital statuses object.
 * @export
 * @enum {string}
 */
export enum MaritalStatuses {
  Married = 'Married',
  Single = 'Single',
  LivingWithAPartnerButNotMarried = 'Living with a partner but not married',
  CivilPartnership = 'Civil partnership',
  Engaged = 'Engaged',
  MarriedButSeparated = 'Married but separated',
  Divorced = 'Divorced',
  Widowed = 'Widowed',
}

export function MaritalStatusesFromJSON(json: any): MaritalStatuses {
  return MaritalStatusesFromJSONTyped(json, false);
}

export function MaritalStatusesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MaritalStatuses {
  return json as MaritalStatuses;
}

export function MaritalStatusesToJSON(value?: MaritalStatuses | null): any {
  return value as any;
}
