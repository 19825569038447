/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  GiftTypes,
  GiftTypesFromJSON,
  GiftTypesFromJSONTyped,
  GiftTypesToJSON,
} from './';

/**
 * gift request object for create.
 * @export
 * @interface GiftCreateRequest
 */
export interface GiftCreateRequest {
  /**
   *
   * @type {number}
   * @memberof GiftCreateRequest
   */
  documentId: number;
  /**
   *
   * @type {number}
   * @memberof GiftCreateRequest
   */
  actorId?: number;
  /**
   *
   * @type {number}
   * @memberof GiftCreateRequest
   */
  charityId?: number;
  /**
   *
   * @type {GiftTypes}
   * @memberof GiftCreateRequest
   */
  type: GiftTypes;
  /**
   *
   * @type {string}
   * @memberof GiftCreateRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof GiftCreateRequest
   */
  message?: string;
}

export function GiftCreateRequestFromJSON(json: any): GiftCreateRequest {
  return GiftCreateRequestFromJSONTyped(json, false);
}

export function GiftCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GiftCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    documentId: json['document_id'],
    actorId: !exists(json, 'actor_id') ? undefined : json['actor_id'],
    charityId: !exists(json, 'charity_id') ? undefined : json['charity_id'],
    type: GiftTypesFromJSON(json['type']),
    description: json['description'],
    message: !exists(json, 'message') ? undefined : json['message'],
  };
}

export function GiftCreateRequestToJSON(value?: GiftCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    document_id: value.documentId,
    actor_id: value.actorId,
    charity_id: value.charityId,
    type: GiftTypesToJSON(value.type),
    description: value.description,
    message: value.message,
  };
}
