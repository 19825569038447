/* eslint-disable no-param-reassign */
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Review } from '@components/common/slide-with-testimonial';
import {
  ArticleObject,
  ArticlesCollectionObject,
  GuideObject,
  GuidesCollectionObject,
} from '@lib/api';

import {
  getReviews,
  getRating,
  getAllGuides,
  getOneGuide,
  getArticles,
  getOneArticle,
} from './thunks';

export interface Rating {
  min: number;
  max: number;
  rating: number;
}

export interface LandingsState {
  isLoading: boolean;
  items: Review[];
  overalRating?: Rating;
  articles?: ArticlesCollectionObject;
  article?: ArticleObject;
  guides?: GuidesCollectionObject[];
  guide?: GuideObject;
  lastFetchedReviews?: number;
  lastFetchedRating?: number;
  error?: string;
}

const initialState: LandingsState = {
  isLoading: false,
  items: [],
  error: undefined,
};

const landingsSlice = createSlice({
  name: 'landings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRating.fulfilled, (state, action) => {
      state.overalRating = {
        min: action.payload.rating.min,
        max: action.payload.rating.max,
        rating: action.payload.rating.rating,
      };
      state.lastFetchedRating = Date.now();
    });
    builder.addCase(getReviews.fulfilled, (state, action) => {
      const items = action.payload.reviews.map((review) => {
        const testimonial: Review = {
          createdAt: review.service.createdAt,
          rating: review.service.rating,
          title: review.service.title,
          description: review.service.review,
          owner: review.customer.displayName,
        };

        return testimonial;
      });

      state.lastFetchedReviews = Date.now();
      state.items = items || [];
      state.isLoading = false;
    });
    builder.addCase(getAllGuides.fulfilled, (state, action) => {
      state.guides = action.payload.data;
      state.isLoading = false;
      state.error = undefined;
    });
    builder.addCase(getOneGuide.fulfilled, (state, action) => {
      state.guide = action.payload.data;
      state.isLoading = false;
      state.error = undefined;
    });
    builder.addCase(getArticles.fulfilled, (state, action) => {
      state.articles = action.payload.data;
      state.isLoading = false;
      state.error = undefined;
    });
    builder.addCase(getOneArticle.fulfilled, (state, action) => {
      state.article = action.payload.data;
      state.isLoading = false;
      state.error = undefined;
    });
    builder.addMatcher(
      isPending(
        getReviews,
        getAllGuides,
        getOneGuide,
        getArticles,
        getOneArticle,
      ),
      (state) => {
        state.isLoading = true;
        state.error = undefined;
      },
    );
    builder.addMatcher(
      isRejected(
        getReviews,
        getAllGuides,
        getOneGuide,
        getArticles,
        getOneArticle,
      ),
      (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      },
    );
  },
});

const landingsReducer = landingsSlice.reducer;

const persistConfig: PersistConfig<LandingsState> = {
  key: 'landings',
  storage,
  blacklist: ['isLoading', 'error'],
};

export default persistReducer(persistConfig, landingsReducer);
