/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Reserve estate object.
 * @export
 * @interface ReserveEstateObject
 */
export interface ReserveEstateObject {
  /**
   *
   * @type {number}
   * @memberof ReserveEstateObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ReserveEstateObject
   */
  beneficiary: string;
  /**
   *
   * @type {number}
   * @memberof ReserveEstateObject
   */
  actorId?: number;
  /**
   *
   * @type {number}
   * @memberof ReserveEstateObject
   */
  charityId?: number;
  /**
   *
   * @type {number}
   * @memberof ReserveEstateObject
   */
  percent: number;
}

export function ReserveEstateObjectFromJSON(json: any): ReserveEstateObject {
  return ReserveEstateObjectFromJSONTyped(json, false);
}

export function ReserveEstateObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReserveEstateObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    beneficiary: json['beneficiary'],
    actorId: !exists(json, 'actor_id') ? undefined : json['actor_id'],
    charityId: !exists(json, 'charity_id') ? undefined : json['charity_id'],
    percent: json['percent'],
  };
}

export function ReserveEstateObjectToJSON(
  value?: ReserveEstateObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    beneficiary: value.beneficiary,
    actor_id: value.actorId,
    charity_id: value.charityId,
    percent: value.percent,
  };
}
