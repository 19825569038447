import {
  Container,
  Link as MaterialUILink,
  Typography,
} from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import classNames from 'classnames';
import Link from 'next/link';

import useStyles from '@components/common/footer/styles';
import Logo from '@public/svg/simp-will.svg';
import idNormalize from '@utils/id-normalize';

import Figures, { Circle } from '../figures';

interface Links {
  href: string;
  id?: string;
  mailto?: boolean;
  rel?: 'noopener noreferrer';
  target?: '_blank';
  tel?: boolean;
  text: string | JSX.Element;
}

interface TextBlockData {
  links: Links[];
  title: string;
}

interface FooterProps {
  withoutBigCircle?: boolean;
}

const textBlockData: TextBlockData[] = [
  {
    title: 'Our services',
    links: [
      {
        text: 'Write your will online',
        href: '/create-will',
      },
      // {
      //   text: 'Plan your funeral',
      //   href: '/arrange-funeral',
      // },
      {
        text: 'Probate & Tax Administration',
        href: '/probate',
      },
    ],
  },
  {
    title: 'Partners',
    links: [
      // {
      //   text: 'Co Operative Funeral Care ',
      //   href: '/arrange-funeral',
      // },
      {
        text: (
          <>
            Countrywide Tax &amp; Trust
            <br />
            Corporation
          </>
        ),
        href: '/probate',
        id: 'countrywide-tax-trust-corporation',
      },
    ],
  },
  {
    title: 'Policies',
    links: [
      {
        text: 'About us',
        href: '/policies/about',
      },
      {
        text: 'Terms and Conditions',
        href: '/policies/terms',
      },
      {
        text: 'Privacy',
        href: '/policies/privacy',
      },
      {
        text: 'Cookies',
        href: '/policies/cookies',
      },
    ],
  },
];

const connectData = [
  {
    icon: <i className="icon-facebook" />,
    href: 'https://www.facebook.com/simpwil1',
    id: 'facebook-link-footer',
  },
  {
    icon: <TwitterIcon />,
    href: 'https://twitter.com/Simpwil1',
    id: 'twitter-link-footer',
  },
  {
    icon: <InstagramIcon />,
    href: 'https://www.instagram.com/simpwil1/',
    id: 'instagram-link-footer',
  },
  {
    icon: <LinkedInIcon />,
    href: 'https://www.linkedin.com/company/simpwill',
    id: 'linked-in-link-footer',
  },
];

const contactUsData = [
  {
    title: 'Contact us',
    links: [
      {
        href: 'mailto:hello@simpwill.co.uk',
        text: 'hello@simpwill.co.uk',
        icon: 'email',
        id: 'mailto-link-footer',
      },
    ],
  },
];

const Footer: React.FC<FooterProps> = (props) => {
  const { withoutBigCircle: withoutFooterBigCircle } = props;

  const classes = useStyles();
  const year = new Date().getFullYear();

  const textBlocks = textBlockData.map((block) => (
    <div className={classes.textBlock} key={block.title}>
      <Typography
        variant="body1"
        className={classNames(classes.textCenter, classes.textBlockTitle)}
      >
        {block.title}
      </Typography>
      {block.links.map((link) => (
        <Link href={link.href} key={link.text as string} passHref>
          <MaterialUILink
            id={link.id || `${idNormalize(link.text as string)}-link-footer`}
            href={link.href}
            key={link.text as string}
            className={classes.textBlockLink}
            target={link.target}
            rel={link.rel}
          >
            <Typography variant="body2">{link.text}</Typography>
          </MaterialUILink>
        </Link>
      ))}
    </div>
  ));

  const connectIcons = connectData.map((iconData) => (
    <a
      id={`${iconData.id}`}
      href={iconData.href}
      target="_blank"
      rel="noopener noreferrer"
      key={iconData.href}
    >
      {iconData.icon}
    </a>
  ));

  const footerBaubles: Circle[] = [
    {
      classWrapper: classNames(classes.firstCircle, {
        [classes.firstCircleDisabled]: withoutFooterBigCircle,
      }),
      background: 'blue',
    },
    {
      classWrapper: classes.secondCircle,
      background: 'pinkMain',
    },
  ];

  const contactLinks = contactUsData.map((contact) => (
    <div className={classes.textBlock} key={contact.title}>
      <Typography
        variant="body1"
        className={classNames(classes.textCenter, classes.textBlockTitle)}
      >
        {contact.title}
      </Typography>
      {contact.links.map((link) => (
        <MaterialUILink id={`${link.id}`} href={link.href} key={link.text}>
          <Typography variant="body2" className={classes.contactLink}>
            <i className={`icon-${link.icon}`} />
            {link.text}
          </Typography>
        </MaterialUILink>
      ))}
      <div className={classes.connect}>
        <div className={classes.iconsWrapper}>{connectIcons}</div>
      </div>
    </div>
  ));

  return (
    <footer className={classes.footer}>
      <Container maxWidth={false}>
        <div className={classes.mainContent}>
          <Figures circles={footerBaubles} classWrapper={classes.figure} />
          <div className={classes.logoWrapper}>
            <Logo />
          </div>
          <div className={classes.textSectionsWrapper}>
            {textBlocks}
            {contactLinks}
          </div>
        </div>
      </Container>
      <div className={classes.copyright}>
        <Container maxWidth={false}>
          <Typography variant="caption" className={classes.textCenter}>
            © {year} Simpwill LTD. All rights reserved.
          </Typography>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
