/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
  MetatagResponse,
  MetatagResponseFromJSON,
  MetatagResponseToJSON,
} from '../models';

export interface GetMetaTagsRequest {
  slug: string;
}

/**
 *
 */
export class MetatagsApi extends runtime.BaseAPI {
  /**
   * Get all meta tags.
   */
  async getMetaTagsRaw(
    requestParameters: GetMetaTagsRequest,
  ): Promise<runtime.ApiResponse<MetatagResponse>> {
    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        'slug',
        'Required parameter requestParameters.slug was null or undefined when calling getMetaTags.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.slug !== undefined) {
      queryParameters['slug'] = requestParameters.slug;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/meta`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MetatagResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get all meta tags.
   */
  async getMetaTags(
    requestParameters: GetMetaTagsRequest,
  ): Promise<MetatagResponse> {
    const response = await this.getMetaTagsRaw(requestParameters);
    return await response.value();
  }
}
