import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    position: 'relative',
  },
  downIcon: {
    position: 'absolute',
    right: 16,
    top: '50%',
    transform: 'translate(50%, -50%)',
    fontSize: 8,
    color: theme.palette.info.main,
  },
  eye: {
    position: 'absolute',
    top: '50%',
    right: 16,
    transform: 'translateY(-50%)',
    display: 'flex',
    fontSize: 24,
    color: theme.palette.secondary.dark,
  },
  crossLine: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%) rotate(-45deg)',
    transition: 'all 0.2s',
    width: 26,
    height: 3,
    borderRadius: 2,
  },
  crossedLineActive: {
    backgroundColor: theme.palette.secondary.dark,
  },
  field: {
    width: '100%',
    borderRadius: 16,
    color: theme.palette.info.dark,
    overflow: 'hidden',
    position: 'relative',

    '& input::placeholder': {
      fontWeight: 300,
      lineHeight: 'normal',
    },

    '& .MuiMenu-list': {
      backgroundColor: 'red,',
    },

    '&$searchIcon': {
      '&:after': {
        content: '"\\e914"',
        fontFamily: 'SimpWillIcons',
        position: 'absolute',
        right: 0,
        top: 0,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.background.default,
        height: '100%',
        width: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 16,
      },
    },
  },
  withoutTag: {
    fontWeight: 700,
  },
  default: {
    color: theme.palette.text.disabled,
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  suffix: {
    position: 'absolute',
    top: 35,
    right: 10,
  },
  searchIcon: {
    /*  nesting */
  },
  [theme.breakpoints.up('sm')]: {
    field: {
      '&$searchIcon': {
        '&:after': {
          width: 104,
        },
      },
    },
  },
}));

export default useStyles;
