import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  alert: {
    position: 'static',
    transform: 'none',
    marginTop: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    padding: '10px 16px',
    backgroundColor: theme.palette.background.default,
  },
  innerContent: {
    padding: 0,
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',

    '& > [class*="message"]': {
      display: 'flex',
      alignItems: 'center',
      padding: 0,

      '& svg': {
        marginRight: 12,
      },
    },
  },
  error: {
    borderColor: theme.palette.error.main,
    '& svg': {
      color: theme.palette.error.main,
    },
  },
  warning: {
    borderColor: '#ff9800',
    '& svg': {
      color: '#ff9800',
    },
  },
  info: {
    borderColor: theme.palette.primary.dark,
    '& svg': {
      color: theme.palette.primary.dark,
    },
  },
  success: {
    borderColor: theme.palette.success.main,
    '& svg': {
      color: theme.palette.success.main,
    },
  },
  closeIcon: { color: theme.palette.text.primary },
}));

export default useStyles;
