/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Payment request
 * @export
 * @interface PaymentRequest
 */
export interface PaymentRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequest
   */
  paymentMethodId: string;
}

export function PaymentRequestFromJSON(json: any): PaymentRequest {
  return PaymentRequestFromJSONTyped(json, false);
}

export function PaymentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    paymentMethodId: json['payment_method_id'],
  };
}

export function PaymentRequestToJSON(value?: PaymentRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    payment_method_id: value.paymentMethodId,
  };
}
