import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 8,
  },
  form: {
    marginTop: 24,
  },
  sendButton: {
    marginTop: 40,
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    sendButton: {
      width: 'initial',
    },
  },
}));

export default useStyles;
