/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AuthorObject,
  AuthorObjectFromJSON,
  AuthorObjectFromJSONTyped,
  AuthorObjectToJSON,
  TagObject,
  TagObjectFromJSON,
  TagObjectFromJSONTyped,
  TagObjectToJSON,
} from './';

/**
 * Article object.
 * @export
 * @interface ArticleObject
 */
export interface ArticleObject {
  /**
   *
   * @type {number}
   * @memberof ArticleObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ArticleObject
   */
  image: string;
  /**
   *
   * @type {string}
   * @memberof ArticleObject
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ArticleObject
   */
  preview: string;
  /**
   *
   * @type {string}
   * @memberof ArticleObject
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof ArticleObject
   */
  metaTitle: string | null;
  /**
   *
   * @type {string}
   * @memberof ArticleObject
   */
  metaDescription: string | null;
  /**
   *
   * @type {string}
   * @memberof ArticleObject
   */
  metaKeywords: string | null;
  /**
   *
   * @type {string}
   * @memberof ArticleObject
   */
  createdAt: string;
  /**
   *
   * @type {Array<TagObject>}
   * @memberof ArticleObject
   */
  tags: Array<TagObject>;
  /**
   *
   * @type {AuthorObject}
   * @memberof ArticleObject
   */
  author: AuthorObject;
}

export function ArticleObjectFromJSON(json: any): ArticleObject {
  return ArticleObjectFromJSONTyped(json, false);
}

export function ArticleObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ArticleObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    image: json['image'],
    title: json['title'],
    preview: json['preview'],
    body: json['body'],
    metaTitle: json['meta_title'],
    metaDescription: json['meta_description'],
    metaKeywords: json['meta_keywords'],
    createdAt: json['created_at'],
    tags: (json['tags'] as Array<any>).map(TagObjectFromJSON),
    author: AuthorObjectFromJSON(json['author']),
  };
}

export function ArticleObjectToJSON(value?: ArticleObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    image: value.image,
    title: value.title,
    preview: value.preview,
    body: value.body,
    meta_title: value.metaTitle,
    meta_description: value.metaDescription,
    meta_keywords: value.metaKeywords,
    created_at: value.createdAt,
    tags: (value.tags as Array<any>).map(TagObjectToJSON),
    author: AuthorObjectToJSON(value.author),
  };
}
