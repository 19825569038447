const isPrimitive = (obj: unknown) =>
  typeof obj === 'bigint' ||
  typeof obj === 'boolean' ||
  typeof obj === 'number' ||
  typeof obj === 'string' ||
  typeof obj === 'symbol';

const isComplex = (obj: unknown) =>
  !isPrimitive(obj) && typeof obj !== 'undefined' && obj !== null && !!obj;

const isSimple = (obj: unknown) => !isComplex(obj);

const convertSimple = (value: unknown) => {
  if (!isSimple(value)) {
    throw Error('Must be a simple value');
  }

  return value !== undefined ? value : null;
};

const transform =
  (keyTransformer: (_: string) => string) =>
  (object: unknown): unknown => {
    if (isSimple(object)) {
      return convertSimple(object);
    }

    if (Array.isArray(object)) {
      return object.map((obj) => transform(keyTransformer)(obj));
    }

    let result = {};

    if (object && typeof object === 'object') {
      result = Object.assign(
        {},
        ...Object.entries(object).map(([key, value]: [string, unknown]) => {
          let v;
          const k = keyTransformer(key);

          if (typeof value === 'object') {
            v = transform(keyTransformer)(value);
          }

          return { [k]: v || value };
        }),
      );
    }

    return result;
  };

const toSnakeCase = transform((oldKey) => {
  const pattern = /[a-z][A-Z]/g;

  return oldKey.replace(
    pattern,
    (match) => `${match[0]}_${match[1].toLowerCase()}`,
  );
});

const toCamelCase = transform((oldKey) => {
  const pattern = /\w_\w/g;

  return oldKey.replace(
    pattern,
    (match) => `${match[0]}${match[2].toUpperCase()}`,
  );
});

type CaseType = 'snake' | 'camel';

export default function transformObject(
  propCase: CaseType,
  object: unknown,
): unknown {
  if (isSimple(object)) {
    return convertSimple(object);
  }

  switch (propCase) {
    case 'camel':
      return toCamelCase(object);
    case 'snake':
      return toSnakeCase(object);

    default:
      return object;
  }
}
