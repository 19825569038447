/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Document steps object.
 * @export
 * @enum {string}
 */
export enum DocumentSteps {
  ToDo = 'To do',
  InProgress = 'In progress',
  Completed = 'Completed',
  Unavailable = 'Unavailable',
  NotApplicable = 'Not applicable',
}

export function DocumentStepsFromJSON(json: any): DocumentSteps {
  return DocumentStepsFromJSONTyped(json, false);
}

export function DocumentStepsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DocumentSteps {
  return json as DocumentSteps;
}

export function DocumentStepsToJSON(value?: DocumentSteps | null): any {
  return value as any;
}
