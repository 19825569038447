/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ApplierRequest,
  ApplierRequestFromJSON,
  ApplierRequestToJSON,
  ApplierResponse,
  ApplierResponseFromJSON,
  ApplierResponseToJSON,
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
} from '../models';

export interface SaveApplierRequest {
  applierRequest: ApplierRequest;
}

/**
 *
 */
export class QuestionsApi extends runtime.BaseAPI {
  /**
   * Save appliers data.
   */
  async saveApplierRaw(
    requestParameters: SaveApplierRequest,
  ): Promise<runtime.ApiResponse<ApplierResponse>> {
    if (
      requestParameters.applierRequest === null ||
      requestParameters.applierRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'applierRequest',
        'Required parameter requestParameters.applierRequest was null or undefined when calling saveApplier.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/api/questions`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ApplierRequestToJSON(requestParameters.applierRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ApplierResponseFromJSON(jsonValue),
    );
  }

  /**
   * Save appliers data.
   */
  async saveApplier(
    requestParameters: SaveApplierRequest,
  ): Promise<ApplierResponse> {
    const response = await this.saveApplierRaw(requestParameters);
    return await response.value();
  }
}
