/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AuthorObject,
  AuthorObjectFromJSON,
  AuthorObjectFromJSONTyped,
  AuthorObjectToJSON,
  GuidePreviewObject,
  GuidePreviewObjectFromJSON,
  GuidePreviewObjectFromJSONTyped,
  GuidePreviewObjectToJSON,
} from './';

/**
 * Guide object.
 * @export
 * @interface GuideObject
 */
export interface GuideObject {
  /**
   *
   * @type {number}
   * @memberof GuideObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GuideObject
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof GuideObject
   */
  preview: string;
  /**
   *
   * @type {string}
   * @memberof GuideObject
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof GuideObject
   */
  metaTitle: string | null;
  /**
   *
   * @type {string}
   * @memberof GuideObject
   */
  metaDescription: string | null;
  /**
   *
   * @type {string}
   * @memberof GuideObject
   */
  metaKeywords: string | null;
  /**
   *
   * @type {string}
   * @memberof GuideObject
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof GuideObject
   */
  updatedAt: string;
  /**
   *
   * @type {AuthorObject}
   * @memberof GuideObject
   */
  author: AuthorObject;
  /**
   *
   * @type {string}
   * @memberof GuideObject
   */
  category: string;
  /**
   *
   * @type {GuidePreviewObject}
   * @memberof GuideObject
   */
  next: GuidePreviewObject | null;
  /**
   *
   * @type {Array<GuidePreviewObject>}
   * @memberof GuideObject
   */
  related: Array<GuidePreviewObject> | null;
}

export function GuideObjectFromJSON(json: any): GuideObject {
  return GuideObjectFromJSONTyped(json, false);
}

export function GuideObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GuideObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    preview: json['preview'],
    body: json['body'],
    metaTitle: json['meta_title'],
    metaDescription: json['meta_description'],
    metaKeywords: json['meta_keywords'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
    author: AuthorObjectFromJSON(json['author']),
    category: json['category'],
    next: GuidePreviewObjectFromJSON(json['next']),
    related:
      json['related'] === null
        ? null
        : (json['related'] as Array<any>).map(GuidePreviewObjectFromJSON),
  };
}

export function GuideObjectToJSON(value?: GuideObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    preview: value.preview,
    body: value.body,
    meta_title: value.metaTitle,
    meta_description: value.metaDescription,
    meta_keywords: value.metaKeywords,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    author: AuthorObjectToJSON(value.author),
    category: value.category,
    next: GuidePreviewObjectToJSON(value.next),
    related:
      value.related === null
        ? null
        : (value.related as Array<any>).map(GuidePreviewObjectToJSON),
  };
}
