/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ActorContactTypes,
  ActorContactTypesFromJSON,
  ActorContactTypesFromJSONTyped,
  ActorContactTypesToJSON,
  ActorObject,
  ActorObjectFromJSON,
  ActorObjectFromJSONTyped,
  ActorObjectToJSON,
  AttorneysCanAct,
  AttorneysCanActFromJSON,
  AttorneysCanActFromJSONTyped,
  AttorneysCanActToJSON,
  AttorneysDecisions,
  AttorneysDecisionsFromJSON,
  AttorneysDecisionsFromJSONTyped,
  AttorneysDecisionsToJSON,
  DocumentProgress,
  DocumentProgressFromJSON,
  DocumentProgressFromJSONTyped,
  DocumentProgressToJSON,
  DocumentTypes,
  DocumentTypesFromJSON,
  DocumentTypesFromJSONTyped,
  DocumentTypesToJSON,
  DonorAndAppliers,
  DonorAndAppliersFromJSON,
  DonorAndAppliersFromJSONTyped,
  DonorAndAppliersToJSON,
  PreferenceObject,
  PreferenceObjectFromJSON,
  PreferenceObjectFromJSONTyped,
  PreferenceObjectToJSON,
} from './';

/**
 * Lpa object.
 * @export
 * @interface LpaObject
 */
export interface LpaObject {
  /**
   *
   * @type {number}
   * @memberof LpaObject
   */
  id: number;
  /**
   *
   * @type {DocumentTypes}
   * @memberof LpaObject
   */
  type: DocumentTypes;
  /**
   *
   * @type {DocumentProgress}
   * @memberof LpaObject
   */
  progress: DocumentProgress;
  /**
   *
   * @type {ActorObject}
   * @memberof LpaObject
   */
  owner: ActorObject | null;
  /**
   *
   * @type {boolean}
   * @memberof LpaObject
   */
  mentalCapacity: boolean | null;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof LpaObject
   */
  attorneys: Array<ActorObject> | null;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof LpaObject
   */
  replacementAttorneys: Array<ActorObject> | null;
  /**
   *
   * @type {AttorneysDecisions}
   * @memberof LpaObject
   */
  attorneysDecisions: AttorneysDecisions | null;
  /**
   *
   * @type {AttorneysCanAct}
   * @memberof LpaObject
   */
  attorneysCanAct: AttorneysCanAct | null;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof LpaObject
   */
  peopleToNotify: Array<ActorObject> | null;
  /**
   *
   * @type {Array<PreferenceObject>}
   * @memberof LpaObject
   */
  preferences: Array<PreferenceObject> | null;
  /**
   *
   * @type {ActorObject}
   * @memberof LpaObject
   */
  certificateProvider: ActorObject | null;
  /**
   *
   * @type {DonorAndAppliers}
   * @memberof LpaObject
   */
  appliers: DonorAndAppliers | null;
  /**
   *
   * @type {boolean}
   * @memberof LpaObject
   */
  receiverDonor: boolean | null;
  /**
   *
   * @type {ActorObject}
   * @memberof LpaObject
   */
  receiverAttorney: ActorObject | null;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof LpaObject
   */
  adultActors: Array<ActorObject> | null;
  /**
   *
   * @type {boolean}
   * @memberof LpaObject
   */
  reducedFee: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof LpaObject
   */
  lifeSustainingTreatment: boolean | null;
  /**
   *
   * @type {Array<ActorContactTypes>}
   * @memberof LpaObject
   */
  contactTypes: Array<ActorContactTypes> | null;
  /**
   *
   * @type {boolean}
   * @memberof LpaObject
   */
  noReplacementAttorneys: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof LpaObject
   */
  noNotifiers: boolean;
  /**
   *
   * @type {string}
   * @memberof LpaObject
   */
  purchasedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof LpaObject
   */
  receiverPhone: string | null;
  /**
   *
   * @type {string}
   * @memberof LpaObject
   */
  receiverEmail: string | null;
}

export function LpaObjectFromJSON(json: any): LpaObject {
  return LpaObjectFromJSONTyped(json, false);
}

export function LpaObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LpaObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: DocumentTypesFromJSON(json['type']),
    progress: DocumentProgressFromJSON(json['progress']),
    owner: ActorObjectFromJSON(json['owner']),
    mentalCapacity: json['mental_capacity'],
    attorneys:
      json['attorneys'] === null
        ? null
        : (json['attorneys'] as Array<any>).map(ActorObjectFromJSON),
    replacementAttorneys:
      json['replacement_attorneys'] === null
        ? null
        : (json['replacement_attorneys'] as Array<any>).map(
            ActorObjectFromJSON,
          ),
    attorneysDecisions: AttorneysDecisionsFromJSON(json['attorneys_decisions']),
    attorneysCanAct: AttorneysCanActFromJSON(json['attorneys_can_act']),
    peopleToNotify:
      json['people_to_notify'] === null
        ? null
        : (json['people_to_notify'] as Array<any>).map(ActorObjectFromJSON),
    preferences:
      json['preferences'] === null
        ? null
        : (json['preferences'] as Array<any>).map(PreferenceObjectFromJSON),
    certificateProvider: ActorObjectFromJSON(json['certificate_provider']),
    appliers: DonorAndAppliersFromJSON(json['appliers']),
    receiverDonor: json['receiver_donor'],
    receiverAttorney: ActorObjectFromJSON(json['receiver_attorney']),
    adultActors:
      json['adult_actors'] === null
        ? null
        : (json['adult_actors'] as Array<any>).map(ActorObjectFromJSON),
    reducedFee: json['reduced_fee'],
    lifeSustainingTreatment: json['life_sustaining_treatment'],
    contactTypes:
      json['contact_types'] === null
        ? null
        : (json['contact_types'] as Array<any>).map(ActorContactTypesFromJSON),
    noReplacementAttorneys: json['no_replacement_attorneys'],
    noNotifiers: json['no_notifiers'],
    purchasedAt: json['purchased_at'],
    receiverPhone: json['receiver_phone'],
    receiverEmail: json['receiver_email'],
  };
}

export function LpaObjectToJSON(value?: LpaObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: DocumentTypesToJSON(value.type),
    progress: DocumentProgressToJSON(value.progress),
    owner: ActorObjectToJSON(value.owner),
    mental_capacity: value.mentalCapacity,
    attorneys:
      value.attorneys === null
        ? null
        : (value.attorneys as Array<any>).map(ActorObjectToJSON),
    replacement_attorneys:
      value.replacementAttorneys === null
        ? null
        : (value.replacementAttorneys as Array<any>).map(ActorObjectToJSON),
    attorneys_decisions: AttorneysDecisionsToJSON(value.attorneysDecisions),
    attorneys_can_act: AttorneysCanActToJSON(value.attorneysCanAct),
    people_to_notify:
      value.peopleToNotify === null
        ? null
        : (value.peopleToNotify as Array<any>).map(ActorObjectToJSON),
    preferences:
      value.preferences === null
        ? null
        : (value.preferences as Array<any>).map(PreferenceObjectToJSON),
    certificate_provider: ActorObjectToJSON(value.certificateProvider),
    appliers: DonorAndAppliersToJSON(value.appliers),
    receiver_donor: value.receiverDonor,
    receiver_attorney: ActorObjectToJSON(value.receiverAttorney),
    adult_actors:
      value.adultActors === null
        ? null
        : (value.adultActors as Array<any>).map(ActorObjectToJSON),
    reduced_fee: value.reducedFee,
    life_sustaining_treatment: value.lifeSustainingTreatment,
    contact_types:
      value.contactTypes === null
        ? null
        : (value.contactTypes as Array<any>).map(ActorContactTypesToJSON),
    no_replacement_attorneys: value.noReplacementAttorneys,
    no_notifiers: value.noNotifiers,
    purchased_at: value.purchasedAt,
    receiver_phone: value.receiverPhone,
    receiver_email: value.receiverEmail,
  };
}
