/* eslint-disable react/jsx-props-no-spreading */

import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';

import defaultMeta from '@config/constants/meta-info';
import { MetaProps, PageProps } from '@pages/_app';
import { PoliciesProps } from '@pages/policies/[tab]';

type WithMetaTagsProps = MetaProps & PageProps & PoliciesProps;

const withMetaTags =
  (slug: keyof typeof defaultMeta) =>
  <T extends WithMetaTagsProps>(
    WrappedComponent: React.ComponentType<T>,
  ): React.FC<T> => {
    const displayName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const ComponentWithMetaTags = (props: T) => {
      const router = useRouter();

      const { meta, frontUrl } = props;

      const url = frontUrl + (router.asPath === '/index' ? '/' : router.asPath);

      return (
        <>
          <Head>
            <meta name="twitter:url" content={url} />
          </Head>

          <NextSeo
            title={meta?.title || defaultMeta[slug].title}
            description={meta?.description || defaultMeta[slug].description}
            openGraph={{
              title: meta?.title || defaultMeta[slug].title,
              description: meta?.description || defaultMeta[slug].description,
              url,
            }}
            additionalMetaTags={[
              {
                name: 'twitter:title',
                content: meta?.title || defaultMeta[slug].title,
              },
              {
                name: 'twitter:description',
                content: meta?.description || defaultMeta[slug].description,
              },
              {
                name: 'keywords',
                content: meta?.keywords || '',
              },
            ]}
            canonical={url}
          />

          <WrappedComponent {...props} />
        </>
      );
    };

    ComponentWithMetaTags.displayName = `withMetaTags(${displayName})`;

    return ComponentWithMetaTags;
  };

export default withMetaTags;
