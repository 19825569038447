/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CreateIntentRequest,
  CreateIntentRequestFromJSON,
  CreateIntentRequestToJSON,
  CreateIntentResponse,
  CreateIntentResponseFromJSON,
  CreateIntentResponseToJSON,
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
} from '../models';

export interface CreatePaymentIntentRequest {
  createIntentRequest: CreateIntentRequest;
}

/**
 *
 */
export class KlarnaApi extends runtime.BaseAPI {
  /**
   * Create payment intent
   */
  async createPaymentIntentRaw(
    requestParameters: CreatePaymentIntentRequest,
  ): Promise<runtime.ApiResponse<CreateIntentResponse>> {
    if (
      requestParameters.createIntentRequest === null ||
      requestParameters.createIntentRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'createIntentRequest',
        'Required parameter requestParameters.createIntentRequest was null or undefined when calling createPaymentIntent.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/klarna`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateIntentRequestToJSON(requestParameters.createIntentRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateIntentResponseFromJSON(jsonValue),
    );
  }

  /**
   * Create payment intent
   */
  async createPaymentIntent(
    requestParameters: CreatePaymentIntentRequest,
  ): Promise<CreateIntentResponse> {
    const response = await this.createPaymentIntentRaw(requestParameters);
    return await response.value();
  }
}
