import { RootState, Store } from '.';

let state: Store;

export const setStore = (store: Store): void => {
  state = store;
};

export const getState = (): RootState => {
  return (state && state.getState()) || {};
};

export default {
  setStore,
  getState,
};
