/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
  InvitePartnerRequest,
  InvitePartnerRequestFromJSON,
  InvitePartnerRequestToJSON,
  MessageResponse,
  MessageResponseFromJSON,
  MessageResponseToJSON,
  PurchaseRequest,
  PurchaseRequestFromJSON,
  PurchaseRequestToJSON,
  PurchaseResponse,
  PurchaseResponseFromJSON,
  PurchaseResponseToJSON,
  SignWillRequest,
  SignWillRequestFromJSON,
  SignWillRequestToJSON,
  SpreadTheWordRequest,
  SpreadTheWordRequestFromJSON,
  SpreadTheWordRequestToJSON,
  WillComponents,
  WillComponentsFromJSON,
  WillComponentsToJSON,
  WillResponse,
  WillResponseFromJSON,
  WillResponseToJSON,
} from '../models';

export interface GetWillRequest {
  will: number;
}

export interface InvitePartnerOperationRequest {
  document: number;
  invitePartnerRequest: InvitePartnerRequest;
}

export interface PurchaseWillRequest {
  document: number;
  purchaseRequest: PurchaseRequest;
}

export interface SignWillOperationRequest {
  document: number;
  signWillRequest: SignWillRequest;
}

export interface SpreadTheWordOperationRequest {
  document: number;
  spreadTheWordRequest: SpreadTheWordRequest;
}

export interface WillStepsRequest {
  will: number;
  willComponents: WillComponents;
}

/**
 *
 */
export class WillsApi extends runtime.BaseAPI {
  /**
   * Get information on will.
   */
  async getWillRaw(
    requestParameters: GetWillRequest,
  ): Promise<runtime.ApiResponse<WillResponse>> {
    if (
      requestParameters.will === null ||
      requestParameters.will === undefined
    ) {
      throw new runtime.RequiredError(
        'will',
        'Required parameter requestParameters.will was null or undefined when calling getWill.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/wills/{will}`.replace(
        `{${'will'}}`,
        encodeURIComponent(String(requestParameters.will)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WillResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get information on will.
   */
  async getWill(requestParameters: GetWillRequest): Promise<WillResponse> {
    const response = await this.getWillRaw(requestParameters);
    return await response.value();
  }

  /**
   * Invite partner
   */
  async invitePartnerRaw(
    requestParameters: InvitePartnerOperationRequest,
  ): Promise<runtime.ApiResponse<MessageResponse>> {
    if (
      requestParameters.document === null ||
      requestParameters.document === undefined
    ) {
      throw new runtime.RequiredError(
        'document',
        'Required parameter requestParameters.document was null or undefined when calling invitePartner.',
      );
    }

    if (
      requestParameters.invitePartnerRequest === null ||
      requestParameters.invitePartnerRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'invitePartnerRequest',
        'Required parameter requestParameters.invitePartnerRequest was null or undefined when calling invitePartner.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/wills/{document}/invite-partner`.replace(
        `{${'document'}}`,
        encodeURIComponent(String(requestParameters.document)),
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: InvitePartnerRequestToJSON(requestParameters.invitePartnerRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MessageResponseFromJSON(jsonValue),
    );
  }

  /**
   * Invite partner
   */
  async invitePartner(
    requestParameters: InvitePartnerOperationRequest,
  ): Promise<MessageResponse> {
    const response = await this.invitePartnerRaw(requestParameters);
    return await response.value();
  }

  /**
   * Purchase will
   */
  async purchaseWillRaw(
    requestParameters: PurchaseWillRequest,
  ): Promise<runtime.ApiResponse<PurchaseResponse>> {
    if (
      requestParameters.document === null ||
      requestParameters.document === undefined
    ) {
      throw new runtime.RequiredError(
        'document',
        'Required parameter requestParameters.document was null or undefined when calling purchaseWill.',
      );
    }

    if (
      requestParameters.purchaseRequest === null ||
      requestParameters.purchaseRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'purchaseRequest',
        'Required parameter requestParameters.purchaseRequest was null or undefined when calling purchaseWill.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/wills/{document}/purchase`.replace(
        `{${'document'}}`,
        encodeURIComponent(String(requestParameters.document)),
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PurchaseRequestToJSON(requestParameters.purchaseRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PurchaseResponseFromJSON(jsonValue),
    );
  }

  /**
   * Purchase will
   */
  async purchaseWill(
    requestParameters: PurchaseWillRequest,
  ): Promise<PurchaseResponse> {
    const response = await this.purchaseWillRaw(requestParameters);
    return await response.value();
  }

  /**
   * Sign will
   */
  async signWillRaw(
    requestParameters: SignWillOperationRequest,
  ): Promise<runtime.ApiResponse<WillResponse>> {
    if (
      requestParameters.document === null ||
      requestParameters.document === undefined
    ) {
      throw new runtime.RequiredError(
        'document',
        'Required parameter requestParameters.document was null or undefined when calling signWill.',
      );
    }

    if (
      requestParameters.signWillRequest === null ||
      requestParameters.signWillRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'signWillRequest',
        'Required parameter requestParameters.signWillRequest was null or undefined when calling signWill.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/wills/{document}/sign`.replace(
        `{${'document'}}`,
        encodeURIComponent(String(requestParameters.document)),
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SignWillRequestToJSON(requestParameters.signWillRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WillResponseFromJSON(jsonValue),
    );
  }

  /**
   * Sign will
   */
  async signWill(
    requestParameters: SignWillOperationRequest,
  ): Promise<WillResponse> {
    const response = await this.signWillRaw(requestParameters);
    return await response.value();
  }

  /**
   * Spread the word
   */
  async spreadTheWordRaw(
    requestParameters: SpreadTheWordOperationRequest,
  ): Promise<runtime.ApiResponse<MessageResponse>> {
    if (
      requestParameters.document === null ||
      requestParameters.document === undefined
    ) {
      throw new runtime.RequiredError(
        'document',
        'Required parameter requestParameters.document was null or undefined when calling spreadTheWord.',
      );
    }

    if (
      requestParameters.spreadTheWordRequest === null ||
      requestParameters.spreadTheWordRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'spreadTheWordRequest',
        'Required parameter requestParameters.spreadTheWordRequest was null or undefined when calling spreadTheWord.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/wills/{document}/spread-the-word`.replace(
        `{${'document'}}`,
        encodeURIComponent(String(requestParameters.document)),
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SpreadTheWordRequestToJSON(requestParameters.spreadTheWordRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MessageResponseFromJSON(jsonValue),
    );
  }

  /**
   * Spread the word
   */
  async spreadTheWord(
    requestParameters: SpreadTheWordOperationRequest,
  ): Promise<MessageResponse> {
    const response = await this.spreadTheWordRaw(requestParameters);
    return await response.value();
  }

  /**
   * Add will components.
   */
  async willStepsRaw(
    requestParameters: WillStepsRequest,
  ): Promise<runtime.ApiResponse<WillResponse>> {
    if (
      requestParameters.will === null ||
      requestParameters.will === undefined
    ) {
      throw new runtime.RequiredError(
        'will',
        'Required parameter requestParameters.will was null or undefined when calling willSteps.',
      );
    }

    if (
      requestParameters.willComponents === null ||
      requestParameters.willComponents === undefined
    ) {
      throw new runtime.RequiredError(
        'willComponents',
        'Required parameter requestParameters.willComponents was null or undefined when calling willSteps.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/wills/{will}`.replace(
        `{${'will'}}`,
        encodeURIComponent(String(requestParameters.will)),
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: WillComponentsToJSON(requestParameters.willComponents),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WillResponseFromJSON(jsonValue),
    );
  }

  /**
   * Add will components.
   */
  async willSteps(requestParameters: WillStepsRequest): Promise<WillResponse> {
    const response = await this.willStepsRaw(requestParameters);
    return await response.value();
  }
}
