import { Typography } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@components/common/button';
// import/order disabled because it creates issues with styles import
// eslint-disable-next-line import/order
import useStyles from '@components/common/add-email-form/styles';
import Field from '@components/common/field';
import Validation from '@utils/validation';

const inviteByEmailValidation = Yup.object().shape({
  email: Validation.emailRequired,
});

export interface InviteByEmailData {
  email: string;
}

interface Props {
  title: string;
  description: string;
  inviteByEmail: (data: InviteByEmailData) => void;
}

const AddEmailForm: React.FC<Props> = (props) => {
  const { title, description, inviteByEmail } = props;

  const classes = useStyles();

  return (
    <>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body2">{description}</Typography>

      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={inviteByEmail}
        validationSchema={inviteByEmailValidation}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field
              id="email-field-add-email-form"
              type="text"
              placeholder="Email address"
              label="Email address"
              variant="filled"
              name="email"
              value={values.email}
              onChange={handleChange}
              errorMessage={errors.email}
            />
            <Button
              id="send-button-add-email-form"
              text="Send"
              styleType="containedBlue"
              type="submit"
              className={classes.sendButton}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddEmailForm;
