/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Applier object.
 * @export
 * @interface ApplierObject
 */
export interface ApplierObject {
  /**
   *
   * @type {number}
   * @memberof ApplierObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ApplierObject
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ApplierObject
   */
  country: string;
}

export function ApplierObjectFromJSON(json: any): ApplierObject {
  return ApplierObjectFromJSONTyped(json, false);
}

export function ApplierObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ApplierObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    email: json['email'],
    country: json['country'],
  };
}

export function ApplierObjectToJSON(value?: ApplierObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    country: value.country,
  };
}
