import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    bottom: 119,
    right: 10,
    width: 60,
    height: 60,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '50%',
    color: theme.palette.background.default,
    fontSize: 28,
    zIndex: 100,
    transition: 'opacity 0.2s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    '& i': {
      verticalAlign: 'middle',
    },
  },
  '@media(pointer: fine)': {
    wrapper: {
      right: 26,
      bottom: 139,

      '&:hover': {
        opacity: 0.8,
      },
    },
  },
}));

export default useStyles;
