import { StyleRules, Theme } from '@material-ui/core/styles';

const cssVariables = (theme: Theme): StyleRules => ({
  '@global': {
    ':root': {
      '--header-height': '88px',
      '--progress-bar-height': '4px',
      '--header-user-logged-height': '112px',
      '--step-width': '453px',
      '--purchase-bar-height': '176px',
      [theme.breakpoints.up('sm')]: {
        '--progress-bar-height': '8px',
        '--header-height': '96px',
        '--purchase-bar-height': '88px',
      },
      [theme.breakpoints.up('md')]: {
        '--header-height': '112px',
      },
    },
  },
});

export default cssVariables;
