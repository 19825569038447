import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { APIError } from '@lib/api';
import transformObject from '@utils/case-transformator';

export const Axios = axios.create();

const apiRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const newConfigs = { ...config };

  newConfigs.headers = newConfigs.headers ?? {};

  newConfigs.headers['Content-Type'] =
    newConfigs.headers['Content-Type'] || 'application/json;charset=utf-8';

  newConfigs.headers.Accept = newConfigs.headers.Accept || 'application/json';

  return newConfigs;
};

export const configureAxios = (): (() => void) => {
  const apiReq = Axios.interceptors.request.use(apiRequest);

  const request = Axios.interceptors.request.use(
    (req) => {
      return {
        ...req,
        data: transformObject('snake', req.data),
      };
    },
    (error: AxiosError) => {
      throw new APIError(error.message, error.code || '');
    },
  );

  const response = Axios.interceptors.response.use((res) => {
    return {
      ...res,
      data: transformObject('camel', res.data),
    };
  });

  return () => {
    Axios.interceptors.request.eject(request);
    Axios.interceptors.response.eject(response);
    Axios.interceptors.request.eject(apiReq);
  };
};
