/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Subscription object
 * @export
 * @interface SubscriptionObject
 */
export interface SubscriptionObject {
  /**
   *
   * @type {string}
   * @memberof SubscriptionObject
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionObject
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionObject
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionObject
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionObject
   */
  currentPeriodStart: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionObject
   */
  currentPeriodEnd: string;
}

export function SubscriptionObjectFromJSON(json: any): SubscriptionObject {
  return SubscriptionObjectFromJSONTyped(json, false);
}

export function SubscriptionObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubscriptionObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status'],
    startDate: json['start_date'],
    created: json['created'],
    currentPeriodStart: json['current_period_start'],
    currentPeriodEnd: json['current_period_end'],
  };
}

export function SubscriptionObjectToJSON(
  value?: SubscriptionObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: value.status,
    start_date: value.startDate,
    created: value.created,
    current_period_start: value.currentPeriodStart,
    current_period_end: value.currentPeriodEnd,
  };
}
