/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Meta parameters for pagination.
 * @export
 * @interface MetaParameters
 */
export interface MetaParameters {
  /**
   *
   * @type {number}
   * @memberof MetaParameters
   */
  currentPage?: number;
  /**
   *
   * @type {number}
   * @memberof MetaParameters
   */
  lastPage?: number;
  /**
   *
   * @type {string}
   * @memberof MetaParameters
   */
  perPage?: string;
  /**
   *
   * @type {number}
   * @memberof MetaParameters
   */
  to?: number;
  /**
   *
   * @type {number}
   * @memberof MetaParameters
   */
  total?: number;
}

export function MetaParametersFromJSON(json: any): MetaParameters {
  return MetaParametersFromJSONTyped(json, false);
}

export function MetaParametersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MetaParameters {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    currentPage: !exists(json, 'current_page')
      ? undefined
      : json['current_page'],
    lastPage: !exists(json, 'last_page') ? undefined : json['last_page'],
    perPage: !exists(json, 'per_page') ? undefined : json['per_page'],
    to: !exists(json, 'to') ? undefined : json['to'],
    total: !exists(json, 'total') ? undefined : json['total'],
  };
}

export function MetaParametersToJSON(value?: MetaParameters | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    current_page: value.currentPage,
    last_page: value.lastPage,
    per_page: value.perPage,
    to: value.to,
    total: value.total,
  };
}
