import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  firstCircle: {
    width: 560,
    height: 535,
    top: -490,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  firstCircleDisabled: {
    display: 'none',
  },
  secondCircle: {
    width: 221,
    height: 221,
    right: -169,
    top: 470,
  },
  footer: {
    overflow: 'hidden',
  },
  mainContent: {
    padding: '80px 0 0',
    position: 'relative',
  },
  logoWrapper: {
    width: 95,
    height: 32,
    marginBottom: 20,
  },
  textSectionsWrapper: {
    display: 'grid',
    gridRowGap: 40,
    gridTemplateColumns: '1fr',
    gridColumnGap: 30,
  },
  textBlock: {
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& i': {
      fontSize: 16,
      color: theme.palette.info.main,
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 8,
    },
  },
  textBlockTitle: {
    marginBottom: 12,
  },
  textBlockLink: {
    marginBottom: 8,
  },
  contactLink: {
    marginBottom: 16,
  },
  iconsWrapper: {
    display: 'flex',
  },
  connect: {
    '& a': {
      textDecoration: 'none',
      transition: 'opacity 0.2s',
      color: theme.palette.info.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover': {
        opacity: '0.8',
      },
    },
    '& p': {
      marginBottom: 16,
    },
    '& i': {
      fontSize: 24,
    },
    '& svg': {
      height: 30,
      width: 30,
    },
    '& a:nth-child(n+2)': { marginLeft: 24 },
  },
  copyright: {
    padding: '8px 0',
    marginTop: 80,
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.up('sm')]: {
    firstCircleDisabled: {
      display: 'block',
    },
    secondCircle: {
      height: 223,
      width: 223,
      top: 390,
      right: -145,
    },
    textSectionsWrapper: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  [theme.breakpoints.up('md')]: {
    secondCircle: {
      top: 270,
      right: -155,
    },
    textSectionsWrapper: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  [theme.breakpoints.up('lg')]: {
    secondCircle: {
      top: 270,
      right: -270,
    },
  },
}));

export default useStyles;
