/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Beneficiaries object.
 * @export
 * @interface BeneficiariesRequestObject
 */
export interface BeneficiariesRequestObject {
  /**
   *
   * @type {Array<number>}
   * @memberof BeneficiariesRequestObject
   */
  actors?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof BeneficiariesRequestObject
   */
  charities?: Array<number>;
}

export function BeneficiariesRequestObjectFromJSON(
  json: any,
): BeneficiariesRequestObject {
  return BeneficiariesRequestObjectFromJSONTyped(json, false);
}

export function BeneficiariesRequestObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BeneficiariesRequestObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    actors: !exists(json, 'actors') ? undefined : json['actors'],
    charities: !exists(json, 'charities') ? undefined : json['charities'],
  };
}

export function BeneficiariesRequestObjectToJSON(
  value?: BeneficiariesRequestObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    actors: value.actors,
    charities: value.charities,
  };
}
