/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ActorContactTypes,
  ActorContactTypesFromJSON,
  ActorContactTypesFromJSONTyped,
  ActorContactTypesToJSON,
  ReceiverRequestObject,
  ReceiverRequestObjectFromJSON,
  ReceiverRequestObjectFromJSONTyped,
  ReceiverRequestObjectToJSON,
} from './';

/**
 * Receiver contact types object.
 * @export
 * @interface ReceiverContactTypesObject
 */
export interface ReceiverContactTypesObject {
  /**
   *
   * @type {ReceiverRequestObject}
   * @memberof ReceiverContactTypesObject
   */
  receiver: ReceiverRequestObject;
  /**
   *
   * @type {Array<ActorContactTypes>}
   * @memberof ReceiverContactTypesObject
   */
  types: Array<ActorContactTypes> | null;
  /**
   *
   * @type {string}
   * @memberof ReceiverContactTypesObject
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ReceiverContactTypesObject
   */
  phone?: string;
}

export function ReceiverContactTypesObjectFromJSON(
  json: any,
): ReceiverContactTypesObject {
  return ReceiverContactTypesObjectFromJSONTyped(json, false);
}

export function ReceiverContactTypesObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReceiverContactTypesObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    receiver: ReceiverRequestObjectFromJSON(json['receiver']),
    types:
      json['types'] === null
        ? null
        : (json['types'] as Array<any>).map(ActorContactTypesFromJSON),
    email: !exists(json, 'email') ? undefined : json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
  };
}

export function ReceiverContactTypesObjectToJSON(
  value?: ReceiverContactTypesObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    receiver: ReceiverRequestObjectToJSON(value.receiver),
    types:
      value.types === null
        ? null
        : (value.types as Array<any>).map(ActorContactTypesToJSON),
    email: value.email,
    phone: value.phone,
  };
}
