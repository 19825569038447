/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  GiftObject,
  GiftObjectFromJSON,
  GiftObjectFromJSONTyped,
  GiftObjectToJSON,
} from './';

/**
 * Gift response object.
 * @export
 * @interface GiftResponse
 */
export interface GiftResponse {
  /**
   *
   * @type {GiftObject}
   * @memberof GiftResponse
   */
  data: GiftObject;
}

export function GiftResponseFromJSON(json: any): GiftResponse {
  return GiftResponseFromJSONTyped(json, false);
}

export function GiftResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GiftResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: GiftObjectFromJSON(json['data']),
  };
}

export function GiftResponseToJSON(value?: GiftResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: GiftObjectToJSON(value.data),
  };
}
