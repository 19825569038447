import { createAsyncThunk } from '@reduxjs/toolkit';

import { Axios } from '@config/axios/axiosConfiguration';
import {
  ArticleCollectionResponse,
  ArticleResponse,
  GetOneArticleRequest,
  GetOneGuideRequest,
  GuideResponse,
  GuidesCollectionResponse,
} from '@lib/api';
import { BLOGS_PAGINATION } from '@pages/blog';
import { GuidesAPI, BlogAPI } from '@services/api';

import { Rating } from '.';

interface RatingResponse {
  rating: Rating;
}

interface ReviewsResponse {
  reviews: {
    customer: {
      displayName: string;
    };
    service: {
      createdAt: string;
      rating: Rating;
      title: string;
      review: string;
    };
  }[];
}

export const getAllGuides = createAsyncThunk(
  'guides/getAllGuides',
  async (): Promise<GuidesCollectionResponse> => {
    return GuidesAPI.getGuides();
  },
);

export const getOneGuide = createAsyncThunk(
  'reviews/getOneGuide',
  async (requestParameters: GetOneGuideRequest): Promise<GuideResponse> => {
    return GuidesAPI.getOneGuide(requestParameters);
  },
);

export const getArticles = createAsyncThunk(
  'blog/getArticles',
  async (): Promise<ArticleCollectionResponse> => {
    return BlogAPI.getArticles({ perPage: BLOGS_PAGINATION });
  },
);

export const getOneArticle = createAsyncThunk(
  'blog/getOneArticle',
  async (requestParameters: GetOneArticleRequest): Promise<ArticleResponse> => {
    return BlogAPI.getOneArticle(requestParameters);
  },
);

export const getRating = createAsyncThunk(
  'reviews/getRating',
  async (): Promise<RatingResponse> => {
    const response = await Axios.get<RatingResponse>(
      'https://api.feefo.com/api/10/reviews/summary/all?merchant_identifier=simpwill-ltd',
    );

    return response.data;
  },
);

export const getReviews = createAsyncThunk(
  'reviews/getReviews',
  async (): Promise<ReviewsResponse> => {
    const response = await Axios.get<ReviewsResponse>(
      'https://api.feefo.com/api/10/reviews/all?merchant_identifier=simpwill-ltd&fields=' +
        'reviews.customer.display_name,' +
        'reviews.service.rating.min,' +
        'reviews.service.rating.max,' +
        'reviews.service.rating.rating,' +
        'reviews.service.review,' +
        'reviews.service.title,' +
        'reviews.service.created_at,',
    );

    return response.data;
  },
);
