/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ActorRoles,
  ActorRolesFromJSON,
  ActorRolesFromJSONTyped,
  ActorRolesToJSON,
  MaritalStatuses,
  MaritalStatusesFromJSON,
  MaritalStatusesFromJSONTyped,
  MaritalStatusesToJSON,
  PetObject,
  PetObjectFromJSON,
  PetObjectFromJSONTyped,
  PetObjectToJSON,
} from './';

/**
 * Actor object.
 * @export
 * @interface ActorObject
 */
export interface ActorObject {
  /**
   *
   * @type {number}
   * @memberof ActorObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  title: string | null;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  lastName: string;
  /**
   *
   * @type {MaritalStatuses}
   * @memberof ActorObject
   */
  maritalStatus: MaritalStatuses | null;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  dateOfBirth: string | null;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  email: string | null;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  phone: string | null;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  houseNumber: string | null;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  street: string | null;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  city: string | null;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  postcode: string | null;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof ActorObject
   */
  guardians?: Array<ActorObject> | null;
  /**
   *
   * @type {Array<ActorObject>}
   * @memberof ActorObject
   */
  guardedChildren?: Array<ActorObject> | null;
  /**
   *
   * @type {Array<PetObject>}
   * @memberof ActorObject
   */
  guardedPets?: Array<PetObject> | null;
  /**
   *
   * @type {Array<ActorRoles>}
   * @memberof ActorObject
   */
  roles: Array<ActorRoles>;
  /**
   *
   * @type {Array<ActorRoles>}
   * @memberof ActorObject
   */
  rolesForLpa: Array<ActorRoles>;
  /**
   *
   * @type {boolean}
   * @memberof ActorObject
   */
  mentalCapacity: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ActorObject
   */
  notify: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ActorObject
   */
  personalRelation: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ActorObject
   */
  liveTogether: boolean | null;
}

export function ActorObjectFromJSON(json: any): ActorObject {
  return ActorObjectFromJSONTyped(json, false);
}

export function ActorObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActorObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    firstName: json['first_name'],
    lastName: json['last_name'],
    maritalStatus: MaritalStatusesFromJSON(json['marital_status']),
    dateOfBirth: json['date_of_birth'],
    email: json['email'],
    phone: json['phone'],
    houseNumber: json['house_number'],
    street: json['street'],
    city: json['city'],
    postcode: json['postcode'],
    guardians: !exists(json, 'guardians')
      ? undefined
      : json['guardians'] === null
      ? null
      : (json['guardians'] as Array<any>).map(ActorObjectFromJSON),
    guardedChildren: !exists(json, 'guardedChildren')
      ? undefined
      : json['guardedChildren'] === null
      ? null
      : (json['guardedChildren'] as Array<any>).map(ActorObjectFromJSON),
    guardedPets: !exists(json, 'guardedPets')
      ? undefined
      : json['guardedPets'] === null
      ? null
      : (json['guardedPets'] as Array<any>).map(PetObjectFromJSON),
    roles: (json['roles'] as Array<any>).map(ActorRolesFromJSON),
    rolesForLpa: (json['rolesForLpa'] as Array<any>).map(ActorRolesFromJSON),
    mentalCapacity: json['mental_capacity'],
    notify: json['notify'],
    personalRelation: json['personal_relation'],
    liveTogether: json['live_together'],
  };
}

export function ActorObjectToJSON(value?: ActorObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    first_name: value.firstName,
    last_name: value.lastName,
    marital_status: MaritalStatusesToJSON(value.maritalStatus),
    date_of_birth: value.dateOfBirth,
    email: value.email,
    phone: value.phone,
    house_number: value.houseNumber,
    street: value.street,
    city: value.city,
    postcode: value.postcode,
    guardians:
      value.guardians === undefined
        ? undefined
        : value.guardians === null
        ? null
        : (value.guardians as Array<any>).map(ActorObjectToJSON),
    guardedChildren:
      value.guardedChildren === undefined
        ? undefined
        : value.guardedChildren === null
        ? null
        : (value.guardedChildren as Array<any>).map(ActorObjectToJSON),
    guardedPets:
      value.guardedPets === undefined
        ? undefined
        : value.guardedPets === null
        ? null
        : (value.guardedPets as Array<any>).map(PetObjectToJSON),
    roles: (value.roles as Array<any>).map(ActorRolesToJSON),
    rolesForLpa: (value.rolesForLpa as Array<any>).map(ActorRolesToJSON),
    mental_capacity: value.mentalCapacity,
    notify: value.notify,
    personal_relation: value.personalRelation,
    live_together: value.liveTogether,
  };
}
