/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ApplierObject,
  ApplierObjectFromJSON,
  ApplierObjectFromJSONTyped,
  ApplierObjectToJSON,
} from './';

/**
 * Applier response object.
 * @export
 * @interface ApplierResponse
 */
export interface ApplierResponse {
  /**
   *
   * @type {ApplierObject}
   * @memberof ApplierResponse
   */
  data: ApplierObject;
}

export function ApplierResponseFromJSON(json: any): ApplierResponse {
  return ApplierResponseFromJSONTyped(json, false);
}

export function ApplierResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ApplierResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: ApplierObjectFromJSON(json['data']),
  };
}

export function ApplierResponseToJSON(value?: ApplierResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ApplierObjectToJSON(value.data),
  };
}
