/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  HistoryObject,
  HistoryObjectFromJSON,
  HistoryObjectFromJSONTyped,
  HistoryObjectToJSON,
} from './';

/**
 * History response.
 * @export
 * @interface HistoryResponse
 */
export interface HistoryResponse {
  /**
   *
   * @type {Array<HistoryObject>}
   * @memberof HistoryResponse
   */
  data: Array<HistoryObject>;
}

export function HistoryResponseFromJSON(json: any): HistoryResponse {
  return HistoryResponseFromJSONTyped(json, false);
}

export function HistoryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HistoryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(HistoryObjectFromJSON),
  };
}

export function HistoryResponseToJSON(value?: HistoryResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(HistoryObjectToJSON),
  };
}
