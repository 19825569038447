/* eslint-disable react/jsx-props-no-spreading */
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from '@material-ui/core';
import classNames from 'classnames';
import Link from 'next/link';

import useStyles from '@components/common/button/styles';

interface Props extends MuiButtonProps {
  styleType:
    | 'containedBlue'
    | 'containedWhite'
    | 'containedRed'
    | 'containedPink'
    | 'outlinePink'
    | 'outlineBlue'
    | 'outlineRed'
    | 'outlineTransparent'
    | 'none';
  text: string | JSX.Element;
  notInteractive?: boolean;
  fullWidthOnMobile?: boolean;
  isLoading?: boolean;
  arrow?: 'leftSide' | 'rightSide' | 'upSide' | 'downSide';
  plusIcon?: true;
  downloadIcon?: true;
  iconReverse?: true;
}

const Button: React.FC<Props> = (props) => {
  const {
    disableRipple,
    text,
    styleType,
    className,
    disabled,
    notInteractive,
    fullWidthOnMobile,
    href,
    arrow,
    plusIcon,
    downloadIcon,
    iconReverse,
    type,
    isLoading,
    onClick,
    ...rest
  } = props;

  const classes = useStyles();

  const button = (
    <MuiButton
      {...rest}
      variant={
        (styleType.includes('contained') && 'contained') ||
        (styleType.includes('outline') && 'outlined') ||
        undefined
      }
      disableRipple={disableRipple}
      className={classNames(classes[styleType], classes.button, className, {
        [classes.fullWidthOnMobile]: fullWidthOnMobile,
        [classes.directionReverse]: arrow === 'leftSide',
        [classes.iconReverse]: iconReverse,
        [classes.notInteractive]: notInteractive,
      })}
      color={styleType === 'containedBlue' ? 'primary' : 'secondary'}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {isLoading ? (
        <CircularProgress color="inherit" size={30} thickness={5} />
      ) : (
        text
      )}
      {arrow === 'rightSide' && <i className="icon-arrow-right" />}
      {arrow === 'leftSide' && <i className="icon-arrow-left" />}
      {arrow === 'upSide' && <i className="icon-arrow-up" />}
      {arrow === 'downSide' && <i className="icon-arrow-down" />}
      {plusIcon && <i className="icon-plus" />}
      {downloadIcon && <i className="icon-download" />}
    </MuiButton>
  );

  return href ? (
    <Link href={href} passHref>
      {button}
    </Link>
  ) : (
    button
  );
};

export default Button;
