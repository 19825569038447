/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  GuideObject,
  GuideObjectFromJSON,
  GuideObjectFromJSONTyped,
  GuideObjectToJSON,
} from './';

/**
 * Guide response object.
 * @export
 * @interface GuideResponse
 */
export interface GuideResponse {
  /**
   *
   * @type {GuideObject}
   * @memberof GuideResponse
   */
  data: GuideObject;
}

export function GuideResponseFromJSON(json: any): GuideResponse {
  return GuideResponseFromJSONTyped(json, false);
}

export function GuideResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GuideResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: GuideObjectFromJSON(json['data']),
  };
}

export function GuideResponseToJSON(value?: GuideResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: GuideObjectToJSON(value.data),
  };
}
