/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PaymentObject,
  PaymentObjectFromJSON,
  PaymentObjectFromJSONTyped,
  PaymentObjectToJSON,
  SubscriptionProfileObject,
  SubscriptionProfileObjectFromJSON,
  SubscriptionProfileObjectFromJSONTyped,
  SubscriptionProfileObjectToJSON,
  UserContactTypes,
  UserContactTypesFromJSON,
  UserContactTypesFromJSONTyped,
  UserContactTypesToJSON,
} from './';

/**
 * User object.
 * @export
 * @interface UserObject
 */
export interface UserObject {
  /**
   *
   * @type {number}
   * @memberof UserObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserObject
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserObject
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserObject
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserObject
   */
  phone: string | null;
  /**
   *
   * @type {PaymentObject}
   * @memberof UserObject
   */
  payment: PaymentObject | null;
  /**
   *
   * @type {SubscriptionProfileObject}
   * @memberof UserObject
   */
  subscription: SubscriptionProfileObject | null;
  /**
   *
   * @type {boolean}
   * @memberof UserObject
   */
  mailing: boolean;
  /**
   *
   * @type {Array<UserContactTypes>}
   * @memberof UserObject
   */
  contactTypes: Array<UserContactTypes> | null;
  /**
   *
   * @type {string}
   * @memberof UserObject
   */
  link: string;
  /**
   *
   * @type {number}
   * @memberof UserObject
   */
  singleWillPrice: number | null;
  /**
   *
   * @type {number}
   * @memberof UserObject
   */
  jointWillPrice: number | null;
  /**
   *
   * @type {number}
   * @memberof UserObject
   */
  oneLpaPrice: number | null;
  /**
   *
   * @type {number}
   * @memberof UserObject
   */
  bothLpasPrice: number | null;
}

export function UserObjectFromJSON(json: any): UserObject {
  return UserObjectFromJSONTyped(json, false);
}

export function UserObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    firstName: json['first_name'],
    lastName: json['last_name'],
    email: json['email'],
    phone: json['phone'],
    payment: PaymentObjectFromJSON(json['payment']),
    subscription: SubscriptionProfileObjectFromJSON(json['subscription']),
    mailing: json['mailing'],
    contactTypes:
      json['contact_types'] === null
        ? null
        : (json['contact_types'] as Array<any>).map(UserContactTypesFromJSON),
    link: json['link'],
    singleWillPrice: json['single_will_price'],
    jointWillPrice: json['joint_will_price'],
    oneLpaPrice: json['one_lpa_price'],
    bothLpasPrice: json['both_lpas_price'],
  };
}

export function UserObjectToJSON(value?: UserObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    first_name: value.firstName,
    last_name: value.lastName,
    email: value.email,
    phone: value.phone,
    payment: PaymentObjectToJSON(value.payment),
    subscription: SubscriptionProfileObjectToJSON(value.subscription),
    mailing: value.mailing,
    contact_types:
      value.contactTypes === null
        ? null
        : (value.contactTypes as Array<any>).map(UserContactTypesToJSON),
    link: value.link,
    single_will_price: value.singleWillPrice,
    joint_will_price: value.jointWillPrice,
    one_lpa_price: value.oneLpaPrice,
    both_lpas_price: value.bothLpasPrice,
  };
}
