/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  LpaObject,
  LpaObjectFromJSON,
  LpaObjectFromJSONTyped,
  LpaObjectToJSON,
  WillObject,
  WillObjectFromJSON,
  WillObjectFromJSONTyped,
  WillObjectToJSON,
} from './';

/**
 * Documents object.
 * @export
 * @interface DocumentsObject
 */
export interface DocumentsObject {
  /**
   *
   * @type {WillObject}
   * @memberof DocumentsObject
   */
  will: WillObject;
  /**
   *
   * @type {LpaObject}
   * @memberof DocumentsObject
   */
  financeLpa: LpaObject;
  /**
   *
   * @type {LpaObject}
   * @memberof DocumentsObject
   */
  healthLpa: LpaObject;
}

export function DocumentsObjectFromJSON(json: any): DocumentsObject {
  return DocumentsObjectFromJSONTyped(json, false);
}

export function DocumentsObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DocumentsObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    will: WillObjectFromJSON(json['will']),
    financeLpa: LpaObjectFromJSON(json['financeLpa']),
    healthLpa: LpaObjectFromJSON(json['healthLpa']),
  };
}

export function DocumentsObjectToJSON(value?: DocumentsObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    will: WillObjectToJSON(value.will),
    financeLpa: LpaObjectToJSON(value.financeLpa),
    healthLpa: LpaObjectToJSON(value.healthLpa),
  };
}
