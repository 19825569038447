/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Document types object.
 * @export
 * @enum {string}
 */
export enum DocumentTypes {
  Will = 'Will',
  FinanceLpa = 'Finance LPA',
  HealthLpa = 'Health LPA',
}

export function DocumentTypesFromJSON(json: any): DocumentTypes {
  return DocumentTypesFromJSONTyped(json, false);
}

export function DocumentTypesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DocumentTypes {
  return json as DocumentTypes;
}

export function DocumentTypesToJSON(value?: DocumentTypes | null): any {
  return value as any;
}
