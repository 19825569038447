/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DocumentsObject,
  DocumentsObjectFromJSON,
  DocumentsObjectFromJSONTyped,
  DocumentsObjectToJSON,
} from './';

/**
 * Documents response.
 * @export
 * @interface DocumentsResponse
 */
export interface DocumentsResponse {
  /**
   *
   * @type {DocumentsObject}
   * @memberof DocumentsResponse
   */
  data: DocumentsObject;
}

export function DocumentsResponseFromJSON(json: any): DocumentsResponse {
  return DocumentsResponseFromJSONTyped(json, false);
}

export function DocumentsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DocumentsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: DocumentsObjectFromJSON(json['data']),
  };
}

export function DocumentsResponseToJSON(value?: DocumentsResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: DocumentsObjectToJSON(value.data),
  };
}
