import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { Dispatch, SetStateAction, useEffect } from 'react';

import useStyles from '@components/common/fullscreen-popup/styles';

import Button from '../button';

interface Edit {
  type: 'edit';
  closePopup?: () => void;
  updateFunction?: () => void;
}

interface ShowMessage {
  type: 'showMessage';
  title: string;
  description: string;
  imageUrl: string;
  imageClass?: string;
  imageStickToBottom?: true;
  href?: string;
  onContinue?: () => void;
  onClose?: Dispatch<SetStateAction<boolean>>;
}

interface CommonProps {
  className?: string;
  continueButtonText?: string;
}

type Props = CommonProps & (ShowMessage | Edit);

const FullscreenPopup: React.FC<Props> = (props) => {
  const { type, children, className } = props;

  const classes = useStyles();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  });

  const popupCase = () => {
    if (type === 'edit') {
      const { closePopup } = props;

      return (
        <div className={classes.wrapper}>
          {closePopup && (
            <button
              id="close-button-fullscreen-popup-type-edit"
              className={classes.closeIcon}
              onClick={closePopup}
              type="button"
            >
              <i className="icon-close" />
            </button>
          )}

          {children}
        </div>
      );
    }

    const {
      title,
      description,
      href,
      imageUrl,
      imageClass,
      imageStickToBottom,
      continueButtonText,
      onContinue,
      onClose,
    } = props;

    return (
      <>
        {onClose && (
          <button
            id="close-button-fullscreen-popup-mobile"
            className={classes.closeButtonMobile}
            type="button"
            onClick={() => onClose(false)}
          >
            <i className={classNames('icon-close', classes.crossIcon)} />
          </button>
        )}

        <div className={classes.imageSection}>
          <img
            src={imageUrl}
            alt="icon"
            className={classNames(classes.popupImage, imageClass, {
              [classes.stickToBottom]: imageStickToBottom,
            })}
          />
          <div className={classes.greySection} />
        </div>
        <Typography
          variant="h3"
          className={classNames(classes.title, classes.textCenter)}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={classNames(classes.description, classes.textCenter)}
        >
          {description}
        </Typography>
        <Button
          id="continue-button-fullscreen-popup"
          type="button"
          styleType="containedBlue"
          text={continueButtonText || 'Continue'}
          href={href}
          onClick={onContinue}
          className={classes.linkButton}
        />
        {onClose && (
          <button
            id="close-button-fullscreen-popup"
            type="button"
            className={classes.closeButton}
            onClick={() => onClose(false)}
          >
            <i className={classNames('icon-close', classes.crossIcon)} />
            <Typography variant="body1">Close</Typography>
          </button>
        )}
      </>
    );
  };

  return (
    <section className={classes.popupWrapper}>
      <div
        className={classNames(classes.popup, className, {
          [classes.editCase]: type === 'edit',
        })}
      >
        {popupCase()}
      </div>
    </section>
  );
};

export default FullscreenPopup;
