import { isDate, parse } from 'date-fns';
import * as Yup from 'yup';

import { dateInPast, isAdult } from './date-validation';

const parseDateString = (_value: unknown, originalValue: unknown) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue as string, 'dd / MM / yyyy', new Date());

  return parsedDate;
};

class Validator {
  public static required = Yup.string().required('Required');

  public static emailRequired = Yup.string()
    .email('Invalid email')
    .required('Required');

  public static letters = Yup.string().matches(
    /^[aA-zZ\s]+$/,
    'Should be only alphabets',
  );

  public static lettersRequired = Validator.letters.required('Required');

  public static birthdayYoung = Yup.date()
    .transform(parseDateString)
    .test('dateInPast', 'Invalid date', dateInPast)
    .typeError('Invalid date');

  public static birthday = Validator.birthdayYoung.test(
    'age',
    'You must be 18 or older',
    isAdult,
  );

  public static dateInPast = Yup.date()
    .transform(parseDateString)
    .test('dateInPast', 'Invalid date', dateInPast);

  public static birthdayRequired = Validator.birthday.required('Required');

  public static numbers = Yup.string()
    .required('Required')
    .matches(/^\d+$/, 'Should be only numbers');

  public static phone = Yup.string()
    .max(14, 'Phone must be at most 14 characters')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid',
    );

  public static phoneRequired = Validator.phone.required('Required');

  public static email = Yup.string().email('Invalid email');

  public static password = Yup.string()
    .required('Required')
    .min(8, 'Should be at least 8 characters.')
    .matches(/[a-zA-Z]/, 'Should include letter ')
    .matches(/[0-9]/, 'Should include number');

  public static postcode = Yup.string()
    .matches(/[a-zA-Z]/, 'Should include letter ')
    .matches(/[0-9]/, 'Should include number');

  public static postcodeRequired = Yup.string()
    .required('Required')
    .matches(/[a-zA-Z]/, 'Should include letter ')
    .matches(/[0-9]/, 'Should include number');
}

export default Validator;
