import { GetStaticProps, InferGetStaticPropsType } from 'next';

import Layout from '@components/common/layout';
import { REVALIDATE_TIMEOUT } from '@config/constants/meta-info';
import withMetaTags from '@hocs/with-meta-tags';
import { ArticlesCollectionObject } from '@lib/api';
import { MetaProps } from '@pages/_app';
import { BlogAPI, MetatagsAPI } from '@services/api';
import BlogView from '@views/blog';

export const BLOGS_PAGINATION = 20;

export type BlogsProps = InferGetStaticPropsType<typeof getStaticProps>;

export const getStaticProps: GetStaticProps<
  {
    articles?: ArticlesCollectionObject;
  } & MetaProps
> = async () => {
  try {
    const response = await Promise.all([
      MetatagsAPI.getMetaTags({
        slug: '/blog',
      }),
      BlogAPI.getArticles({ perPage: BLOGS_PAGINATION }),
    ]);

    return {
      props: {
        meta: response[0].data,
        articles: response[1].data,
      },
      revalidate: REVALIDATE_TIMEOUT,
    };
  } catch {
    return {
      props: {},
      revalidate: REVALIDATE_TIMEOUT,
    };
  }
};

const Blog: React.FC<BlogsProps> = (props) => {
  const { articles } = props;

  return (
    <Layout>
      <BlogView articles={articles} />
    </Layout>
  );
};

export default withMetaTags('blog')(Blog);
