/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  GuidesCollectionObject,
  GuidesCollectionObjectFromJSON,
  GuidesCollectionObjectFromJSONTyped,
  GuidesCollectionObjectToJSON,
} from './';

/**
 * Guide collection response object.
 * @export
 * @interface GuidesCollectionResponse
 */
export interface GuidesCollectionResponse {
  /**
   *
   * @type {Array<GuidesCollectionObject>}
   * @memberof GuidesCollectionResponse
   */
  data: Array<GuidesCollectionObject>;
}

export function GuidesCollectionResponseFromJSON(
  json: any,
): GuidesCollectionResponse {
  return GuidesCollectionResponseFromJSONTyped(json, false);
}

export function GuidesCollectionResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GuidesCollectionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(GuidesCollectionObjectFromJSON),
  };
}

export function GuidesCollectionResponseToJSON(
  value?: GuidesCollectionResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(GuidesCollectionObjectToJSON),
  };
}
