import { makeStyles } from '@material-ui/core/styles';

import transparentize from '@utils/transparentize';

const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    backgroundColor: theme.palette.background.paper,
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 40,
  },

  tag: {
    color: theme.palette.primary.dark,
    padding: '4px 16px',
    borderRadius: 8,
    width: 'fit-content',
    backgroundColor: transparentize(theme.palette.primary.dark, 0.1),

    '& span': {
      textTransform: 'uppercase',
      color: 'inherit',
      fontSize: 14,
    },
  },
  bigTag: {
    padding: '8px 24px',

    '& span': {
      textTransform: 'uppercase',
      color: 'inherit',
      fontSize: 16,
    },
  },
  topSection: {
    backgroundColor: theme.palette.background.paper,
    padding: '40px 0',
  },
  subtitle: {
    marginTop: 8,
  },
  articlesWrapper: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 56,

    '& > div': {
      display: 'grid',
      rowGap: 24,
    },
  },
  card: {
    backgroundColor: theme.palette.background.default,
    padding: 24,
    borderRadius: 24,
    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.1)',
  },
  articleImage: {
    marginBottom: 24,
    width: '100%',
    aspectRatio: '298/114',
    position: 'relative',
    borderRadius: 24,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,

    '& img': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '100%',
      height: 'auto',
    },
  },
  articleDate: {
    color: theme.palette.info.main,
  },
  articleText: {
    margin: '24px 0',
  },
  articleTitle: {
    marginBottom: 8,
  },
  authorBlock: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      marginRight: '0.5ch',
      width: 40,
      height: 40,
      borderRadius: '50%',
      overflow: 'hidden',
    },
  },
  authorText: {
    '& p': {
      fontSize: 14,
    },
  },
  [theme.breakpoints.up('sm')]: {
    topSection: {
      paddingTop: 56,
    },
    articlesWrapper: {
      paddingBottom: 80,
    },
    tag: {
      padding: '8px 24px',

      '& span': {
        textTransform: 'uppercase',
        color: 'inherit',
        fontSize: 16,
      },
    },
    card: {
      padding: 40,
      height: 'fit-content',
    },
    articleTitle: {
      fontSize: 24,
    },
    articleDate: {
      paddingLeft: '0.5ch',
      color: theme.palette.info.main,

      '&:before': {
        content: '"•"',
        paddingRight: '0.5ch',
      },
    },
    authorText: {
      display: 'flex',

      '& p': {
        fontSize: 16,

        '&:first-of-type': {
          fontWeight: 700,
        },
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    topSection: {
      padding: '80px 0',
    },
    title: {
      fontSize: 48,
    },
    articlesWrapper: {
      '& > div': {
        gridAutoFlow: 'dense',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'masonry',
        columnGap: 30,
        rowGap: 40,
      },
    },
  },
}));

export default useStyles;
