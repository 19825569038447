/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Set new password
 * @export
 * @interface SetNewPasswordData
 */
export interface SetNewPasswordData {
  /**
   *
   * @type {string}
   * @memberof SetNewPasswordData
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof SetNewPasswordData
   */
  token: string;
}

export function SetNewPasswordDataFromJSON(json: any): SetNewPasswordData {
  return SetNewPasswordDataFromJSONTyped(json, false);
}

export function SetNewPasswordDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SetNewPasswordData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    password: json['password'],
    token: json['token'],
  };
}

export function SetNewPasswordDataToJSON(
  value?: SetNewPasswordData | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    password: value.password,
    token: value.token,
  };
}
