import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  DocumentsResponse,
  GetWillRequest,
  WillResponse,
  GetLpaRequest,
  LpaResponse,
  UpdateLpaRequest,
  UpdateActorRequest,
  CreateActorRequest,
  DeleteActorRequest,
  CreatePetRequest,
  UpdatePetRequest,
  DeletePetRequest,
  CreateAssetRequest,
  AssetResponse,
  UpdateAssetRequest,
  DeleteAssetRequest,
  WillStepsRequest,
  CreateGiftRequest,
  GiftResponse,
  UpdateGiftRequest,
  DeleteGiftRequest,
  CreateCharitiesRequest,
  ActorDocumentResponse,
  CharityCollectionResponse,
  PurchaseWillRequest,
  PurchaseResponse,
  CheckCouponRequest,
  CouponResponse,
  InvitePartnerOperationRequest,
  MessageResponse,
  SpreadTheWordOperationRequest,
  CreatePreferenceRequest,
  PreferenceResponse,
  UpdatePreferenceRequest,
  SignWillOperationRequest,
  PurchaseLpasRequest,
  PricesResponse,
} from '@lib/api';
import {
  DocumentsAPI,
  WillsAPI,
  LpasAPI,
  ActorsAPI,
  PetsAPI,
  AssetsAPI,
  GiftsAPI,
  CharitiesAPI,
  StripeAPI,
  PreferencesAPI,
  PricesAPI,
} from '@services/api';
import { WithCallback } from '@store/index';

export const getDocuments = createAsyncThunk(
  'documents/getInfo',
  async (): Promise<DocumentsResponse> => {
    return DocumentsAPI.getDocuments();
  },
);

export const getWill = createAsyncThunk(
  'will/getInfo',
  async (requestParameters: GetWillRequest): Promise<WillResponse> => {
    return WillsAPI.getWill(requestParameters);
  },
);

export const updateWill = createAsyncThunk(
  'will/updateInfo',
  async (
    requestParameters: WithCallback<WillStepsRequest, WillResponse>,
  ): Promise<WillResponse> => {
    return WillsAPI.willSteps(requestParameters);
  },
);

export const purchaseWill = createAsyncThunk(
  'will/purchaseWill',
  async (
    requestParameters: WithCallback<PurchaseWillRequest>,
  ): Promise<PurchaseResponse> => {
    return WillsAPI.purchaseWill(requestParameters);
  },
);

export const invitePartner = createAsyncThunk(
  'will/invitePartner',
  async (
    requestParameters: WithCallback<InvitePartnerOperationRequest>,
  ): Promise<MessageResponse> => {
    return WillsAPI.invitePartner(requestParameters);
  },
);

export const spreadTheWord = createAsyncThunk(
  'will/spreadTheWord',
  async (
    requestParameters: WithCallback<SpreadTheWordOperationRequest>,
  ): Promise<MessageResponse> => {
    return WillsAPI.spreadTheWord(requestParameters);
  },
);

export const signWill = createAsyncThunk(
  'will/signWill',
  async (
    requestParameters: WithCallback<SignWillOperationRequest>,
  ): Promise<WillResponse> => {
    return WillsAPI.signWill(requestParameters);
  },
);

export const createActor = createAsyncThunk(
  'actor/createInfo',
  async (
    requestParameters: WithCallback<CreateActorRequest, ActorDocumentResponse>,
  ): Promise<ActorDocumentResponse> => {
    return ActorsAPI.createActor(requestParameters);
  },
);

export const updateActor = createAsyncThunk(
  'actor/updateInfo',
  async (
    requestParameters: WithCallback<UpdateActorRequest>,
  ): Promise<ActorDocumentResponse> => {
    return ActorsAPI.updateActor(requestParameters);
  },
);

export const deleteActor = createAsyncThunk(
  'actor/delete',
  async (
    requestParameters: WithCallback<DeleteActorRequest>,
  ): Promise<ActorDocumentResponse> => {
    return ActorsAPI.deleteActor(requestParameters);
  },
);

export const createPet = createAsyncThunk(
  'pet/createInfo',
  async (
    requestParameters: WithCallback<CreatePetRequest>,
  ): Promise<WillResponse> => {
    return PetsAPI.createPet(requestParameters);
  },
);

export const updatePet = createAsyncThunk(
  'pet/updateInfo',
  async (
    requestParameters: WithCallback<UpdatePetRequest>,
  ): Promise<WillResponse> => {
    return PetsAPI.updatePet(requestParameters);
  },
);

export const deletePet = createAsyncThunk(
  'pet/delete',
  async (
    requestParameters: WithCallback<DeletePetRequest>,
  ): Promise<WillResponse> => {
    return PetsAPI.deletePet(requestParameters);
  },
);

export const createAsset = createAsyncThunk(
  'asset/createAsset',
  async (
    requestParameters: WithCallback<CreateAssetRequest>,
  ): Promise<AssetResponse> => {
    return AssetsAPI.createAsset(requestParameters);
  },
);

export const updateAsset = createAsyncThunk(
  'asset/updateAsset',
  async (
    requestParameters: WithCallback<UpdateAssetRequest>,
  ): Promise<AssetResponse> => {
    return AssetsAPI.updateAsset(requestParameters);
  },
);

export const deleteAsset = createAsyncThunk(
  'asset/deleteAsset',
  async (
    requestParameters: WithCallback<DeleteAssetRequest>,
  ): Promise<void> => {
    return AssetsAPI.deleteAsset(requestParameters);
  },
);

export const createCharities = createAsyncThunk(
  'charity/createCharity',
  async (
    requestParameters: WithCallback<
      CreateCharitiesRequest,
      CharityCollectionResponse
    >,
  ): Promise<CharityCollectionResponse> => {
    return CharitiesAPI.createCharities(requestParameters);
  },
);

export const createGift = createAsyncThunk(
  'gift/createGift',
  async (
    requestParameters: WithCallback<CreateGiftRequest>,
  ): Promise<GiftResponse> => {
    return GiftsAPI.createGift(requestParameters);
  },
);

export const updateGift = createAsyncThunk(
  'gift/updateGift',
  async (
    requestParameters: WithCallback<UpdateGiftRequest>,
  ): Promise<GiftResponse> => {
    return GiftsAPI.updateGift(requestParameters);
  },
);

export const deleteGift = createAsyncThunk(
  'gift/deleteGift',
  async (requestParameters: WithCallback<DeleteGiftRequest>): Promise<void> => {
    return GiftsAPI.deleteGift(requestParameters);
  },
);

export const getLPA = createAsyncThunk(
  'lpa/getInfo',
  async (requestParameters: GetLpaRequest): Promise<LpaResponse> => {
    return LpasAPI.getLpa(requestParameters);
  },
);

export const updateLPA = createAsyncThunk(
  'lpa/updateInfo',
  async (
    requestParameters: WithCallback<UpdateLpaRequest>,
  ): Promise<LpaResponse> => {
    return LpasAPI.updateLpa(requestParameters);
  },
);

export const purchaseLPA = createAsyncThunk(
  'lpa/purchase',
  async (
    requestParameters: WithCallback<PurchaseLpasRequest>,
  ): Promise<PurchaseResponse> => {
    return LpasAPI.purchaseLpas(requestParameters);
  },
);

export const createPreference = createAsyncThunk(
  'preference/create',
  async (
    requestParameters: WithCallback<CreatePreferenceRequest>,
  ): Promise<PreferenceResponse> => {
    return PreferencesAPI.createPreference(requestParameters);
  },
);

export const updatePreference = createAsyncThunk(
  'preference/update',
  async (
    requestParameters: WithCallback<UpdatePreferenceRequest>,
  ): Promise<PreferenceResponse> => {
    return PreferencesAPI.updatePreference(requestParameters);
  },
);

export const checkDiscountCode = createAsyncThunk(
  'coupon/checkDiscountCode',
  async (
    requestParameters: WithCallback<CheckCouponRequest>,
  ): Promise<CouponResponse> => {
    return StripeAPI.checkCoupon(requestParameters);
  },
);

export const getPrices = createAsyncThunk(
  'price/getPrices',
  async (): Promise<PricesResponse> => {
    return PricesAPI.getPrices();
  },
);
