/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ActorDocumentObject,
  ActorDocumentObjectFromJSON,
  ActorDocumentObjectFromJSONTyped,
  ActorDocumentObjectToJSON,
} from './';

/**
 * Actor document response object.
 * @export
 * @interface ActorDocumentResponse
 */
export interface ActorDocumentResponse {
  /**
   *
   * @type {ActorDocumentObject}
   * @memberof ActorDocumentResponse
   */
  data: ActorDocumentObject;
}

export function ActorDocumentResponseFromJSON(
  json: any,
): ActorDocumentResponse {
  return ActorDocumentResponseFromJSONTyped(json, false);
}

export function ActorDocumentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActorDocumentResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: ActorDocumentObjectFromJSON(json['data']),
  };
}

export function ActorDocumentResponseToJSON(
  value?: ActorDocumentResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ActorDocumentObjectToJSON(value.data),
  };
}
