/* eslint-disable react/jsx-props-no-spreading */
import {
  FilledTextFieldProps,
  MenuItem,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import { AnyMaskedOptions } from 'imask';
import { ChangeEvent, useState } from 'react';
import { useIMask } from 'react-imask';

import useStyles from '@components/common/field/styles';

interface DropdownItems {
  value: string;
  label: string;
}

interface CustomTextFieldProps {
  errorMessage?: string | undefined;
  dropdownOptions?: DropdownItems[];
  showHidePassword?: boolean;
  searchIcon?: true;
  fieldClass?: string;
  maskOptions?: AnyMaskedOptions;
  redOutline?: true;
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;
}

type Props = CustomTextFieldProps &
  (StandardTextFieldProps | FilledTextFieldProps | OutlinedTextFieldProps);

const Field: React.FC<Props> = (props) => {
  const {
    type,
    label,
    placeholder,
    variant = 'filled',
    value,
    inputRef,
    errorMessage,
    dropdownOptions,
    showHidePassword,
    searchIcon,
    fieldClass,
    redOutline,
    maskOptions = {
      mask: '',
    },
    ...rest
  } = props;

  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const { ref } = useIMask(maskOptions);

  const handleShowHidePassword = () => {
    if (showHidePassword) {
      if (showPassword) {
        return 'text';
      }

      return 'password';
    }

    return type;
  };

  const icon = () => (
    <i className={classNames(classes.downIcon, 'icon-arrow')} />
  );

  const setInputRef = (elementRef: HTMLInputElement) => {
    ref.current = elementRef;

    if (inputRef && 'current' in inputRef) {
      inputRef.current = elementRef;
    }
  };

  return (
    <div className={classNames(classes.fieldWrapper, rest.className)}>
      {variant === 'outlined' ? (
        <TextField
          {...rest}
          variant="outlined"
          type={handleShowHidePassword()}
          label={typeof errorMessage === 'string' ? errorMessage : label}
          className={classNames(classes.field, fieldClass, {
            [classes.error]: errorMessage || redOutline,
          })}
          inputRef={setInputRef}
          value={value}
          error={!!errorMessage}
          SelectProps={{
            IconComponent: icon,
          }}
        >
          {dropdownOptions?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          {...rest}
          variant={variant}
          type={handleShowHidePassword()}
          label={typeof errorMessage === 'string' ? errorMessage : label}
          className={classNames(classes.field, fieldClass, {
            [classes.error]: errorMessage || redOutline,
            [classes.searchIcon]: searchIcon,
          })}
          inputRef={setInputRef}
          value={value}
          error={!!errorMessage}
          InputProps={{
            disableUnderline: true,
          }}
          onInput={
            maskOptions.mask !== ''
              ? (e) => {
                  const event = e as ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >;

                  rest.onChange?.(event);
                }
              : undefined
          }
          placeholder={placeholder || (errorMessage && label?.toString())}
          SelectProps={{
            IconComponent: icon,
          }}
        >
          {dropdownOptions?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      )}

      {showHidePassword && value && (
        <button
          type="button"
          className={classes.eye}
          onClick={() => setShowPassword(!showPassword)}
        >
          <i className="icon-eye" />
          <span
            className={classNames(classes.crossLine, {
              [classes.crossedLineActive]: showPassword,
            })}
          />
        </button>
      )}
    </div>
  );
};

export default Field;
