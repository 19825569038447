export const isAdult = (birthday?: Date): boolean => {
  const cutoff = new Date();

  cutoff.setFullYear(cutoff.getFullYear() - 18);

  if (birthday) return birthday <= cutoff;

  return true;
};

export const dateInPast = (date?: Date): boolean => {
  const dateNow = new Date();

  if (date) {
    const userDate = new Date(date);

    return userDate < dateNow;
  }

  return true;
};

export const isUnder18 = (birthday?: Date): boolean => {
  const cutoff = new Date();

  cutoff.setFullYear(cutoff.getFullYear() - 18);

  if (birthday) return birthday >= cutoff;

  return false;
};
