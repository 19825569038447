/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ArticleCollectionResponse,
  ArticleCollectionResponseFromJSON,
  ArticleCollectionResponseToJSON,
  ArticleResponse,
  ArticleResponseFromJSON,
  ArticleResponseToJSON,
  BlogCollectionResponse,
  BlogCollectionResponseFromJSON,
  BlogCollectionResponseToJSON,
} from '../models';

export interface GetArticlesRequest {
  perPage: number;
  tag?: string;
}

export interface GetOneArticleRequest {
  article: number;
}

/**
 *
 */
export class BlogApi extends runtime.BaseAPI {
  /**
   * Get articles.
   */
  async getArticlesRaw(
    requestParameters: GetArticlesRequest,
  ): Promise<runtime.ApiResponse<ArticleCollectionResponse>> {
    if (
      requestParameters.perPage === null ||
      requestParameters.perPage === undefined
    ) {
      throw new runtime.RequiredError(
        'perPage',
        'Required parameter requestParameters.perPage was null or undefined when calling getArticles.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.tag !== undefined) {
      queryParameters['tag'] = requestParameters.tag;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/articles`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ArticleCollectionResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get articles.
   */
  async getArticles(
    requestParameters: GetArticlesRequest,
  ): Promise<ArticleCollectionResponse> {
    const response = await this.getArticlesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get blogs.
   */
  async getBlogsRaw(): Promise<runtime.ApiResponse<BlogCollectionResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/blogs`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BlogCollectionResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get blogs.
   */
  async getBlogs(): Promise<BlogCollectionResponse> {
    const response = await this.getBlogsRaw();
    return await response.value();
  }

  /**
   * Get one article.
   */
  async getOneArticleRaw(
    requestParameters: GetOneArticleRequest,
  ): Promise<runtime.ApiResponse<ArticleResponse>> {
    if (
      requestParameters.article === null ||
      requestParameters.article === undefined
    ) {
      throw new runtime.RequiredError(
        'article',
        'Required parameter requestParameters.article was null or undefined when calling getOneArticle.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/articles/{article}`.replace(
        `{${'article'}}`,
        encodeURIComponent(String(requestParameters.article)),
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ArticleResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get one article.
   */
  async getOneArticle(
    requestParameters: GetOneArticleRequest,
  ): Promise<ArticleResponse> {
    const response = await this.getOneArticleRaw(requestParameters);
    return await response.value();
  }
}
