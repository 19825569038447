/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DocumentSteps,
  DocumentStepsFromJSON,
  DocumentStepsFromJSONTyped,
  DocumentStepsToJSON,
} from './';

/**
 * Will steps object.
 * @export
 * @interface WillStepsObject
 */
export interface WillStepsObject {
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsObject
   */
  about: DocumentSteps;
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsObject
   */
  assets: DocumentSteps;
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsObject
   */
  estate: DocumentSteps;
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsObject
   */
  executors: DocumentSteps;
  /**
   *
   * @type {DocumentSteps}
   * @memberof WillStepsObject
   */
  guardians: DocumentSteps;
}

export function WillStepsObjectFromJSON(json: any): WillStepsObject {
  return WillStepsObjectFromJSONTyped(json, false);
}

export function WillStepsObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WillStepsObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    about: DocumentStepsFromJSON(json['about']),
    assets: DocumentStepsFromJSON(json['assets']),
    estate: DocumentStepsFromJSON(json['estate']),
    executors: DocumentStepsFromJSON(json['executors']),
    guardians: DocumentStepsFromJSON(json['guardians']),
  };
}

export function WillStepsObjectToJSON(value?: WillStepsObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    about: DocumentStepsToJSON(value.about),
    assets: DocumentStepsToJSON(value.assets),
    estate: DocumentStepsToJSON(value.estate),
    executors: DocumentStepsToJSON(value.executors),
    guardians: DocumentStepsToJSON(value.guardians),
  };
}
