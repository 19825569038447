/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Blog object.
 * @export
 * @interface BlogObject
 */
export interface BlogObject {
  /**
   *
   * @type {number}
   * @memberof BlogObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BlogObject
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof BlogObject
   */
  updatedAt: string;
}

export function BlogObjectFromJSON(json: any): BlogObject {
  return BlogObjectFromJSONTyped(json, false);
}

export function BlogObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BlogObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    updatedAt: json['updated_at'],
  };
}

export function BlogObjectToJSON(value?: BlogObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    updated_at: value.updatedAt,
  };
}
