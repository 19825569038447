/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
  PreferenceCreateRequest,
  PreferenceCreateRequestFromJSON,
  PreferenceCreateRequestToJSON,
  PreferenceResponse,
  PreferenceResponseFromJSON,
  PreferenceResponseToJSON,
  PreferenceUpdateRequest,
  PreferenceUpdateRequestFromJSON,
  PreferenceUpdateRequestToJSON,
} from '../models';

export interface CreatePreferenceRequest {
  preferenceCreateRequest: PreferenceCreateRequest;
}

export interface DeletePreferenceRequest {
  preference: number;
}

export interface UpdatePreferenceRequest {
  preference: number;
  preferenceUpdateRequest: PreferenceUpdateRequest;
}

/**
 *
 */
export class PreferencesApi extends runtime.BaseAPI {
  /**
   * Create preference.
   */
  async createPreferenceRaw(
    requestParameters: CreatePreferenceRequest,
  ): Promise<runtime.ApiResponse<PreferenceResponse>> {
    if (
      requestParameters.preferenceCreateRequest === null ||
      requestParameters.preferenceCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'preferenceCreateRequest',
        'Required parameter requestParameters.preferenceCreateRequest was null or undefined when calling createPreference.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/preferences`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PreferenceCreateRequestToJSON(
        requestParameters.preferenceCreateRequest,
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PreferenceResponseFromJSON(jsonValue),
    );
  }

  /**
   * Create preference.
   */
  async createPreference(
    requestParameters: CreatePreferenceRequest,
  ): Promise<PreferenceResponse> {
    const response = await this.createPreferenceRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete preference.
   */
  async deletePreferenceRaw(
    requestParameters: DeletePreferenceRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.preference === null ||
      requestParameters.preference === undefined
    ) {
      throw new runtime.RequiredError(
        'preference',
        'Required parameter requestParameters.preference was null or undefined when calling deletePreference.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/preferences/{preference}`.replace(
        `{${'preference'}}`,
        encodeURIComponent(String(requestParameters.preference)),
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete preference.
   */
  async deletePreference(
    requestParameters: DeletePreferenceRequest,
  ): Promise<void> {
    await this.deletePreferenceRaw(requestParameters);
  }

  /**
   * Update preference.
   */
  async updatePreferenceRaw(
    requestParameters: UpdatePreferenceRequest,
  ): Promise<runtime.ApiResponse<PreferenceResponse>> {
    if (
      requestParameters.preference === null ||
      requestParameters.preference === undefined
    ) {
      throw new runtime.RequiredError(
        'preference',
        'Required parameter requestParameters.preference was null or undefined when calling updatePreference.',
      );
    }

    if (
      requestParameters.preferenceUpdateRequest === null ||
      requestParameters.preferenceUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'preferenceUpdateRequest',
        'Required parameter requestParameters.preferenceUpdateRequest was null or undefined when calling updatePreference.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/preferences/{preference}`.replace(
        `{${'preference'}}`,
        encodeURIComponent(String(requestParameters.preference)),
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: PreferenceUpdateRequestToJSON(
        requestParameters.preferenceUpdateRequest,
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PreferenceResponseFromJSON(jsonValue),
    );
  }

  /**
   * Update preference.
   */
  async updatePreference(
    requestParameters: UpdatePreferenceRequest,
  ): Promise<PreferenceResponse> {
    const response = await this.updatePreferenceRaw(requestParameters);
    return await response.value();
  }
}
