/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CharityCollectionResponse,
  CharityCollectionResponseFromJSON,
  CharityCollectionResponseToJSON,
  CharityRequest,
  CharityRequestFromJSON,
  CharityRequestToJSON,
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
} from '../models';

export interface CreateCharitiesRequest {
  charityRequest: Array<CharityRequest>;
}

/**
 *
 */
export class CharitiesApi extends runtime.BaseAPI {
  /**
   * Create charities.
   */
  async createCharitiesRaw(
    requestParameters: CreateCharitiesRequest,
  ): Promise<runtime.ApiResponse<CharityCollectionResponse>> {
    if (
      requestParameters.charityRequest === null ||
      requestParameters.charityRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'charityRequest',
        'Required parameter requestParameters.charityRequest was null or undefined when calling createCharities.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/charities`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.charityRequest.map(CharityRequestToJSON),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CharityCollectionResponseFromJSON(jsonValue),
    );
  }

  /**
   * Create charities.
   */
  async createCharities(
    requestParameters: CreateCharitiesRequest,
  ): Promise<CharityCollectionResponse> {
    const response = await this.createCharitiesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get charities.
   */
  async getCharitiesRaw(): Promise<
    runtime.ApiResponse<CharityCollectionResponse>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/charities`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CharityCollectionResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get charities.
   */
  async getCharities(): Promise<CharityCollectionResponse> {
    const response = await this.getCharitiesRaw();
    return await response.value();
  }
}
