/* eslint-disable no-param-reassign */
import {
  createSelector,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  ActorRoles,
  DocumentProgress,
  DocumentsObject,
  DocumentTypes,
  PricesCollectionObject,
  PricesObject,
} from '@lib/api';
import Toast from '@services/toast';
import { logOut } from '@store/auth';
import { RootState } from '@store/index';
import { isUnder18 } from '@utils/validation/date-validation';

import {
  getDocuments,
  getLPA,
  getWill,
  createActor,
  updateActor,
  updateLPA,
  deleteActor,
  createPet,
  updatePet,
  deletePet,
  createAsset,
  updateAsset,
  deleteAsset,
  updateWill,
  createGift,
  updateGift,
  deleteGift,
  createCharities,
  checkDiscountCode,
  invitePartner,
  spreadTheWord,
  purchaseWill,
  createPreference,
  updatePreference,
  signWill,
  purchaseLPA,
  getPrices,
} from './thunks';

export enum ActorRolesId {
  Owner = 1,
  Partner = 2,
  Child = 3,
  Beneficiary = 4,
  Guardian = 5,
  Executor = 6,
  HealthAttorney = 7,
  HealthCertificateProvider = 8,
  FinanceAttorney = 9,
  FinanceCertificateProvider = 10,
  FinanceLPAapplier = 11,
  HealthLPAapplier = 12,
  FinanceLPAReceiver = 13,
  HealthLPAReceiver = 14,
  HealthPersonToNotify = 15,
  FinancePersonToNotify = 16,
  HealthReplacementAttorney = 17,
  FinanceReplacementAttorney = 18,
}

export type FetchStatus = 'draft' | 'pending' | 'fulfilled' | 'rejected';

export interface PurchaseProgress {
  partnerEmail?: string;
  paymentMethodId?: string;
  priceStatus?: PricesObject;
  unlimitedUpdates?: boolean;
}

export interface DocumentsState extends Partial<DocumentsObject> {
  discountPercentage: {
    discount?: number;
    code?: string;
  };
  error: string | undefined;
  prices?: PricesCollectionObject;
  purchaseProgress: PurchaseProgress;
  status: FetchStatus;
}

const initialState: DocumentsState = {
  status: 'draft',
  error: undefined,
  prices: undefined,
  discountPercentage: {
    discount: undefined,
    code: undefined,
  },
  purchaseProgress: {
    paymentMethodId: undefined,
    priceStatus: undefined,
    unlimitedUpdates: true,
    partnerEmail: undefined,
  },
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'draft';
    },
    resetDiscountCode: (state) => {
      state.discountPercentage = {
        discount: undefined,
        code: undefined,
      };
    },
    setPurchaseProgress: (
      state,
      { payload }: PayloadAction<PurchaseProgress>,
    ) => {
      state.purchaseProgress = {
        ...state.purchaseProgress,
        ...payload,
      };
    },
    resetPurchaseProgress: (state) => {
      state.purchaseProgress = {
        paymentMethodId: undefined,
        priceStatus: undefined,
        unlimitedUpdates: true,
        partnerEmail: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logOut, () => {
      return initialState;
    });
    builder.addCase(getDocuments.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload.data,
      };
    });

    builder.addCase(getWill.fulfilled, (state, { payload }) => {
      state.will = payload.data;
    });
    builder.addCase(updateWill.fulfilled, (state, { meta, payload }) => {
      state.will = payload.data;

      meta.arg.fulfilledCallback?.(payload);
    });
    builder.addCase(purchaseWill.fulfilled, (state, { meta }) => {
      if (state.will) {
        state.will.progress = DocumentProgress.Purchased;
      }

      meta.arg.fulfilledCallback?.();
    });
    builder.addCase(spreadTheWord.fulfilled, (_state, { meta }) => {
      meta.arg.fulfilledCallback?.();
    });
    builder.addCase(signWill.fulfilled, (state, { meta, payload }) => {
      state.will = payload.data;

      meta.arg.fulfilledCallback?.();
    });

    builder.addCase(updateLPA.fulfilled, (_state, { meta }) => {
      meta.arg.fulfilledCallback?.();
    });

    builder.addCase(createActor.fulfilled, (state, { meta, payload }) => {
      if (payload.data.will) state.will = payload.data.will;

      if (payload.data.lpa)
        state[
          payload.data.lpa.type === DocumentTypes.FinanceLpa
            ? 'financeLpa'
            : 'healthLpa'
        ] = payload.data.lpa;

      meta.arg.fulfilledCallback?.(payload);
    });

    builder.addCase(invitePartner.fulfilled, (_state, { meta }) => {
      meta.arg.fulfilledCallback?.();
    });

    builder.addCase(createAsset.fulfilled, (state, { meta, payload }) => {
      if (state.will) {
        state.will.assets = [...(state.will.assets || []), payload.data];
        state.will.noAssets = false;
      }

      meta.arg.fulfilledCallback?.();
    });
    builder.addCase(updateAsset.fulfilled, (state, { meta, payload }) => {
      if (state.will?.assets) {
        state.will.assets[
          state.will.assets?.findIndex((asset) => asset.id === payload.data.id)
        ] = payload.data;
      }

      meta.arg.fulfilledCallback?.();
    });
    builder.addCase(deleteAsset.fulfilled, (state, { meta }) => {
      if (state.will) {
        const assetID = meta.arg.asset;

        state.will.assets =
          state.will?.assets?.filter((asset) => asset.id !== assetID) || [];
      }

      meta.arg.fulfilledCallback?.();
    });

    builder.addCase(createCharities.fulfilled, (state, { meta, payload }) => {
      if (state.will) {
        state.will.charities = [
          ...(state.will.charities || []),
          ...payload.data,
        ];
      }

      meta.arg.fulfilledCallback?.(payload);
    });

    builder.addCase(createGift.fulfilled, (state, { meta, payload }) => {
      if (state.will) {
        state.will.gifts = [...(state.will.gifts || []), payload.data];
      }

      meta.arg.fulfilledCallback?.();
    });
    builder.addCase(updateGift.fulfilled, (state, { meta, payload }) => {
      if (state.will?.gifts) {
        state.will.gifts[
          state.will.gifts?.findIndex((gift) => gift.id === payload.data.id)
        ] = payload.data;
      }

      meta.arg.fulfilledCallback?.();
    });
    builder.addCase(deleteGift.fulfilled, (state, { meta }) => {
      if (state.will) {
        const giftID = meta.arg.gift;

        state.will.gifts =
          state.will?.gifts?.filter((gift) => gift.id !== giftID) || [];
      }

      meta.arg.fulfilledCallback?.();
    });

    builder.addCase(checkDiscountCode.fulfilled, (state, { meta, payload }) => {
      state.discountPercentage.discount = payload.data.discount;
      state.discountPercentage.code = meta.arg.couponRequest.coupon;

      meta.arg.fulfilledCallback?.();
    });

    builder.addCase(createPreference.fulfilled, (state, { meta, payload }) => {
      const { documentId } = meta.arg.preferenceCreateRequest;

      if (state.financeLpa?.id === documentId) {
        state.financeLpa.preferences = [
          ...(state.financeLpa.preferences || []),
          payload.data,
        ];
      } else if (state.healthLpa?.id === documentId) {
        state.healthLpa.preferences = [
          ...(state.healthLpa.preferences || []),
          payload.data,
        ];
      }

      meta.arg.fulfilledCallback?.();
    });

    builder.addCase(updatePreference.fulfilled, (state, { payload }) => {
      const financePreference = state.financeLpa?.preferences?.find(
        (pref) => pref.id === payload.data.id,
      );
      const healthPreference = state.healthLpa?.preferences?.find(
        (pref) => pref.id === payload.data.id,
      );

      if (financePreference) {
        financePreference.name = payload.data.name;
      } else if (healthPreference) {
        healthPreference.name = payload.data.name;
      }
    });

    builder.addCase(purchaseLPA.fulfilled, (state, { meta }) => {
      if (
        meta.arg.purchaseRequest.priceStatus === PricesObject.BothLpas &&
        state.financeLpa &&
        state.healthLpa
      ) {
        state.financeLpa.purchasedAt = new Date().toISOString();
        state.healthLpa.purchasedAt = new Date().toISOString();
      }

      if (state?.financeLpa?.id === meta.arg.document) {
        state.financeLpa.progress = DocumentProgress.Purchased;
        state.financeLpa.purchasedAt = new Date().toISOString();
      } else if (state.healthLpa) {
        state.healthLpa.progress = DocumentProgress.Purchased;
        state.healthLpa.purchasedAt = new Date().toISOString();
      }

      meta.arg.fulfilledCallback?.();
    });

    builder.addCase(getPrices.fulfilled, (state, { payload }) => {
      state.prices = payload.data;
    });

    builder.addMatcher(isFulfilled(getLPA, updateLPA), (state, { payload }) => {
      if (payload.data.type === DocumentTypes.FinanceLpa) {
        state.financeLpa = payload.data;
      } else if (payload.data.type === DocumentTypes.HealthLpa) {
        state.healthLpa = payload.data;
      }
    });

    builder.addMatcher(
      isPending(
        getDocuments,
        getWill,
        updateWill,
        getLPA,
        updateLPA,
        createActor,
        updateActor,
        deleteActor,
        createPet,
        updatePet,
        deletePet,
        createAsset,
        updateAsset,
        deleteAsset,
        createCharities,
        createGift,
        updateGift,
        deleteGift,
        checkDiscountCode,
        spreadTheWord,
        purchaseWill,
        createPreference,
        updatePreference,
        signWill,
        purchaseLPA,
        getPrices,
      ),
      (state) => {
        state.status = 'pending';
      },
    );

    builder.addMatcher(
      isFulfilled(updateActor, deleteActor),
      (state, { meta, payload }) => {
        if (payload.data.will) state.will = payload.data.will;

        if (payload.data.lpa)
          state[
            payload.data.lpa.type === DocumentTypes.FinanceLpa
              ? 'financeLpa'
              : 'healthLpa'
          ] = payload.data.lpa;

        meta.arg.fulfilledCallback?.();
      },
    );

    builder.addMatcher(
      isFulfilled(createPet, updatePet, deletePet),
      (state, { meta, payload }) => {
        if (state.will) {
          state.will = payload.data;
        }

        meta.arg.fulfilledCallback?.();
      },
    );

    builder.addMatcher(
      isFulfilled(
        getDocuments,
        getWill,
        updateWill,
        getLPA,
        updateLPA,
        updateActor,
        createActor,
        deleteActor,
        createPet,
        updatePet,
        deletePet,
        createAsset,
        updateAsset,
        deleteAsset,
        createCharities,
        createGift,
        updateGift,
        deleteGift,
        checkDiscountCode,
        spreadTheWord,
        purchaseWill,
        createPreference,
        updatePreference,
        signWill,
        purchaseLPA,
        getPrices,
      ),
      (state) => {
        state.status = 'fulfilled';
      },
    );
    builder.addMatcher(
      isRejected(
        getDocuments,
        getWill,
        updateWill,
        getLPA,
        updateLPA,
        createActor,
        updateActor,
        deleteActor,
        createPet,
        updatePet,
        deletePet,
        createAsset,
        updateAsset,
        deleteAsset,
        createCharities,
        createGift,
        updateGift,
        deleteGift,
        checkDiscountCode,
        spreadTheWord,
        purchaseWill,
        createPreference,
        updatePreference,
        signWill,
        purchaseLPA,
        getPrices,
      ),
      (state, { meta, error }) => {
        state.status = 'rejected';
        state.error = error.message;

        if (meta.arg && 'rejectedCallback' in meta.arg) {
          meta.arg.rejectedCallback?.();
        }

        Toast.error(error.message || 'Something went wrong');
      },
    );
  },
});

export const {
  resetStatus,
  setPurchaseProgress,
  resetDiscountCode,
  resetPurchaseProgress,
} = documentsSlice.actions;

const selectAdults = (state: RootState) => state.documents.will?.adultActors;
const selectChildren = (state: RootState) => state.documents.will?.children;
const selectPets = (state: RootState) => state.documents.will?.pets;

export const getMinorChildren = createSelector([selectChildren], (children) => {
  const minorChildren =
    children?.filter((child) =>
      isUnder18(child.dateOfBirth ? new Date(child.dateOfBirth) : undefined),
    ) || [];

  return minorChildren;
});

export const getGuardiansFor = createSelector(
  [getMinorChildren, selectPets],
  (children, pets) => {
    const mappedChildren =
      children.map((child) => ({
        id: child.id,
        name: child.firstName,
        guardians: child.guardians,
        type: 'child' as const,
      })) || [];

    const mappedPets =
      pets?.map((pet) => ({
        id: pet.id,
        name: pet.name,
        guardians: pet.guardians,
        type: 'pet' as const,
      })) || [];

    return [...mappedChildren, ...mappedPets];
  },
);

export const selectAllActors = createSelector(
  [selectAdults, getMinorChildren],
  (adults, children) => {
    if (adults?.length && adults[0].roles.includes(ActorRoles.Partner)) {
      return [adults[0], ...children, ...adults.slice(1)];
    }

    return [...children, ...(adults || [])];
  },
);

const documentsReducer = documentsSlice.reducer;

const persistConfig: PersistConfig<DocumentsState> = {
  key: 'documents',
  storage,
  blacklist: ['status', 'error', 'discountPercentage'],
};

export default persistReducer(persistConfig, documentsReducer);
