/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UserObject,
  UserObjectFromJSON,
  UserObjectFromJSONTyped,
  UserObjectToJSON,
} from './';

/**
 * Response token.
 * @export
 * @interface UserToken
 */
export interface UserToken {
  /**
   *
   * @type {string}
   * @memberof UserToken
   */
  token: string;
  /**
   *
   * @type {UserObject}
   * @memberof UserToken
   */
  user: UserObject;
}

export function UserTokenFromJSON(json: any): UserToken {
  return UserTokenFromJSONTyped(json, false);
}

export function UserTokenFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserToken {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: json['token'],
    user: UserObjectFromJSON(json['user']),
  };
}

export function UserTokenToJSON(value?: UserToken | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
    user: UserObjectToJSON(value.user),
  };
}
