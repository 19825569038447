/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssetTypes,
  AssetTypesFromJSON,
  AssetTypesFromJSONTyped,
  AssetTypesToJSON,
} from './';

/**
 * Asset request object for create.
 * @export
 * @interface AssetCreateRequest
 */
export interface AssetCreateRequest {
  /**
   *
   * @type {number}
   * @memberof AssetCreateRequest
   */
  documentId: number;
  /**
   *
   * @type {string}
   * @memberof AssetCreateRequest
   */
  name: string;
  /**
   *
   * @type {AssetTypes}
   * @memberof AssetCreateRequest
   */
  type: AssetTypes;
}

export function AssetCreateRequestFromJSON(json: any): AssetCreateRequest {
  return AssetCreateRequestFromJSONTyped(json, false);
}

export function AssetCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AssetCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    documentId: json['document_id'],
    name: json['name'],
    type: AssetTypesFromJSON(json['type']),
  };
}

export function AssetCreateRequestToJSON(
  value?: AssetCreateRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    document_id: value.documentId,
    name: value.name,
    type: AssetTypesToJSON(value.type),
  };
}
