/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Spread the word request object
 * @export
 * @interface SpreadTheWordRequest
 */
export interface SpreadTheWordRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof SpreadTheWordRequest
   */
  actors?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof SpreadTheWordRequest
   */
  email?: string;
}

export function SpreadTheWordRequestFromJSON(json: any): SpreadTheWordRequest {
  return SpreadTheWordRequestFromJSONTyped(json, false);
}

export function SpreadTheWordRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SpreadTheWordRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    actors: !exists(json, 'actors') ? undefined : json['actors'],
    email: !exists(json, 'email') ? undefined : json['email'],
  };
}

export function SpreadTheWordRequestToJSON(
  value?: SpreadTheWordRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    actors: value.actors,
    email: value.email,
  };
}
