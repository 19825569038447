import {
  Button as MaterialUIButton,
  Typography,
  Link as MaterialUiLink,
  ClickAwayListener,
  Container,
} from '@material-ui/core';
import classNames from 'classnames';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useStyles from '@components/common/header/styles';
import useTypedSelector from '@hooks/use-typed-selector';
import Logo from '@public/svg/simp-will.svg';
import { logOut } from '@store/auth';
import idNormalize from '@utils/id-normalize';

import Button from '../button';

interface Props {
  goBackLink?: string;
}

const navigationLinksNotLogged = [
  {
    text: 'Write a will online',
    href: '/create-will',
  },
  {
    text: 'Lasting power of attorney',
    href: '/lpas',
  },
  {
    text: 'Probate',
    href: '/probate',
  },
  // {
  //   text: 'Funerals',
  //   href: '/arrange-funeral',
  // },
  {
    text: 'Guides',
    href: '/guides',
  },
  {
    text: 'Blog',
    href: '/blog',
  },
];

const navigationLinksLogged = [
  {
    text: 'Documents',
    href: '/documents',
  },
  {
    text: 'Invite',
    href: '/invite',
  },
  {
    text: 'My profile',
    href: '/profile',
  },
  {
    text: 'My history',
    href: '/history',
  },
  {
    text: 'Edit people',
    href: '/edit-people',
  },
  {
    text: 'Simpwill guides',
    href: '/guides',
  },
];

const Header: React.FC<Props> = (props) => {
  const { goBackLink } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const { token, user } = useTypedSelector((state) => state.auth);

  const logoutButtonHidden = !!(
    user?.singleWillPrice ||
    user?.jointWillPrice ||
    user?.oneLpaPrice ||
    user?.bothLpasPrice
  );

  const [openedNav, setOpenedNav] = useState(false);
  const [openedDropdown, setOpenedDropdown] = useState(false);

  const isUserLoggedLinks = token
    ? navigationLinksLogged
    : navigationLinksNotLogged;

  useEffect(() => {
    if (!openedNav) {
      document.documentElement.classList.remove(classes.disableScroll);
      document.body.classList.remove(classes.disableScroll);
    } else {
      document.documentElement.classList.add(classes.disableScroll);
      document.body.classList.add(classes.disableScroll);
    }

    return () => {
      document.documentElement.classList.remove(classes.disableScroll);
      document.body.classList.remove(classes.disableScroll);
    };
  }, [openedNav, classes.disableScroll]);

  const mobileLinks = isUserLoggedLinks.map((link) => (
    <Fragment key={link.text}>
      <Link href={link.href} passHref>
        <MaterialUiLink
          id={`${idNormalize(link.text)}-mobile-link-header`}
          className={classes.mobileLink}
        >
          <Typography variant="body1">{link.text}</Typography>
        </MaterialUiLink>
      </Link>
    </Fragment>
  ));

  const userNotLoggedDesktopLinks = () => {
    const willDropdown = navigationLinksNotLogged.slice(0, 2);
    const otherLinks = navigationLinksNotLogged.slice(2);

    return (
      <>
        <div className={classes.linksWrapper}>
          <ClickAwayListener onClickAway={() => setOpenedDropdown(false)}>
            <div
              className={classes.dropdownWrapper}
              onFocus={() => setOpenedDropdown(true)}
              onMouseOver={() => setOpenedDropdown(true)}
              onMouseLeave={() => setOpenedDropdown(false)}
            >
              <button
                id="open-dropdown-button-user-not-logged-header"
                type="button"
                onClick={() => setOpenedDropdown(!openedDropdown)}
                className={classes.willDropdown}
              >
                <Typography variant="body1">Wills</Typography>
                <i
                  className={classNames(
                    'icon-arrow-bold',
                    classes.dropDownArrow,
                    {
                      [classes.invert]: openedDropdown,
                    },
                  )}
                />
              </button>

              <div
                className={classNames(classes.dropdown, {
                  [classes.open]: openedDropdown,
                })}
              >
                {willDropdown.map((link) => (
                  <Fragment key={link.text}>
                    <Link href={link.href} passHref>
                      <MaterialUiLink id={idNormalize(link.text)}>
                        <Typography variant="body1">{link.text}</Typography>
                      </MaterialUiLink>
                    </Link>
                  </Fragment>
                ))}
              </div>
            </div>
          </ClickAwayListener>
          {otherLinks.map((link) => (
            <Fragment key={link.text}>
              <Link href={link.href} passHref>
                <MaterialUiLink
                  id={`${link.text.toLocaleLowerCase()}-desktop-link-header`}
                  className={classes.desktopLink}
                >
                  <Typography variant="body1">{link.text}</Typography>
                </MaterialUiLink>
              </Link>
            </Fragment>
          ))}
        </div>
        <Button
          id="sign-in-button-header"
          styleType="containedPink"
          text="Sign in"
          href="/sign-in"
          arrow="rightSide"
        />
      </>
    );
  };

  const userLoggedDesktopLinks = () => {
    const dropDownLinks = navigationLinksLogged.slice(2);
    const otherLinks = navigationLinksLogged.slice(0, 2);

    return (
      <div className={classNames(classes.linksWrapper, classes.linksReverse)}>
        <ClickAwayListener onClickAway={() => setOpenedDropdown(false)}>
          <div
            className={classes.dropdownWrapper}
            onFocus={() => setOpenedDropdown(true)}
            onMouseOver={() => setOpenedDropdown(true)}
            onMouseLeave={() => setOpenedDropdown(false)}
          >
            <button
              id="open-dropdown-button-user-logged-header"
              type="button"
              onClick={() => setOpenedDropdown(!openedDropdown)}
              className={classes.willDropdown}
            >
              <Typography variant="body1">
                <i className={classNames('icon-user', classes.userIcon)} />
                {user && `${user.firstName} ${user.lastName}`}
              </Typography>
              <i
                className={classNames(
                  'icon-arrow-bold',
                  classes.dropDownArrow,
                  {
                    [classes.invert]: openedDropdown,
                  },
                )}
              />
            </button>

            <div
              className={classNames(classes.dropdown, {
                [classes.open]: openedDropdown,
              })}
            >
              {dropDownLinks.map((link) => (
                <Fragment key={link.text}>
                  <Link href={link.href} passHref>
                    <MaterialUiLink
                      id={`${idNormalize(link.text)}-desktop-link`}
                    >
                      <Typography variant="body1">{link.text}</Typography>
                    </MaterialUiLink>
                  </Link>
                </Fragment>
              ))}
              {!logoutButtonHidden && (
                <button
                  id="log-out-button-desktop"
                  onClick={() => {
                    dispatch(logOut());
                    setOpenedDropdown(false);
                  }}
                  type="button"
                  className={classes.logOutButton}
                >
                  <Typography variant="body1">Log out</Typography>
                </button>
              )}
            </div>
          </div>
        </ClickAwayListener>
        <div>
          {otherLinks.map((link) => (
            <Fragment key={link.text}>
              <Link href={link.href} passHref>
                <MaterialUiLink
                  id={`${link.text.toLocaleLowerCase()}-desktop-link`}
                  className={classNames(classes.desktopLink, {
                    [classes.inviteLink]: link.text === 'Invite',
                  })}
                >
                  <Typography variant="body1">{link.text}</Typography>
                </MaterialUiLink>
              </Link>
            </Fragment>
          ))}
        </div>
      </div>
    );
  };

  return (
    <header
      className={classNames(classes.header, {
        [classes.userLogged]: token,
      })}
    >
      <Container maxWidth={false} className={classes.headerContainer}>
        <nav className={classes.navigation}>
          {token ? (
            <Logo className={classes.logo} />
          ) : (
            <Link href="/" passHref>
              <a>
                <Logo className={classes.logo} />
              </a>
            </Link>
          )}
          {!goBackLink && openedNav && (
            <div className={classes.mobileNavigation}>
              <div className={classes.blueSection}>
                <Typography variant="subtitle1">Menu</Typography>
                <MaterialUIButton
                  id="close-icon-header"
                  className={classes.closeButton}
                  onClick={() => setOpenedNav(false)}
                >
                  <i className={classNames(classes.closeIcon, 'icon-close')} />
                </MaterialUIButton>
              </div>
              <div className={classes.mobileLinks}>
                {mobileLinks}

                {token ? (
                  !logoutButtonHidden && (
                    <button
                      id="log-out-button-mobile"
                      type="button"
                      className={classNames(
                        classes.mobileLink,
                        classes.authButtonMobile,
                      )}
                      onClick={() => dispatch(logOut())}
                    >
                      <Typography variant="body1">
                        Log out
                        <i className="icon-arrow-right" />
                      </Typography>
                    </button>
                  )
                ) : (
                  <Link href="/sign-in" passHref>
                    <MaterialUiLink
                      id="sign-in-mobile"
                      className={classNames(
                        classes.mobileLink,
                        classes.authButtonMobile,
                      )}
                    >
                      <Typography variant="body1">
                        Sign in <i className="icon-arrow-right" />
                      </Typography>
                    </MaterialUiLink>
                  </Link>
                )}
              </div>
            </div>
          )}

          {!goBackLink && (
            <div className={classes.desktopNavigation}>
              {token ? userLoggedDesktopLinks() : userNotLoggedDesktopLinks()}
            </div>
          )}

          {!goBackLink && (
            <MaterialUIButton
              id="open-menu-button-mobile"
              className={classes.burgerIconWrapper}
              onClick={() => setOpenedNav(!openedNav)}
              color="default"
              variant="contained"
            >
              <i className={classNames(classes.burgerIcon, 'icon-menu')} />
            </MaterialUIButton>
          )}

          {goBackLink && (
            <Link href={goBackLink} passHref>
              <a id="go-back-link-header" className={classes.goBackLink}>
                <Typography variant="body1">Back to overview</Typography>
              </a>
            </Link>
          )}
        </nav>
      </Container>
    </header>
  );
};

export default Header;
