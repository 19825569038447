/* eslint-disable max-len */
export const REVALIDATE_TIMEOUT = 300; // seconds

export default {
  home: {
    title: 'Cost effective will writing service from the comfort of your home.',
    description:
      'The most cost effective and convenient will writing service online, in just 15 minutes complete your will from the comfort of your home. Write your will online today.',
  },
  'create-will': {
    title:
      'Write your will online using our simple online will writing service.',
    description:
      'Writing a will? Complete your will in under 15 minutes with our online will writing service with unlimited updates powered by Coop funeral care and Countrywide',
  },
  'arrange-funeral': {
    title: 'Arrange a funeral with Simpwill powered by the Coop Funeral Care',
    description:
      'Need help with funeral plans? Simpwill take care of everything from will writing from the comfort of your home to funeral plans powered by the Coop. Find out more',
  },
  probate: {
    title: 'Making probate easy with Simpwill powered by Countrywide',
    description:
      'What is probate? Simpwill makes everything easy when it comes to Probate, with unlimited estate updates get advice from our estate experts Countrywide',
  },
  'sign-in': {
    title:
      'Sign in and complete your will online today. Click Here to sign in.',
    description:
      'Click here to sign in and complete your will today, make unlimited estate updates, manages your LPA and Probate today. Powered by Coop and Countrywide.',
  },
  'sign-up': {
    title:
      'Sign Up to Simpwill and write your will online in under 15 minutes.',
    description:
      'Write your will today, our online will writing service is easy to complete in under 15 minutes with unlimited estate updates Start your will writing journey today.',
  },
  'reset-password': {
    title: 'Simpwill - Send a request to reset your password',
    description: `If you've forgotten your password, you can request an email and we'll send instructions on how to reset it.`,
  },
  policies: {
    title: 'Find out more about Simpwill here and all relevant documentation',
    description:
      'Simpwill have made it easy to find out more about us, our Terms and conditions, privacy policy and cookie policy. Click here today for more information',
  },
  lpas: {
    title: 'LPAs Choose your lasting Power of Attorney with Simpwill',
    description:
      'Do you know what a lasting power of attorney is? Understand what LPAs do and what it is with Simpwill. Find out more here',
  },
  blog: {
    title: 'Find out more about Will Writing, Funeral Plans and Probate.',
    description:
      'Find out everything about will writing, probate, lasting power of attorneys, funeral plans, funeral care and estate update with Simpwill and our blogs. Read more here.',
  },
  guides: {
    title: 'Find out more about Will Writing, Funeral Plans and Probate.',
    description:
      'Find out everything about will writing, probate, lasting power of attorneys, funeral plans, funeral care and estate update with Simpwill and our blogs. Read more here.',
  },
};
