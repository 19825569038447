/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request data to sign up.
 * @export
 * @interface UserDataForSignUp
 */
export interface UserDataForSignUp {
  /**
   *
   * @type {string}
   * @memberof UserDataForSignUp
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserDataForSignUp
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserDataForSignUp
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof UserDataForSignUp
   */
  mailing?: boolean;
}

export function UserDataForSignUpFromJSON(json: any): UserDataForSignUp {
  return UserDataForSignUpFromJSONTyped(json, false);
}

export function UserDataForSignUpFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserDataForSignUp {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    email: json['email'],
    password: json['password'],
    mailing: !exists(json, 'mailing') ? undefined : json['mailing'],
  };
}

export function UserDataForSignUpToJSON(value?: UserDataForSignUp | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    email: value.email,
    password: value.password,
    mailing: value.mailing,
  };
}
