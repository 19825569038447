/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PurchaseObject,
  PurchaseObjectFromJSON,
  PurchaseObjectFromJSONTyped,
  PurchaseObjectToJSON,
} from './';

/**
 * Client secret object.
 * @export
 * @interface ClientSecretObject
 */
export interface ClientSecretObject {
  /**
   *
   * @type {PurchaseObject}
   * @memberof ClientSecretObject
   */
  purchase: PurchaseObject;
  /**
   *
   * @type {string}
   * @memberof ClientSecretObject
   */
  link: string;
}

export function ClientSecretObjectFromJSON(json: any): ClientSecretObject {
  return ClientSecretObjectFromJSONTyped(json, false);
}

export function ClientSecretObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ClientSecretObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    purchase: PurchaseObjectFromJSON(json['purchase']),
    link: json['link'],
  };
}

export function ClientSecretObjectToJSON(
  value?: ClientSecretObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    purchase: PurchaseObjectToJSON(value.purchase),
    link: value.link,
  };
}
