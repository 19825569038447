/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Attorneys can act.
 * @export
 * @enum {string}
 */
export enum AttorneysCanAct {
  AsSoonLpaIsRegistered = 'As soon LPA is registered',
  OnlyWhenIDontHaveMentalCapacity = "Only when I don't have mental capacity",
}

export function AttorneysCanActFromJSON(json: any): AttorneysCanAct {
  return AttorneysCanActFromJSONTyped(json, false);
}

export function AttorneysCanActFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AttorneysCanAct {
  return json as AttorneysCanAct;
}

export function AttorneysCanActToJSON(value?: AttorneysCanAct | null): any {
  return value as any;
}
