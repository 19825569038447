import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AddEmailForm, {
  InviteByEmailData,
} from '@components/common/add-email-form';
import FullscreenPopup from '@components/common/fullscreen-popup';
import useTypedSelector from '@hooks/use-typed-selector';
import { confirmIsUserEmail } from '@store/auth';
import { getUser, updateUser } from '@store/auth/thunks';
import { getDocuments, getPrices } from '@store/documents/thunks';
import theme from '@utils/material-ui-theme';

const Setup: React.FC = (props) => {
  const { children } = props;

  const { token, isUserEmail } = useTypedSelector((state) => state.auth);

  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      token &&
      router.pathname !== '/documents' &&
      router.pathname !== '/sign-in' &&
      router.pathname !== '/sign-up'
    ) {
      dispatch(getDocuments());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);

  useEffect(() => {
    if (token && router.pathname !== '/profile') {
      dispatch(getUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);

  useEffect(() => {
    dispatch(getPrices());
  }, [dispatch]);

  const updateUserEmail = (data: InviteByEmailData) => {
    dispatch(
      updateUser({
        profileRequest: {
          email: data.email,
        },
        fulfilledCallback: () => {
          setTimeout(() => {
            dispatch(confirmIsUserEmail());
          }, 0);
        },
      }),
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <main>{children}</main>

      {token && !isUserEmail && (
        <FullscreenPopup type="edit">
          <AddEmailForm
            title="Please add your email!"
            description="You should add you email address."
            inviteByEmail={updateUserEmail}
          />
        </FullscreenPopup>
      )}
    </ThemeProvider>
  );
};

export default Setup;
