/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorsObject,
  ErrorsObjectFromJSON,
  ErrorsObjectToJSON,
  PetCreateRequest,
  PetCreateRequestFromJSON,
  PetCreateRequestToJSON,
  PetUpdateRequest,
  PetUpdateRequestFromJSON,
  PetUpdateRequestToJSON,
  WillResponse,
  WillResponseFromJSON,
  WillResponseToJSON,
} from '../models';

export interface CreatePetRequest {
  petCreateRequest: PetCreateRequest;
}

export interface DeletePetRequest {
  pet: number;
}

export interface UpdatePetRequest {
  pet: number;
  petUpdateRequest: PetUpdateRequest;
}

/**
 *
 */
export class PetsApi extends runtime.BaseAPI {
  /**
   * Create pet.
   */
  async createPetRaw(
    requestParameters: CreatePetRequest,
  ): Promise<runtime.ApiResponse<WillResponse>> {
    if (
      requestParameters.petCreateRequest === null ||
      requestParameters.petCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'petCreateRequest',
        'Required parameter requestParameters.petCreateRequest was null or undefined when calling createPet.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/pets`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: PetCreateRequestToJSON(requestParameters.petCreateRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WillResponseFromJSON(jsonValue),
    );
  }

  /**
   * Create pet.
   */
  async createPet(requestParameters: CreatePetRequest): Promise<WillResponse> {
    const response = await this.createPetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete pet.
   */
  async deletePetRaw(
    requestParameters: DeletePetRequest,
  ): Promise<runtime.ApiResponse<WillResponse>> {
    if (requestParameters.pet === null || requestParameters.pet === undefined) {
      throw new runtime.RequiredError(
        'pet',
        'Required parameter requestParameters.pet was null or undefined when calling deletePet.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/pets/{pet}`.replace(
        `{${'pet'}}`,
        encodeURIComponent(String(requestParameters.pet)),
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WillResponseFromJSON(jsonValue),
    );
  }

  /**
   * Delete pet.
   */
  async deletePet(requestParameters: DeletePetRequest): Promise<WillResponse> {
    const response = await this.deletePetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update pet.
   */
  async updatePetRaw(
    requestParameters: UpdatePetRequest,
  ): Promise<runtime.ApiResponse<WillResponse>> {
    if (requestParameters.pet === null || requestParameters.pet === undefined) {
      throw new runtime.RequiredError(
        'pet',
        'Required parameter requestParameters.pet was null or undefined when calling updatePet.',
      );
    }

    if (
      requestParameters.petUpdateRequest === null ||
      requestParameters.petUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'petUpdateRequest',
        'Required parameter requestParameters.petUpdateRequest was null or undefined when calling updatePet.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('bearerAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request({
      path: `/api/pets/{pet}`.replace(
        `{${'pet'}}`,
        encodeURIComponent(String(requestParameters.pet)),
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: PetUpdateRequestToJSON(requestParameters.petUpdateRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WillResponseFromJSON(jsonValue),
    );
  }

  /**
   * Update pet.
   */
  async updatePet(requestParameters: UpdatePetRequest): Promise<WillResponse> {
    const response = await this.updatePetRaw(requestParameters);
    return await response.value();
  }
}
