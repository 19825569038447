/* eslint-disable react/jsx-props-no-spreading */
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';

import initFirebase from '@boot/init-firebase';
import Setup from '@boot/setup';
import { configureAxios } from '@config/axios/axiosConfiguration';
import { MetatagObject } from '@lib/api';
import { store, persistor } from '@store/index';

import '@public/fonts/SimpWillIcons/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface PageProps {
  frontUrl: string;
}

export interface MetaProps {
  meta?: MetatagObject;
}

const useConstructor = (callBack: () => void) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  if (hasBeenCalled) return;

  callBack();
  setHasBeenCalled(true);
};

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const frontUrl = process.env.NEXT_PUBLIC_FRONT_URL || '/';

  useConstructor(() => {
    if (typeof window !== 'undefined') {
      persistor.persist();
    }

    configureAxios();
    initFirebase();
  });

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');

    smoothscroll.polyfill();

    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <title>Simpwill</title>
        <meta
          name="twitter:image"
          content={`${frontUrl}/jpg/meta-preview.jpg`}
        />
      </Head>
      <DefaultSeo
        title="Cost effective will writing service from the comfort of your home."
        description="The most cost effective and convenient will writing service online, in just 15 minutes complete 
                    your will from the comfort of your home. Write your will online today."
        openGraph={{
          title:
            'Cost effective will writing service from the comfort of your home.',
          description: `The most cost effective and convenient will writing service online, in 
                      just 15 minutes complete your will from the comfort of your home. Write your will online today.`,
          type: 'website',
          url: frontUrl,
          site_name: 'Simpwill',
          images: [
            {
              url: `${frontUrl}/jpg/meta-preview.jpg`,
              alt: 'Simpwill',
              width: 600,
              height: 315,
            },
          ],
        }}
        twitter={{
          site: '@Simpwil1',
          cardType: 'summary_large_image',
        }}
      />

      <Provider store={store}>
        <Setup>
          <Component {...pageProps} frontUrl={frontUrl} />
        </Setup>
      </Provider>
    </>
  );
};

export default MyApp;
