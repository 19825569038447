/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssetTypes,
  AssetTypesFromJSON,
  AssetTypesFromJSONTyped,
  AssetTypesToJSON,
} from './';

/**
 * Asset request object for update.
 * @export
 * @interface AssetUpdateRequest
 */
export interface AssetUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof AssetUpdateRequest
   */
  name?: string;
  /**
   *
   * @type {AssetTypes}
   * @memberof AssetUpdateRequest
   */
  type?: AssetTypes;
}

export function AssetUpdateRequestFromJSON(json: any): AssetUpdateRequest {
  return AssetUpdateRequestFromJSONTyped(json, false);
}

export function AssetUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AssetUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    type: !exists(json, 'type') ? undefined : AssetTypesFromJSON(json['type']),
  };
}

export function AssetUpdateRequestToJSON(
  value?: AssetUpdateRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: AssetTypesToJSON(value.type),
  };
}
