import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loader: {
    margin: '0 auto',
    display: 'block',
  },
  md: {
    width: 250,
    height: 250,
  },
  sm: {
    width: 48,
    height: 48,
  },
  marginAuto: {
    margin: 'auto',
  },
  circle: { fill: theme.palette.primary.dark },
  big: { animation: '$bigCircles 0.8s ease-in-out infinite alternate' },
  small: { animation: '$smallCircles 0.8s ease-in-out infinite alternate' },
  positionFixedCenter: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  '@keyframes smallCircles': {
    from: { r: 4 },
    to: { r: 7 },
  },
  '@keyframes bigCircles': {
    from: { r: 7 },
    to: { r: 4 },
  },
}));

export default useStyles;
