import {
  unstable_createMuiStrictModeTheme as unstableCreateMuiStrictModeTheme,
  ThemeOptions,
} from '@material-ui/core';

import transparentize from '@utils/transparentize';

const palette = {
  background: {
    default: '#fff',
    paper: '#FAFAFA',
  },
  info: {
    main: '#969799',
    dark: '#2D2F33',
    light: '#E3E3E3',
  },
  primary: {
    main: '#C3E9FF',
    dark: '#38B6FF',
    light: '#F1F8FB',
  },
  secondary: {
    main: '#FFA5A5',
    light: '#FFD6C0',
    dark: '#EC368D',
    contrastText: '#440381',
  },
  success: {
    main: '#22C632',
  },
  error: { main: '#EB3540' },
  text: { disabled: '#969799' },
};

const materialUiTheme: ThemeOptions = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          overflow: 'overlay',
        },
        button: {
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          padding: 0,
        },
        img: {
          imageRendering: '-webkit-optimize-contrast',
        },
        input: {
          '&::-ms-reveal': {
            display: 'none',
          },
          '&::-ms-clear': {
            display: 'none',
          },
        },
        '::-webkit-scrollbar': {
          width: 10,
        },

        '::-webkit-scrollbar-thumb': {
          background: transparentize(palette.primary.main, 0.8),
          borderRadius: 5,
          transition: 'all 0.3s ease-in-out',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: palette.primary.dark,
          borderRadius: 5,
          transition: 'all 0.3s ease-in-out',
        },

        '.slick-slider .slick-track': {
          display: 'flex',
        },
        '.slick-slider .slick-list': {
          padding: '40px 0 80px',
          margin: '0 -15px',
        },
        '.slick-slider.slick-initialized .slick-dots': {
          display: 'flex !important',
          justifyContent: 'center',
          padding: 0,
          bottom: '40px',
          '& li': {
            display: 'inline-block',
            width: 16,
            height: 16,
            color: 'transparent',
            borderRadius: '100%',
            borderWidth: 1,
            borderColor: palette.text.disabled,
            borderStyle: 'solid',
            margin: '0 8px',
            '& button': {
              '&:before': {
                content: 'none',
              },
            },
            '&.slick-active': {
              backgroundColor: palette.primary.dark,
              borderColor: palette.primary.dark,
              '& button': {
                '&:before': {
                  content: 'none',
                },
              },
            },
          },
        },
        '#toast-container': {
          position: 'fixed',
          left: '50%',
          bottom: 24,
          display: 'flex',
          flexDirection: 'column-reverse',
          width: 'calc(100% - 30px)',
          maxHeight: 290,
          overflow: 'hidden',
          transform: 'translateX(-50%)',
          zIndex: 1400,
        },
        '.overflow-hidden': {
          overflow: 'hidden',
        },
        '@media (min-width:600px)': {
          '#toast-container': {
            width: 'unset',
          },
        },
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: 15,
        paddingRight: 15,
        maxWidth: 'calc(1110px + 47px * 2)',
        '@media (min-width:600px)': {
          paddingLeft: 39,
          paddingRight: 39,
        },
        '@media (min-width:960px)': {
          paddingLeft: 47,
          paddingRight: 47,
        },
      },
      maxWidthMd: {
        '@media (min-width:960px)': {
          paddingLeft: 127,
          paddingRight: 127,
          maxWidth: 'calc(920px + 127px * 2)',
        },
      },
      maxWidthLg: {
        '@media (min-width:1280px)': {
          paddingLeft: 0,
          paddingRight: 0,
          maxWidth: 940,
        },
      },
    },
    MuiAppBar: {
      root: {
        marginBottom: 24,
      },
    },
    MuiLink: {
      root: {
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: 16,
        lineHeight: 24 / 16,
        color: 'currentColor',
        fontWeight: 700,

        '&.blue': {
          color: palette.primary.dark,
        },
        '&.pink': {
          color: palette.secondary.dark,
        },
        '&.underline': {
          textDecoration: 'underline',
        },

        '& i': {
          fontSize: 24,
          verticalAlign: 'middle',

          '&.icon-arrow-left': {
            marginRight: 8,
          },
          '&.icon-arrow-right': {
            marginLeft: 8,
          },
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: palette.primary.dark,
        padding: '0 8px 0 0',

        '&.MuiCheckbox-colorPrimary.Mui-checked': {
          color: palette.primary.dark,
        },
      },
    },
    MuiMenu: {
      list: {
        backgroundColor: '#EDF6FA',
        padding: 0,

        '& .MuiMenuItem-root': {
          color: palette.info.main,
          fontWeight: 300,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          '&::after': {
            content: '"\\e907"',
            fontFamily: 'SimpWillIcons',
            fontSize: 12,
            marginLeft: 10,
            visibility: 'hidden',
          },

          '&:hover': {
            color: palette.info.dark,

            '&::after': {
              visibility: 'visible',
            },
          },

          '&.Mui-selected': {
            color: palette.primary.dark,
            fontWeight: 700,
            backgroundColor: 'transparent',

            '&::after': {
              visibility: 'visible',
            },
          },
        },

        '& .MuiListItem-button:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiButton: {
      root: {
        color: palette.info.dark,
        fontSize: 16,
        lineHeight: 24 / 16,
        cursor: 'pointer',
        outline: 0,
        borderRadius: 16,
        textTransform: 'none',
        height: '56px',
        minWidth: '56px',
        textDecoration: 'none',
      },
      contained: {
        padding: '16px 48px',
        boxShadow: '0px 10px 20px rgba(14, 87, 129, 0.25)',
        color: palette.background.default,
        '&:active': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: palette.info.light,
          color: palette.background.default,
        },
      },
      outlined: {
        padding: '16px 20px',
        '&.Mui-disabled': {
          borderColor: 'currentColor',
          color: palette.text.disabled,
          borderWidth: 2,
        },
      },
      outlinedSecondary: {
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
          borderColor: 'initial',
        },
        '&.Mui-disabled': {
          borderWidth: 2,
        },
      },
    },
    MuiPaper: {
      rounded: {
        boxShadow: '0px 6px 34px rgba(36, 41, 55, 0.08)',
        borderRadius: '10px',
      },
      elevation1: {
        boxShadow: '0px 6px 34px rgba(36, 41, 55, 0.08)',
      },
    },
    MuiTypography: {
      root: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
    MuiTextField: {
      root: {
        fontSize: 16,
        lineHeight: 24 / 16,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#EDF6FA',
        borderRadius: 16,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        fontSize: 16,
        '&:hover': {
          backgroundColor: '#EDF6FA',
        },
        '&.Mui-focused': {
          backgroundColor: '#EDF6FA',
        },
      },
      input: {
        padding: '27px 16px 10px',
      },
    },
    MuiInputLabel: {
      root: {
        color: palette.info.dark,
        '&.MuiInputLabel-filled.MuiInputLabel-shrink': {
          transform: 'translate(16px, 10px) scale(0.75)',
        },
      },
      filled: {
        transform: 'translate(16px, 20px) scale(1)',
        fontWeight: 300,
        color: palette.text.disabled,
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: palette.text.disabled,
        },
        '&.Mui-error': {
          color: palette.error.main,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: '#EDF6FA',
        borderRadius: 16,
      },
      input: {
        padding: 16,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      filled: {
        '&.MuiSelect-filled.MuiSelect-filled': {
          paddingRight: 10,
        },
      },
    },
  },
  typography: {
    fontFamily: 'Mukta, sans-serif',
    htmlFontSize: 16,
    h1: {
      fontSize: 78,
      fontWeight: 700,
      lineHeight: 80 / 78,
      color: palette.info.dark,
    },
    h2: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: 56 / 48,
      color: palette.info.dark,
    },
    h3: {
      fontSize: 31,
      fontWeight: 700,
      lineHeight: 40 / 32,
      color: palette.info.dark,
    },
    subtitle1: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 32 / 24,
      color: palette.info.dark,
    },
    subtitle2: {
      fontSize: 24,
      fontWeight: 300,
      lineHeight: 32 / 24,
      color: palette.info.dark,
    },
    button: {
      fontSize: 16,
      lineHeight: 24 / 16,
      fontWeight: 700,
      color: palette.primary.dark,
      textTransform: 'initial',
      textDecoration: 'none',
      '&.underline': {
        textDecoration: 'underline',
      },
      '&.pink': {
        color: palette.secondary.dark,
      },
    },
    body1: {
      fontSize: 16,
      lineHeight: 24 / 16,
      fontWeight: 700,
      color: palette.info.dark,
    },
    body2: {
      fontSize: 16,
      lineHeight: 24 / 16,
      fontWeight: 300,
      color: palette.info.dark,
    },
    caption: {
      fontSize: 14,
      lineHeight: 20 / 14,
      fontWeight: 300,
      color: palette.info.main,
    },
  },
  palette,
};

export default unstableCreateMuiStrictModeTheme(materialUiTheme);
