/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Prices collection object.
 * @export
 * @interface PricesCollectionObject
 */
export interface PricesCollectionObject {
  /**
   *
   * @type {number}
   * @memberof PricesCollectionObject
   */
  singleWillPrice: number;
  /**
   *
   * @type {number}
   * @memberof PricesCollectionObject
   */
  jointWillPrice: number;
  /**
   *
   * @type {number}
   * @memberof PricesCollectionObject
   */
  oneLpaPrice: number;
  /**
   *
   * @type {number}
   * @memberof PricesCollectionObject
   */
  bothLpasPrice: number;
  /**
   *
   * @type {number}
   * @memberof PricesCollectionObject
   */
  unlimitedUpdates?: number;
}

export function PricesCollectionObjectFromJSON(
  json: any,
): PricesCollectionObject {
  return PricesCollectionObjectFromJSONTyped(json, false);
}

export function PricesCollectionObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PricesCollectionObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    singleWillPrice: json['single_will_price'],
    jointWillPrice: json['joint_will_price'],
    oneLpaPrice: json['one_lpa_price'],
    bothLpasPrice: json['both_lpas_price'],
    unlimitedUpdates: !exists(json, 'unlimited_updates')
      ? undefined
      : json['unlimited_updates'],
  };
}

export function PricesCollectionObjectToJSON(
  value?: PricesCollectionObject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    single_will_price: value.singleWillPrice,
    joint_will_price: value.jointWillPrice,
    one_lpa_price: value.oneLpaPrice,
    both_lpas_price: value.bothLpasPrice,
    unlimited_updates: value.unlimitedUpdates,
  };
}
