import { combineReducers } from 'redux';

import auth from './auth';
import documents from './documents';
import landings from './landings';
/* PLOP_INJECT_IMPORT */

const appReducer = combineReducers({
  auth,
  documents,
  landings,
  /* PLOP_CONTENT */
});

export default appReducer;
