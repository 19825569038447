/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DiscountObject,
  DiscountObjectFromJSON,
  DiscountObjectFromJSONTyped,
  DiscountObjectToJSON,
} from './';

/**
 * Coupin response object.
 * @export
 * @interface CouponResponse
 */
export interface CouponResponse {
  /**
   *
   * @type {DiscountObject}
   * @memberof CouponResponse
   */
  data: DiscountObject;
}

export function CouponResponseFromJSON(json: any): CouponResponse {
  return CouponResponseFromJSONTyped(json, false);
}

export function CouponResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CouponResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: DiscountObjectFromJSON(json['data']),
  };
}

export function CouponResponseToJSON(value?: CouponResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: DiscountObjectToJSON(value.data),
  };
}
