/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BeneficiariesRequestObject,
  BeneficiariesRequestObjectFromJSON,
  BeneficiariesRequestObjectFromJSONTyped,
  BeneficiariesRequestObjectToJSON,
  DocumentProgress,
  DocumentProgressFromJSON,
  DocumentProgressFromJSONTyped,
  DocumentProgressToJSON,
  EstateRequestObject,
  EstateRequestObjectFromJSON,
  EstateRequestObjectFromJSONTyped,
  EstateRequestObjectToJSON,
  Offers,
  OffersFromJSON,
  OffersFromJSONTyped,
  OffersToJSON,
  ReserveBeneficiaryObject,
  ReserveBeneficiaryObjectFromJSON,
  ReserveBeneficiaryObjectFromJSONTyped,
  ReserveBeneficiaryObjectToJSON,
  WillStepsRequestObject,
  WillStepsRequestObjectFromJSON,
  WillStepsRequestObjectFromJSONTyped,
  WillStepsRequestObjectToJSON,
} from './';

/**
 * Will components.
 * @export
 * @interface WillComponents
 */
export interface WillComponents {
  /**
   *
   * @type {Array<Offers>}
   * @memberof WillComponents
   */
  exclusiveOffers?: Array<Offers>;
  /**
   *
   * @type {BeneficiariesRequestObject}
   * @memberof WillComponents
   */
  beneficiaries?: BeneficiariesRequestObject;
  /**
   *
   * @type {Array<EstateRequestObject>}
   * @memberof WillComponents
   */
  estate?: Array<EstateRequestObject>;
  /**
   *
   * @type {ReserveBeneficiaryObject}
   * @memberof WillComponents
   */
  reserveBeneficiary?: ReserveBeneficiaryObject;
  /**
   *
   * @type {Array<number>}
   * @memberof WillComponents
   */
  executors?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof WillComponents
   */
  simpwillExecutor?: boolean;
  /**
   *
   * @type {WillStepsRequestObject}
   * @memberof WillComponents
   */
  steps?: WillStepsRequestObject;
  /**
   *
   * @type {DocumentProgress}
   * @memberof WillComponents
   */
  progress?: DocumentProgress;
  /**
   *
   * @type {boolean}
   * @memberof WillComponents
   */
  downloaded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WillComponents
   */
  noAssets?: boolean;
}

export function WillComponentsFromJSON(json: any): WillComponents {
  return WillComponentsFromJSONTyped(json, false);
}

export function WillComponentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WillComponents {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    exclusiveOffers: !exists(json, 'exclusive_offers')
      ? undefined
      : (json['exclusive_offers'] as Array<any>).map(OffersFromJSON),
    beneficiaries: !exists(json, 'beneficiaries')
      ? undefined
      : BeneficiariesRequestObjectFromJSON(json['beneficiaries']),
    estate: !exists(json, 'estate')
      ? undefined
      : (json['estate'] as Array<any>).map(EstateRequestObjectFromJSON),
    reserveBeneficiary: !exists(json, 'reserve_beneficiary')
      ? undefined
      : ReserveBeneficiaryObjectFromJSON(json['reserve_beneficiary']),
    executors: !exists(json, 'executors') ? undefined : json['executors'],
    simpwillExecutor: !exists(json, 'simpwill_executor')
      ? undefined
      : json['simpwill_executor'],
    steps: !exists(json, 'steps')
      ? undefined
      : WillStepsRequestObjectFromJSON(json['steps']),
    progress: !exists(json, 'progress')
      ? undefined
      : DocumentProgressFromJSON(json['progress']),
    downloaded: !exists(json, 'downloaded') ? undefined : json['downloaded'],
    noAssets: !exists(json, 'no_assets') ? undefined : json['no_assets'],
  };
}

export function WillComponentsToJSON(value?: WillComponents | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    exclusive_offers:
      value.exclusiveOffers === undefined
        ? undefined
        : (value.exclusiveOffers as Array<any>).map(OffersToJSON),
    beneficiaries: BeneficiariesRequestObjectToJSON(value.beneficiaries),
    estate:
      value.estate === undefined
        ? undefined
        : (value.estate as Array<any>).map(EstateRequestObjectToJSON),
    reserve_beneficiary: ReserveBeneficiaryObjectToJSON(
      value.reserveBeneficiary,
    ),
    executors: value.executors,
    simpwill_executor: value.simpwillExecutor,
    steps: WillStepsRequestObjectToJSON(value.steps),
    progress: DocumentProgressToJSON(value.progress),
    downloaded: value.downloaded,
    no_assets: value.noAssets,
  };
}
