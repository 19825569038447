import classNames from 'classnames';
import { CSSProperties, Fragment } from 'react';

import useStyles from '@components/common/figures/styles';

export interface Figure extends CSSProperties {
  classWrapper: string;
}

export interface Image extends Figure {
  src: string;
  transform?: string;
}

export interface Circle extends Figure {
  background:
    | 'blue'
    | 'blueMain'
    | 'blueLight'
    | 'blueGradient'
    | 'pink'
    | 'pinkMain'
    | 'pinkDark'
    | 'pinkGradient'
    | 'yellow'
    | 'yellowGradient'
    | 'purple'
    | 'purpleGradient';
}

interface Props {
  images?: Image[];
  circles?: Circle[];
  classWrapper?: string;
}

const Figures: React.FC<Props> = (props) => {
  const { images, circles, classWrapper } = props;
  const classes = useStyles();

  const circlesRender = circles?.map((circle) => (
    <Fragment key={circle.classWrapper}>
      <div
        className={classNames(
          classes.circle,
          classes[circle.background],
          circle.classWrapper,
        )}
        key={circle.left}
      />
    </Fragment>
  ));

  const imageRender = images?.map((image) => (
    <Fragment key={image.src}>
      <img
        src={image.src}
        alt="figure"
        className={classNames(classes.image, image.classWrapper)}
        key={image.src}
      />
    </Fragment>
  ));

  return (
    <div className={classNames(classes.figures, classWrapper)}>
      {circlesRender}
      {imageRender}
    </div>
  );
};

export default Figures;
