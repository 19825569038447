/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Actor roles object.
 * @export
 * @enum {string}
 */
export enum ActorRoles {
  Owner = 'Owner',
  Partner = 'Partner',
  Child = 'Child',
  Beneficiary = 'Beneficiary',
  Guardian = 'Guardian',
  Executor = 'Executor',
  HealthAttorney = 'Health Attorney',
  HealthCertificateProvider = 'Health Certificate provider',
  HealthApplier = 'Health Applier',
  HealthReceiver = 'Health Receiver',
  HealthPersonToNotify = 'Health Person to Notify',
  FinanceAttorney = 'Finance Attorney',
  FinanceCertificateProvider = 'Finance Certificate provider',
  FinanceApplier = 'Finance Applier',
  FinanceReceiver = 'Finance Receiver',
  FinancePersonToNotify = 'Finance Person to Notify',
  HealthReplacementAttorney = 'Health Replacement Attorney',
  FinanceReplacementAttorney = 'Finance Replacement Attorney',
}

export function ActorRolesFromJSON(json: any): ActorRoles {
  return ActorRolesFromJSONTyped(json, false);
}

export function ActorRolesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActorRoles {
  return json as ActorRoles;
}

export function ActorRolesToJSON(value?: ActorRoles | null): any {
  return value as any;
}
