/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Preference request object for update.
 * @export
 * @interface PreferenceUpdateRequest
 */
export interface PreferenceUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof PreferenceUpdateRequest
   */
  name?: string;
}

export function PreferenceUpdateRequestFromJSON(
  json: any,
): PreferenceUpdateRequest {
  return PreferenceUpdateRequestFromJSONTyped(json, false);
}

export function PreferenceUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PreferenceUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function PreferenceUpdateRequestToJSON(
  value?: PreferenceUpdateRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}
