/* tslint:disable */
/* eslint-disable */
/**
 * Simpwill API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ReserveEstateObject,
  ReserveEstateObjectFromJSON,
  ReserveEstateObjectFromJSONTyped,
  ReserveEstateObjectToJSON,
} from './';

/**
 * Estate object.
 * @export
 * @interface EstateObject
 */
export interface EstateObject {
  /**
   *
   * @type {number}
   * @memberof EstateObject
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof EstateObject
   */
  beneficiary: string;
  /**
   *
   * @type {number}
   * @memberof EstateObject
   */
  actorId?: number;
  /**
   *
   * @type {number}
   * @memberof EstateObject
   */
  charityId?: number;
  /**
   *
   * @type {number}
   * @memberof EstateObject
   */
  percent: number;
  /**
   *
   * @type {boolean}
   * @memberof EstateObject
   */
  children: boolean | null;
  /**
   *
   * @type {Array<ReserveEstateObject>}
   * @memberof EstateObject
   */
  reserveBeneficiaries: Array<ReserveEstateObject> | null;
}

export function EstateObjectFromJSON(json: any): EstateObject {
  return EstateObjectFromJSONTyped(json, false);
}

export function EstateObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EstateObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    beneficiary: json['beneficiary'],
    actorId: !exists(json, 'actor_id') ? undefined : json['actor_id'],
    charityId: !exists(json, 'charity_id') ? undefined : json['charity_id'],
    percent: json['percent'],
    children: json['children'],
    reserveBeneficiaries:
      json['reserve_beneficiaries'] === null
        ? null
        : (json['reserve_beneficiaries'] as Array<any>).map(
            ReserveEstateObjectFromJSON,
          ),
  };
}

export function EstateObjectToJSON(value?: EstateObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    beneficiary: value.beneficiary,
    actor_id: value.actorId,
    charity_id: value.charityId,
    percent: value.percent,
    children: value.children,
    reserve_beneficiaries:
      value.reserveBeneficiaries === null
        ? null
        : (value.reserveBeneficiaries as Array<any>).map(
            ReserveEstateObjectToJSON,
          ),
  };
}
